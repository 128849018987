import type { GridApi } from "@ag-grid-community/core"
import { createContext, useContext } from "react"
import type { ReactNode } from "react"

import type { CellFocused, RowData } from "./types"

interface GridContextState {
  cellFocused: CellFocused
  setCellFocused: (cellFocused: CellFocused) => void
  gridApi?: GridApi<RowData>
  setGridApi: (gridRef: GridApi) => void
}

const GridContext = createContext<GridContextState | undefined>(undefined)

export const useGridContext = () => {
  const context = useContext(GridContext)
  if (context === undefined) {
    throw new Error("useGridContext must be used within a GridProvider")
  }
  return context
}

export const GridProvider: React.FC<{
  children: ReactNode
  value: GridContextState
}> = ({ children, value }) => (
  <GridContext.Provider value={value}>{children}</GridContext.Provider>
)

import { httpsCallable } from "../serverUtils"
import type {
  CommentCreationRequest,
  CommentDeleteRequest,
  DiscussionDeleteRequest,
  DiscussionResolutionRequest,
} from "./types"

export const createComment =
  httpsCallable<CommentCreationRequest>("createComment")

export const deleteDiscussion =
  httpsCallable<DiscussionDeleteRequest>("deleteDiscussion")

export const deleteComment =
  httpsCallable<CommentDeleteRequest>("deleteComment")

export const resolveDiscussion =
  httpsCallable<DiscussionResolutionRequest>("resolveDiscussion")

import type { User as AuthUser } from "firebase/auth"
import { doc } from "firebase/firestore"
import { createContext, useContext, useMemo } from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"

import { type QuiltPermission } from "../auth/permissions"
import { makeConverter } from "../dbUtils"
import { db } from "../firebaseApp"
import type { Group, QuiltFlags } from "../groups/types"
import { GROUPS_COLLECTION } from "../types/common"
import { useActiveUserAuthorizationFromContext } from "./ActiveUserAuthorizationContext"

interface ActiveGroupContextType {
  activeGroupOid: string
  activeGroup: Group | undefined
  activeGroupLoading: boolean
  activeGroupError: Error | undefined
  flagsLoading: boolean
  flags: QuiltFlags
  authUser: AuthUser
  hasPerm: (permission: QuiltPermission) => boolean
  numGroups: number
}

const ActiveGroupContext = createContext<ActiveGroupContextType>({
  activeGroupOid: "",
  activeGroup: undefined,
  activeGroupLoading: true,
  activeGroupError: undefined,
  flagsLoading: true,
  flags: {},
  authUser: {} as AuthUser,
  hasPerm: () => false,
  numGroups: 0,
})

const ActiveGroupProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { authUser, activeGroupOid, hasPerm, numGroups } =
    useActiveUserAuthorizationFromContext()
  const [activeGroup, activeGroupLoading, activeGroupError] = useDocumentData(
    doc(db, GROUPS_COLLECTION, activeGroupOid).withConverter(
      makeConverter<Group>(),
    ),
  )

  const value = useMemo(() => {
    return {
      activeGroupOid,
      activeGroup,
      activeGroupLoading: activeGroupLoading,
      activeGroupError: activeGroupError,
      flagsLoading: activeGroupLoading,
      flags: activeGroup?.flags ?? {},
      authUser,
      hasPerm,
      numGroups,
    }
  }, [
    activeGroupOid,
    activeGroup,
    activeGroupLoading,
    activeGroupError,
    authUser,
    hasPerm,
    numGroups,
  ])

  return (
    <ActiveGroupContext.Provider value={value}>
      {children}
    </ActiveGroupContext.Provider>
  )
}

const useActiveGroup = (): ActiveGroupContextType => {
  return useContext(ActiveGroupContext)
}

export { ActiveGroupProvider, useActiveGroup }

import { captureMessage } from "@sentry/browser"
import type { User as AuthUser } from "firebase/auth"
import { useEffect } from "react"
import { useIntercom } from "react-use-intercom"

import { useQuiltAuthState } from "../contexts/useQuiltAuthState"

const HelpButton: React.FC<{
  user?: AuthUser
  className?: string
  children?: React.ReactNode
}> = ({ user, className = "", children }) => {
  const { boot, hide, show, isOpen } = useIntercom()
  const { claims } = useQuiltAuthState()

  useEffect(() => {
    if (user && !claims?.icomHash) {
      captureMessage(`Intercom hash not found in user claims: ${user.email}`)
    }

    boot(
      user && claims?.icomHash
        ? {
            name: user.displayName ?? undefined,
            email: user.email ?? undefined,
            userId: user.uid,
            avatar: {
              type: "avatar",
              imageUrl: user.photoURL ?? undefined,
            },
            userHash: claims.icomHash,
          }
        : undefined,
    )
  }, [boot, user, claims?.icomHash])

  return (
    <a
      href="https://intercom.help/quilt-app/"
      className={className}
      target="_blank"
      onClick={(e) => {
        if (!import.meta.env.VITE_INTERCOM_APP_ID) {
          // Intercom disabled, go to help.
          return
        }

        e.preventDefault()
        // TODO(mgraczyk): This isOpen check does not work when there is an
        // active conversation.
        if (isOpen) {
          hide()
        } else {
          show()
        }
      }}
    >
      {children}
    </a>
  )
}

export default HelpButton

import { useCallback, useEffect, useState } from "react"

import { getOauthStateParam } from "../../auth/api"
import { GOOGLE_DRIVE_DOCUMENTS_SCOPES } from "../../constants"
import {
  getUserCredentials,
  removeUserCredentialEntry,
} from "../../credentials/api"
import {
  ATLASSIAN_PROVIDER,
  type CredentialMetadata,
  GONG_PROVIDER,
  NOTION_PROVIDER,
  ZOOM_PROVIDER,
} from "../../credentials/providerTypes"
import useGoogleAccessToken from "./../useGoogleAccessToken"
import { type OAUTH_PROVIDERS, doOauthFlow } from "./doOauthFlow"

const useUserCredential = () => {
  const [error, setError] = useState<Error | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [userCredentials, setUserCredentials] = useState<CredentialMetadata[]>(
    [],
  )
  const { getAccessToken: getGoogleAccessToken } = useGoogleAccessToken(
    GOOGLE_DRIVE_DOCUMENTS_SCOPES,
  )

  const fetchData = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await getUserCredentials({})
      setUserCredentials(data.credentials)
    } catch (error) {
      if (error instanceof Error) {
        setError(error)
        return
      }
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    void fetchData()
  }, [fetchData])

  const removeCredentialEntry = useCallback(
    async (metadata: CredentialMetadata) => {
      setLoading(true)
      try {
        await removeUserCredentialEntry({
          provider: metadata.provider,
          subProvider: metadata.subProvider,
        })
        await fetchData()
      } catch (error) {
        if (error instanceof Error) {
          setError(error)
          return
        }
        throw error
      } finally {
        setLoading(false)
      }
    },
    [fetchData],
  )

  const connectGoogleDrive = useCallback(async () => {
    setLoading(true)
    try {
      await getGoogleAccessToken()
      await fetchData()
    } catch (error) {
      if (error instanceof Error) {
        setError(error)
        return
      }
      throw error
    } finally {
      setLoading(false)
    }
  }, [fetchData, getGoogleAccessToken])

  const connectProvider = useCallback(
    async (provider: OAUTH_PROVIDERS) => {
      setLoading(true)
      try {
        const {
          data: { state },
        } = await getOauthStateParam()
        await doOauthFlow(provider, state)
        await fetchData()
      } catch (error) {
        if (error instanceof Error) {
          setError(error)
          return
        }
        throw error
      } finally {
        setLoading(false)
      }
    },
    [fetchData],
  )

  const connectNotion = useCallback(
    () => connectProvider(NOTION_PROVIDER),
    [connectProvider],
  )
  // TODO (ishan) move this to group credential hook
  const connectGong = useCallback(
    () => connectProvider(GONG_PROVIDER),
    [connectProvider],
  )

  const connectZoom = useCallback(
    () => connectProvider(ZOOM_PROVIDER),
    [connectProvider],
  )

  const connectAtlassian = useCallback(
    () => connectProvider(ATLASSIAN_PROVIDER),
    [connectProvider],
  )

  return {
    error,
    loading,
    userCredentials,
    removeCredentialEntry,
    connectGoogleDrive,
    connectNotion,
    connectGong,
    connectZoom,
    connectAtlassian,
  }
}

export default useUserCredential

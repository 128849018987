import { Button, Checkbox, Divider, Form, Popover, Tooltip } from "antd"
import { CircleCheckIcon, ThumbsDownIcon } from "lucide-react"
import { useCallback, useState } from "react"

import { sendAnalyticsEvent } from "../analytics"
import type { DownvoteReason } from "../analytics/types"
import type { AnalyticsEventSurface } from "../analytics/types"
import useAnswerFeedback from "../hooks/useAnswerFeedback"
import useErrorPopup from "../hooks/useErrorPopup"

type DetailFormData = Record<DownvoteReason, boolean | undefined>
interface DetailItem {
  reason: DownvoteReason
  label: string
}

const ANSWERED_DETAIL_ITEMS: DetailItem[] = [
  {
    reason: "INCORRECT_INFORMATION",
    label: "Not factually correct",
  },
  {
    reason: "MISSING_INFORMATION",
    label: "Did not fully answer the question",
  },
  { reason: "VAGUE", label: "Vague" },
  { reason: "BAD_STYLE", label: "Bad style" },
  { reason: "TOO_SLOW", label: "Too slow" },
  { reason: "OTHER", label: "Other" },
]

const UNABLE_TO_ANSWER_DETAIL_ITEMS: DetailItem[] = [
  { reason: "SHOULD_HAVE_ANSWERED", label: "Should have answered" },
  { reason: "TOO_SLOW", label: "Too slow" },
  { reason: "OTHER", label: "Other" },
]

interface DetailFormProps {
  surface: AnalyticsEventSurface
  unableToAnswer: boolean
  answererRequestId: string | null | undefined
  onClose: () => void
}

const DownvoteDetailForm: React.FC<DetailFormProps> = ({
  surface,
  unableToAnswer,
  answererRequestId,
  onClose,
}) => {
  const [form] = Form.useForm<DetailFormData>()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { handleSuccess, handleError } = useErrorPopup()
  const formValue = Form.useWatch([], form)

  const onFinish = useCallback(
    (formData: DetailFormData) => {
      setSubmitting(true)
      try {
        sendAnalyticsEvent({
          event_type: "DOWNVOTE_DETAIL",
          surface,
          event_data: {
            entity_type: "ANSWER",
            entity_id: answererRequestId ?? null,
            reasons: Object.entries(formData)
              .filter(([, v]) => !!v)
              .map(([k]) => k) as DownvoteReason[],
            reason_detail: null,
          },
        })
        onClose()
        handleSuccess("Thanks for your feedback!")
      } catch (error) {
        handleError({ error, prefix: "Couldn't submit feedback" })
      } finally {
        setSubmitting(false)
      }
    },
    [surface, answererRequestId, onClose, handleError, handleSuccess],
  )

  return (
    <Form
      form={form}
      layout="vertical"
      className="max-w-[320px]"
      onFinish={onFinish}
    >
      <div className="flex gap-1 font-semibold text-green-600">
        <CircleCheckIcon size={20} /> Your feedback was recorded
      </div>
      <div className="text-gray-800">
        You can provide additional details to help us understand what went wrong
      </div>
      <Divider />
      {(unableToAnswer
        ? UNABLE_TO_ANSWER_DETAIL_ITEMS
        : ANSWERED_DETAIL_ITEMS
      ).map(({ reason, label }) => (
        <Form.Item
          key={reason}
          name={reason}
          valuePropName="checked"
          className="m-0"
        >
          <Checkbox>{label}</Checkbox>
        </Form.Item>
      ))}
      <div className="mt-1 flex gap-1">
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!Object.values(formValue ?? {}).some(Boolean)}
            loading={submitting}
          >
            Submit
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="default" loading={submitting} onClick={onClose}>
            Cancel
          </Button>
        </Form.Item>
      </div>
    </Form>
  )
}

interface Props {
  disabled: boolean
  unableToAnswer: boolean
  surface: AnalyticsEventSurface
  answererRequestId: string | null | undefined
}

const BadAnswerButton: React.FC<Props> = ({
  disabled,
  unableToAnswer,
  surface,
  answererRequestId,
}) => {
  const { onBadAnswer } = useAnswerFeedback(
    surface,
    answererRequestId,
    /*hideBadAnswerFeedbackPopup=*/ true,
  )
  const [open, setOpen] = useState<boolean>(false)
  const hideForm = useCallback(() => setOpen(false), [])

  return (
    <Tooltip title="Bad Answer" open={open ? false : undefined}>
      <Popover
        open={open}
        onOpenChange={setOpen}
        content={
          <DownvoteDetailForm
            surface={surface}
            unableToAnswer={unableToAnswer}
            answererRequestId={answererRequestId}
            onClose={hideForm}
          />
        }
        trigger="click"
      >
        <Button
          icon={<ThumbsDownIcon />}
          size="small"
          disabled={disabled}
          onClick={onBadAnswer}
        />
      </Popover>
    </Tooltip>
  )
}

export default BadAnswerButton

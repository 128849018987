import { Button, Popover, Table, Tag } from "antd"
import { collection, query, where } from "firebase/firestore"
import { EllipsisVerticalIcon } from "lucide-react"
import { useMemo, useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"

import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../../dbUtils"
import { db } from "../../firebaseApp"
import type {
  Group,
  GroupInvite,
  GroupMembership,
  GroupRole,
} from "../../groups/types"
import { useGroupMembers } from "../../hooks/useGroupMembers"
import { INVITES_COLLECTION } from "../../types/common"
import UserAvatar from "../UserAvatar"
import dateColumn from "../table/dateColumn"
import AddMemberButton from "./AddMemberButton"
import DeleteMemberConfirmation from "./DeleteMemberConfirmation"
import UpdateMemberConfirmation from "./UpdateMemberConfirmation"
import { ROLE_INFOS } from "./constants"

const { Column } = Table

type TableItem =
  | (GroupMembership & { type: "member" })
  | (GroupInvite & { type: "invite"; photoURL?: string })

interface UnifiedGroupTableProps {
  group: Group
}

const UnifiedGroupTable: React.FC<UnifiedGroupTableProps> = ({ group }) => {
  const { hasPerm } = useActiveUserAuthorizationFromContext()
  const [members, membersLoading] = useGroupMembers(group.oid)
  const [editMember, setEditMember] = useState<GroupMembership | null>(null)
  const [deleteMember, setDeleteMember] = useState<GroupMembership | null>(null)

  const colRef = collection(db, INVITES_COLLECTION)
  const allDocsQuery = query(
    colRef,
    where("group_oid", "==", group.oid),
    where("status", "==", "PENDING"),
  ).withConverter(makeConverter<GroupInvite>())

  const [invites, invitesLoading] = useCollectionData<GroupInvite>(allDocsQuery)

  const hidePopovers = () => {
    setEditMember(null)
    setDeleteMember(null)
  }

  const tableData: TableItem[] = useMemo(
    () => [
      ...(members || []).map((member) => ({
        ...member,
        type: "member" as const,
      })),
      ...(invites || []).map((invite) => ({
        ...invite,
        photoURL: undefined,
        type: "invite" as const,
      })),
    ],
    [members, invites],
  )

  const renderActions = (record: TableItem) => {
    if (record.type !== "member" || !hasPerm("admin")) {
      return null
    }
    return (
      <Popover
        content={
          <div className="flex items-center">
            <Popover
              content={
                <UpdateMemberConfirmation
                  group={group}
                  membership={record}
                  onClose={hidePopovers}
                />
              }
              trigger="click"
              open={editMember === record}
              placement="left"
              align={{ offset: [0, 0] }}
              overlayStyle={{ maxWidth: "400px" }}
              onOpenChange={(visible) => {
                if (visible) {
                  setEditMember(record)
                } else {
                  setEditMember(null)
                }
              }}
            >
              <Button type="primary" size="small">
                Edit Role
              </Button>
            </Popover>
            <Popover
              content={
                <DeleteMemberConfirmation
                  group={group}
                  membership={record}
                  onClose={hidePopovers}
                />
              }
              trigger="click"
              open={deleteMember === record}
              onOpenChange={(visible) => {
                if (visible) {
                  setDeleteMember(record)
                } else {
                  setDeleteMember(null)
                }
              }}
            >
              <Button className="ml-2" size="small" danger>
                Delete
              </Button>
            </Popover>
          </div>
        }
        trigger="click"
        placement="bottomRight"
      >
        <Button icon={<EllipsisVerticalIcon />} size="small" />
      </Popover>
    )
  }

  const roleFilters = useMemo(
    () =>
      Object.entries(ROLE_INFOS).map(([key, value]) => ({
        text: value.name,
        value: key,
      })),
    [],
  )

  return (
    <div className="mb-8">
      <div className="mb-4 flex items-center justify-between">
        <h3>Group Members and Invites</h3>
        {hasPerm("admin") && <AddMemberButton group={group} />}
      </div>
      <Table
        dataSource={tableData}
        rowKey={(record) => record.oid}
        loading={membersLoading || invitesLoading}
        pagination={{ pageSize: 10, hideOnSinglePage: true }}
      >
        <Column
          title="User"
          dataIndex="email"
          key="email"
          render={(_, record: TableItem) => (
            <div className="flex items-center">
              <UserAvatar
                name={
                  record.type === "member" ? record.email : record.invitee_email
                }
                photoURL={record.photoURL}
                size="sm"
              />
              <span className="ml-2">
                {record.type === "member" ? record.email : record.invitee_email}
              </span>
            </div>
          )}
          sorter={(a: TableItem, b: TableItem) => {
            const aEmail = a.type === "member" ? a.email : a.invitee_email
            const bEmail = b.type === "member" ? b.email : b.invitee_email
            return aEmail.localeCompare(bEmail)
          }}
        />
        <Column
          title="Role"
          key="role"
          dataIndex="role"
          width={220}
          filters={roleFilters}
          onFilter={(value, record) => record.role === value}
          render={(role: GroupRole, record: TableItem) => (
            <Tag
              className="text-xs font-semibold"
              color={
                record.type === "member" ? ROLE_INFOS[role].color : "orange"
              }
            >
              {record.type === "member"
                ? ROLE_INFOS[role].name
                : `Invitation Pending (${ROLE_INFOS[role].name})`}
            </Tag>
          )}
          sorter={(a: TableItem, b: TableItem) => {
            const aRole = ROLE_INFOS[a.role].name
            const bRole = ROLE_INFOS[b.role].name
            if (a.type !== b.type) {
              return a.type === "member" ? -1 : aRole === bRole ? 0 : 1
            }
            return aRole.localeCompare(bRole)
          }}
        />
        {dateColumn({
          title: "Joined / Invited On",
          dataIndex: "created_at",
          width: 300,
          sortable: true,
        })}
        <Column
          title="Actions"
          key="actions"
          width={60}
          render={(_, record: TableItem) => renderActions(record)}
        />
      </Table>
    </div>
  )
}

export default UnifiedGroupTable

import {
  Button,
  Drawer,
  Form,
  Select,
  Skeleton,
  Tabs,
  type TabsProps,
} from "antd"
import type { FirestoreError } from "firebase/firestore"
import {
  collection,
  doc,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore"
import {
  Calendar,
  CalendarIcon,
  CheckIcon,
  CircleUserRoundIcon,
  ListIcon,
  XIcon,
} from "lucide-react"
import { useCallback, useMemo, useState } from "react"
import {
  useCollectionData,
  useDocumentData,
  useDocumentDataOnce,
} from "react-firebase-hooks/firestore"
import { useNavigate, useParams } from "react-router"
import { Link, useSearchParams } from "react-router-dom"

import AssignKnowledgeItemButton from "../../components/AssignKnowledgeItemForm"
import { Discussions } from "../../components/Discussions/Discussions"
import DownloadLink from "../../components/DownloadLink"
import ExternalLink from "../../components/ExternalLink"
import LoadingSpinner from "../../components/LoadingSpinner"
import NotFoundPage from "../../components/NotFoundPage"
import { getConfirmation } from "../../components/confirmAndExecute"
import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../../dbUtils"
import {
  DISCUSSION_COLLECTION,
  type KnowledgeDiscussion,
} from "../../discussions/types"
import { getDocumentUrl } from "../../documents/api"
import { db } from "../../firebaseApp"
import useErrorPopup from "../../hooks/useErrorPopup"
import { useGroupMembers } from "../../hooks/useGroupMembers"
import { useGroupTags } from "../../hooks/useGroupTags"
import KnowledgeItemHistoryCard from "../../knowledge/KnowledgeItemHistoryCard"
import { deleteUserDocumentsApi } from "../../knowledge/api"
import {
  editKnowledgeItemsApi,
  reviewKnowledgeItems,
} from "../../knowledge/api"
import { knowledgeItemHistoryConverter } from "../../knowledge/db"
import type { AnyUserDocument } from "../../knowledge/types"
import { SOURCE_KIND_DESCRIPTION } from "../../sources/constants"
import { TagListItem } from "../../tags/Tag"
import {
  GROUPS_COLLECTION,
  PIECES_SUBCOLLECTION,
  USER_DOCUMENTS_COLLECTION,
  USER_DOCUMENTS_HISTORY_SUBCOLLECTION,
} from "../../types/common"
import { EXCEL_SUPPORTED_MIMETYPES } from "../../types/mimetype"
import type { UserDocumentPiece } from "../../types/userDocument"
import type { UserDocument } from "../../types/userDocument"
import { sleep } from "../../utils"
import { shorten, toHumanReadableString } from "../../utils"
import Header from "./../../components/Header"
import SourceDocumentViewer from "./SourceDocumentViewer"

const userDocConverter = makeConverter<UserDocument>()

interface FormDataType {
  title: string
  tags: string[]
}

const FormTextItemStyle: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div className="flex flex-wrap items-center gap-1 overflow-y-auto text-[14px] leading-5 text-gray-600">
    {children}
  </div>
)

const UserDocumentForm: React.FC<{ doc: UserDocument }> = ({ doc: srcDoc }) => {
  const [form] = Form.useForm<FormDataType>()
  const { activeGroupOid, authUser, hasPerm } =
    useActiveUserAuthorizationFromContext()

  const { handleSuccess, handleError } = useErrorPopup()
  const [groupMembers, groupMembersLoading] = useGroupMembers(activeGroupOid)
  const [tags, tagsLoading] = useGroupTags(activeGroupOid)
  const [submitting, setSubmitting] = useState(false)
  const [reviewing, setReviewing] = useState(false)
  const [unreviewing, setUnreviewing] = useState(false)

  const couldHaveLinkedItems =
    srcDoc.mimetype && EXCEL_SUPPORTED_MIMETYPES.includes(srcDoc.mimetype)
  const [generatedDocs, generatedDocsLoading] = useCollectionData(
    couldHaveLinkedItems
      ? query(
          collection(db, USER_DOCUMENTS_COLLECTION),
          where("group_oid", "==", activeGroupOid),
          where("source.source_oid", "==", srcDoc.oid),
        ).withConverter(makeConverter<AnyUserDocument>())
      : null,
  )

  let sourceDocUserText: React.ReactNode
  if (authUser.uid === srcDoc.creator.uid) {
    sourceDocUserText = authUser.email
  } else if (groupMembersLoading) {
    sourceDocUserText = (
      <Skeleton
        active
        className="w-36"
        title={false}
        paragraph={{ className: "m-0", rows: 1 }}
      />
    )
  } else {
    const groupMember = groupMembers?.find(
      (member) => member.uid === srcDoc.creator.uid,
    )
    sourceDocUserText = groupMember?.email ?? "Unknown User"
  }

  const initialValues = useMemo(
    () => ({
      title: srcDoc.title,
      tags: srcDoc.tags ? Object.keys(srcDoc.tags) : [],
    }),
    [srcDoc.title, srcDoc.tags],
  )

  const onDownload = useCallback(async () => {
    try {
      const { data: downloadUrl } = await getDocumentUrl({
        oid: srcDoc.oid,
        isDownload: true,
      })
      window.open(downloadUrl, "_blank", "noopener,noreferrer")
      void handleSuccess("Downloading document")
    } catch (error) {
      handleError({ error, prefix: "Couldn't download document" })
    }
  }, [handleSuccess, handleError, srcDoc.oid])

  const onChangeReview = useCallback(
    async (
      kind: "REVIEW" | "UNREVIEW",
      setStatus: (status: boolean) => void,
    ) => {
      setStatus(true)
      try {
        await reviewKnowledgeItems({
          requests: [
            {
              oid: srcDoc.oid,
              kind,
            },
          ],
        })
        handleSuccess(
          kind === "REVIEW" ? "Document reviewed!" : "Document unreviewed!",
        )
      } catch (error) {
        handleError({ error, prefix: "Couldn't change review status" })
      } finally {
        setStatus(false)
      }
    },
    [handleSuccess, handleError, srcDoc.oid],
  )

  const reviewItem = useCallback(async () => {
    return await onChangeReview("REVIEW", setReviewing)
  }, [onChangeReview])

  const unreviewItem = useCallback(async () => {
    return await onChangeReview("UNREVIEW", setUnreviewing)
  }, [onChangeReview])

  const onSubmit = async (formData: FormDataType) => {
    setSubmitting(true)
    try {
      await editKnowledgeItemsApi({
        requests: [
          {
            oid: srcDoc.oid,
            tags: formData.tags
              ? Object.fromEntries(formData.tags.map((tag) => [tag, null]))
              : undefined,
          },
        ],
      })
      handleSuccess("Updated document")
    } catch (error) {
      handleError({ error, prefix: "Couldn't update document" })
    } finally {
      setSubmitting(false)
    }
  }

  const submitTitle = hasPerm("doc.modify")
    ? "Update"
    : "Cannot update, you do not have write permissions"

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark="optional"
      initialValues={initialValues}
      onFinish={onSubmit}
      disabled={submitting}
    >
      <Form.Item
        className="font-semibold"
        label="Title"
        required
        name="title"
        rules={[{ required: true }]}
      >
        {srcDoc.source_kind === "FILEUPLOAD" ||
        srcDoc.source_kind === "QUESTIONNAIRE_ASSISTANT_IMPORT" ? (
          <DownloadLink
            className="text-primary justify-start text-left"
            onClick={onDownload}
          >
            {srcDoc.title || "Document"}
          </DownloadLink>
        ) : (
          <ExternalLink
            className="text-primary justify-start text-left"
            href={srcDoc.document_url}
          >
            {srcDoc.title || (
              <span className="break-words">{srcDoc.document_url}</span>
            )}
          </ExternalLink>
        )}
      </Form.Item>
      <Form.Item label="Created By" required>
        <FormTextItemStyle>
          <CircleUserRoundIcon size={14} />
          {sourceDocUserText}
        </FormTextItemStyle>
        <FormTextItemStyle>
          <CalendarIcon size={14} />
          {toHumanReadableString(srcDoc.created_at)}
        </FormTextItemStyle>
      </Form.Item>

      <Form.Item label="Last Updated At" className="font-semibold" required>
        <div className="flex items-center gap-1 font-normal">
          <Calendar size={14} />
          {toHumanReadableString(srcDoc.updated_at)}
        </div>
      </Form.Item>

      <Form.Item label="Import Method" className="font-semibold" required>
        <div className="font-normal">
          {SOURCE_KIND_DESCRIPTION[srcDoc.source_kind]}
        </div>
      </Form.Item>

      {srcDoc.last_assigned_to && srcDoc.last_assigned_at && (
        <Form.Item label="Last Assigned To" required>
          <FormTextItemStyle>
            <CircleUserRoundIcon size={14} />
            {srcDoc.last_assigned_to.email}
          </FormTextItemStyle>
          <FormTextItemStyle>
            <CalendarIcon size={14} />
            {new Date(srcDoc.last_assigned_at.seconds * 1000).toDateString()}
          </FormTextItemStyle>
        </Form.Item>
      )}
      {srcDoc.last_reviewed_at && srcDoc.last_reviewed_by && (
        <Form.Item label="Last Reviewed By" required>
          <FormTextItemStyle>
            <CircleUserRoundIcon size={14} />
            {srcDoc.last_reviewed_by.email}
          </FormTextItemStyle>
          <FormTextItemStyle>
            <CalendarIcon size={14} />
            {new Date(srcDoc.last_reviewed_at.seconds * 1000).toDateString()}
          </FormTextItemStyle>
        </Form.Item>
      )}

      <Form.Item label="Tags" name="tags" className="mb-0">
        <Select
          mode="multiple"
          className="w-full"
          options={tags?.map((tag) => ({
            value: tag.oid,
            label: <TagListItem tag={tag} />,
            name: tag.name,
          }))}
          optionFilterProp="name"
          placeholder={
            tagsLoading ? (
              <Skeleton active loading title={false} paragraph={{ rows: 1 }} />
            ) : (
              "Select Tags"
            )
          }
          disabled={tagsLoading}
        />
      </Form.Item>
      <span className="text-xs text-gray-500">
        To add a new tag visit{" "}
        <a href="/groups" className="font-semibold">
          groups settings
        </a>
      </span>
      <div className="mt-8 pb-2">
        <div className="mb-3 flex">
          <Button
            type="primary"
            className="submit mr-2 flex font-bold"
            htmlType="submit"
            title={submitTitle}
            disabled={submitting || !hasPerm("doc.modify") || tagsLoading}
          >
            {submitting ? "Updating..." : "Update"}
          </Button>
          <AssignKnowledgeItemButton
            knowledgeOid={srcDoc.oid}
            assignedTo={srcDoc.last_assigned_to}
            isUserDocument
          />
        </div>
        {hasPerm("doc.modify") && (
          <div className="mt-3 flex">
            <Button
              className="submit flex items-center font-bold"
              onClick={reviewItem}
              icon={<CheckIcon />}
              title="Mark as Reviewed"
              disabled={submitting || reviewing || unreviewing}
            >
              {reviewing ? "Reviewing..." : "Review"}
            </Button>
            <Button
              className="submit ml-2 flex items-center font-bold"
              onClick={unreviewItem}
              icon={<XIcon />}
              title="Remove review status"
              disabled={
                submitting ||
                reviewing ||
                unreviewing ||
                !srcDoc.last_reviewed_at
              }
            >
              {unreviewing ? "Removing..." : "Remove Review"}
            </Button>
          </div>
        )}
      </div>
      {couldHaveLinkedItems && (
        <Skeleton
          active
          loading={generatedDocsLoading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {generatedDocs ? (
            <a
              href={`/knowledge-items?source=${srcDoc.oid}`}
              className="text-primary mt-4 font-semibold"
            >
              View {generatedDocs.length} linked Answer Bank items
            </a>
          ) : (
            "Could not load linked Answer Bank items"
          )}
        </Skeleton>
      )}
    </Form>
  )
}

const LoadingComponent = () => (
  <Skeleton
    active
    className="mt-4 w-36"
    title={false}
    paragraph={{ className: "m-0", rows: 1 }}
  />
)

const UserDocumentReference: React.FC<{
  piece: UserDocumentPiece | undefined
  pieceLoading: boolean
  pieceError: FirestoreError | undefined
}> = ({ piece, pieceLoading, pieceError }) => {
  if (pieceLoading) {
    return <Skeleton loading active title={true} />
  }

  if (pieceError || !piece) {
    return (
      <p className="font-normal text-gray-600">
        Failed to load snippet. The reference may have been deleted
      </p>
    )
  }

  if (piece.kind === "RAW_TEXT") {
    return <p className="font-normal italic text-gray-600">{piece.content}</p>
  } else if (piece.kind === "ANSWERED_QUESTION") {
    return (
      <div>
        <h4 className="m-0 font-semibold text-gray-600">Question:</h4>
        <p className="m-0 font-normal italic text-gray-600">
          {piece.primary_question}
        </p>
        {piece.secondary_question && (
          <>
            <h4 className="m-0 font-semibold text-gray-600">
              Question Details:
            </h4>
            <p className="m-0 font-normal italic text-gray-600">
              {piece.secondary_question}
            </p>
          </>
        )}
        <h4 className="m-0 font-semibold text-gray-600">Answer:</h4>
        <p className="m-0 font-normal italic text-gray-600">
          {piece.primary_answer}
        </p>
        {piece.secondary_answer && (
          <>
            <h4 className="m-0 font-semibold text-gray-600">Answer Details:</h4>
            <p className="m-0 font-normal italic text-gray-600">
              {piece.secondary_answer}
            </p>
          </>
        )}
      </div>
    )
  } else {
    return (
      <p className="font-normal text-gray-600">Could not display reference.</p>
    )
  }
}

const UserDocumentPage: React.FC = () => {
  const { docOid = "" } = useParams<{ docOid: string }>()
  const docRef = doc(db, USER_DOCUMENTS_COLLECTION, docOid).withConverter(
    userDocConverter,
  )
  const [searchParams] = useSearchParams()
  const discussionOid = searchParams.get("discussion_oid")
  const [sidebarOpen, setSidebarOpen] = useState(!!discussionOid)
  const [userDoc, loading, error] = useDocumentData(docRef)
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()
  const [groupMembers, groupMembersLoading] = useGroupMembers(activeGroupOid)
  const [tags, tagsLoading] = useGroupTags(activeGroupOid)
  const [removing, setRemoving] = useState<boolean>(false)
  const [removed, setRemoved] = useState<boolean>(false)
  const navigate = useNavigate()

  const pieceOid = searchParams.get("pieceOid")

  const pieceRef = pieceOid
    ? doc(
        db,
        USER_DOCUMENTS_COLLECTION,
        docOid,
        PIECES_SUBCOLLECTION,
        pieceOid,
      ).withConverter(makeConverter<UserDocumentPiece>())
    : null

  const [piece, pieceLoading, pieceError] = useDocumentDataOnce(pieceRef)

  const discussionsColRef = collection(
    db,
    GROUPS_COLLECTION,
    activeGroupOid,
    DISCUSSION_COLLECTION,
  ).withConverter(makeConverter<KnowledgeDiscussion>())

  const discussionsQuery = query(
    discussionsColRef,
    where("user_document_oid", "==", docOid),
    where("deleted", "==", false),
    orderBy("created_at", "desc"),
    limit(100),
  )

  const [discussions, discussionsLoading, discussionsError] =
    useCollectionData<KnowledgeDiscussion>(discussionsQuery)

  const historyColRef = collection(
    db,
    USER_DOCUMENTS_COLLECTION,
    docOid,
    USER_DOCUMENTS_HISTORY_SUBCOLLECTION,
  ).withConverter(knowledgeItemHistoryConverter)

  const [history, historyLoading] = useCollectionData(
    query(
      historyColRef,
      where("group_oid", "==", activeGroupOid),
      orderBy("updated_at", "desc"),
    ),
  )

  const removeUserDocument = useCallback(async () => {
    if (
      !(await getConfirmation(
        "This will permanently remove the document from Quilt.",
      ))
    ) {
      return
    }

    setRemoving(true)
    try {
      await deleteUserDocumentsApi({ oids: [docOid] })
      setRemoved(true)
      await sleep(3000)
      navigate("/source-documents")
    } finally {
      setRemoving(false)
    }
  }, [docOid, navigate])

  // TODO(mgraczyk): Combine drawer logic with KnowledgeItemPage and KnowledgeItemForm.
  const drawerTabs: TabsProps["items"] = [
    {
      key: "discussions",
      label: "Discussions",
      children: discussionsLoading ? (
        <LoadingComponent />
      ) : (
        <Discussions
          group_oid={activeGroupOid}
          discussions={discussions}
          user_document_oid={docOid}
          kind="USER_DOCUMENT"
          discussionsError={discussionsError}
        />
      ),
    },
  ]

  if (history?.length) {
    drawerTabs.push({
      key: "history",
      label: "History",
      children: historyLoading ? (
        <LoadingComponent />
      ) : (
        <div className="border-t border-gray-100">
          {history.map((h, index) => (
            <KnowledgeItemHistoryCard
              key={index}
              item={h}
              groupMembers={groupMembers}
              tags={tags}
              tagsLoading={tagsLoading}
              groupMembersLoading={groupMembersLoading}
            />
          ))}
        </div>
      ),
    })
  }

  if (!docOid) {
    return <NotFoundPage />
  }

  let body: React.ReactNode
  if (removed) {
    body = (
      <div>
        <div className="m-4">Document marked for removal</div>
        <div>
          Redirecting to{" "}
          <Link to="/source-documents">your Source Documents</Link> in a few
          seconds...
        </div>
      </div>
    )
  } else if (removing) {
    body = (
      <div>
        <div className="m-4">Removing document...</div>
      </div>
    )
  } else if (loading) {
    body = <LoadingSpinner />
  } else if (!userDoc) {
    if (error) {
      if (error.code === "permission-denied") {
        return <NotFoundPage />
      }
      return (
        <span className="m-4">
          Failed to load document: {JSON.stringify(error)}
        </span>
      )
    } else {
      return <NotFoundPage />
    }
  } else {
    body = (
      <div className="m-8 flex flex-grow flex-row gap-8">
        <div className="w-72">
          {pieceOid && (
            <>
              <h3 className="font-bold text-gray-800">Reference</h3>
              <UserDocumentReference
                piece={piece}
                pieceLoading={pieceLoading}
                pieceError={pieceError}
              />
            </>
          )}
          <h3 className="font-bold text-gray-800">File Details</h3>
          <UserDocumentForm doc={userDoc} />
        </div>
        <div className="bg-gray-25 flex flex-auto grow flex-col justify-center rounded-lg">
          <SourceDocumentViewer userDocument={userDoc} piece={piece} />
        </div>
        <Drawer
          open={sidebarOpen}
          onClose={() => setSidebarOpen(false)}
          title="Options"
        >
          <Tabs
            rootClassName="quilt-questionnaire-sidebar"
            items={drawerTabs}
          />
        </Drawer>
      </div>
    )
  }

  const titleText = loading
    ? "Loading..."
    : userDoc && userDoc.title
      ? shorten(userDoc.title, 50)
      : "Source Document Details"

  return (
    <>
      <Header
        title={titleText}
        breadcrumbs={[
          {
            title: "Source Documents",
            href: "/source-documents",
          },
          {
            title: `Document ${docOid}`,
            href: `/source-documents/${docOid}`,
          },
        ]}
      >
        <div className="flex items-center">
          <Button onClick={removeUserDocument} className="font-bold" danger>
            Remove
          </Button>
          <Button
            icon={<ListIcon />}
            className="ml-4"
            onClick={() => setSidebarOpen(true)}
          />
        </div>
      </Header>
      {body}
    </>
  )
}

export default UserDocumentPage

import { useCallback, useMemo } from "react"
import { useSearchParams } from "react-router-dom"

export interface DocumentFilterState {
  tags: string[]
  creator: string | null
  source: string | null
  assignedTo: string | null
  sourceKind: string | null
}

export function useDocumentFilters(): [
  DocumentFilterState,
  () => void,
  (
    key: keyof DocumentFilterState,
    value: string | string[] | null | undefined,
  ) => void,
] {
  const [searchParams, setSearchParams] = useSearchParams()

  const filterState = useMemo<DocumentFilterState>(
    () => ({
      tags: searchParams.get("tags")?.split(",").filter(Boolean) || [],
      creator: searchParams.get("creator") || null,
      source: searchParams.get("source") || null,
      assignedTo: searchParams.get("assignedTo") || null,
      sourceKind: searchParams.get("sourceKind") || null,
    }),
    [searchParams],
  )

  const resetFilters = useCallback(() => {
    setSearchParams({})
  }, [setSearchParams])

  const setFilterValue = useCallback(
    (
      key: keyof DocumentFilterState,
      value: string | string[] | null | undefined,
    ) => {
      const newParams = new URLSearchParams(searchParams)
      if (
        value === null ||
        value === undefined ||
        (Array.isArray(value) && value.length === 0)
      ) {
        newParams.delete(key)
      } else if (Array.isArray(value)) {
        newParams.set(key, value.join(","))
      } else {
        newParams.set(key, value)
      }
      setSearchParams(newParams)
    },
    [searchParams, setSearchParams],
  )

  return [filterState, resetFilters, setFilterValue]
}

import { Button, Tooltip } from "antd"
import { Clipboard, ClipboardCheck } from "lucide-react"
import { useState } from "react"

import { sleep } from "../utils"

interface Props {
  text: string
  height?: string | number
  className?: string
  customCopyTooltip?: string
}

const CopyToClipboardButton: React.FC<Props> = ({
  text,
  height,
  className,
  customCopyTooltip,
}) => {
  const [copied, setCopied] = useState<boolean>(false)

  className = "flex items-center justify-center h-full " + (className ?? "")
  return (
    <Tooltip
      title={
        copied ? "Copied!" : (customCopyTooltip ?? "Copy Link to Clipboard")
      }
    >
      <Button
        type="text"
        icon={
          copied ? (
            <ClipboardCheck height={height} />
          ) : (
            <Clipboard height={height} />
          )
        }
        className={className}
        onClick={async () => {
          await navigator.clipboard.writeText(text)
          setCopied(true)
          await sleep(3000)
          setCopied(false)
        }}
      />
    </Tooltip>
  )
}

export default CopyToClipboardButton

import { Button, Typography } from "antd"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import LoadingSpinner from "../components/LoadingSpinner"
import { QUILT_EMAIL_ADDRESS } from "../constants"
import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { getErrorMessage } from "../errors"
import { useActiveSubscription } from "../hooks/useActiveSubscription"
import useErrorPopup from "../hooks/useErrorPopup"
import useSearchParamValue from "../hooks/useSearchParamValue"
import { PlanDetailsComponent } from "./PlanDetailsComponent"

const BillingPage: React.FC = () => {
  const [updateSubscriptionSuccess, setUpdateSubscriptionSuccess] =
    useSearchParamValue("success")

  const { handleSuccess, messageApi } = useErrorPopup()

  useEffect(() => {
    if (!updateSubscriptionSuccess) {
      return
    }
    // TODO(@henkejosh) this is necessary because the message is always double rendered
    //  for some reason (2 messages appear stacked)
    messageApi.destroy()
    void handleSuccess("Subscription updated successfully.")
    setUpdateSubscriptionSuccess(null)
  }, [
    handleSuccess,
    setUpdateSubscriptionSuccess,
    updateSubscriptionSuccess,
    messageApi,
  ])

  const { activeGroupOid } = useActiveUserAuthorizationFromContext()
  const navigate = useNavigate()

  const [
    planAndStatus,
    subscriptions,
    subscriptionsLoading,
    subscriptionsError,
  ] = useActiveSubscription(activeGroupOid)

  if (subscriptionsLoading) {
    return <LoadingSpinner />
  }

  if (subscriptionsError) {
    const errMsg = getErrorMessage({ error: subscriptionsError })
    return <div className="text-center text-red-600">{errMsg}</div>
  }

  if (!subscriptions || subscriptions.length === 0) {
    return (
      <div className="flex flex-col space-y-4 p-8">
        <div className="">
          <Typography.Text className="text-base">
            Not Subscribed to any plan.
          </Typography.Text>
        </div>
        <Button
          type="primary"
          className="self-start"
          onClick={() => navigate("/billing/plans")}
        >
          View plans and upgrade
        </Button>
      </div>
    )
  }

  const activeSubscription = subscriptions[0]
  return (
    <div>
      <Typography.Text>
        For questions about billing or for assistance changing or canceling your
        plan,{" "}
        <a href={`mailto:${QUILT_EMAIL_ADDRESS}`} target="_blank">
          contact us
        </a>
      </Typography.Text>
      <section>
        <div className="mb-8">
          <div className="flex items-center justify-between">
            <Typography.Title level={5} className="mb-4">
              Current Plan
            </Typography.Title>
          </div>
          <div className="flex-col items-center space-y-4">
            <PlanDetailsComponent
              planAndStatus={planAndStatus}
              activeQuiltSubscription={activeSubscription}
            />
          </div>
        </div>
      </section>
    </div>
  )
}

export default BillingPage

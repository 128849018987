import { Button, Form, Input } from "antd"
import { useCallback, useState } from "react"

import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { updateGroup } from "../../groups/api"
import type { Group } from "../../groups/types"
import useErrorPopup from "../../hooks/useErrorPopup"

interface Props {
  onSubmit: () => void
  group: Group
}

interface FormDataType {
  name: string
  company_name: string
}

const EditGroupForm: React.FC<Props> = ({ onSubmit, group }) => {
  const [form] = Form.useForm()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { handleSuccess, handleError } = useErrorPopup()
  const { hasPerm } = useActiveUserAuthorizationFromContext()

  const onFinish = useCallback(
    async (formData: FormDataType) => {
      if (!group) {
        return
      }
      setSubmitting(true)
      try {
        await updateGroup({
          oid: group.oid,
          ...formData,
        })

        handleSuccess("Updated group")
        onSubmit()
      } catch (error) {
        handleError({ error, prefix: "Couldn't save group" })
      } finally {
        setSubmitting(false)
      }
    },
    [handleSuccess, handleError, onSubmit, group],
  )

  const initialValues = {
    name: group.name,
    company_name: group.company_name,
  }

  return (
    <Form
      form={form}
      layout="vertical"
      className=""
      disabled={submitting || !hasPerm("admin")}
      style={{ width: 800 }}
      requiredMark="optional"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item label="Group Name" name="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Company Name"
        name="company_name"
        rules={[{ required: false }]}
      >
        <Input />
      </Form.Item>
      <Button
        type="primary"
        disabled={submitting || !hasPerm("admin")}
        className="submit flex"
        title={
          !hasPerm("admin")
            ? "Contact your group admin to modify the group"
            : ""
        }
        htmlType="submit"
      >
        {submitting ? "Saving..." : "Save"}
      </Button>
    </Form>
  )
}

export default EditGroupForm

import type {
  AnalyticsEventProduct,
  AnalyticsEventSurface,
} from "../analytics/types.js"
import type { AnswerResponseType } from "./answerResponseType.js"
import type { SheetsRange, WorksheetRange } from "./sheets.js"

// TODO(mgraczyk): The sheets range type should be WorkSheetRange, without
// spreadsheetId.
export interface Question {
  text: string
  details: string | null
  location?: WorksheetRange | null
  details_location?: WorksheetRange | null
}

export enum AnswerReferenceKind {
  KNOWLEDGE_ITEM = "KNOWLEDGE_ITEM",
  SOURCE_DOCUMENT_PIECE = "SOURCE_DOCUMENT_PIECE",
}

export interface AnswerReference {
  document_oid: string

  // The oid of the associated knowledge item, if this reference is a knowledge
  // item.
  knowledge_item_oid?: string | null

  // The url of the reference, if the reference is a web page.
  reference_url: string
  content: string

  kind?: AnswerReferenceKind | null

  piece_oid?: string | null
}

export interface Answer {
  primary_answer: string
  secondary_answer?: string | null
  confidence: number
  references: AnswerReference[]
  location?: WorksheetRange | null
}

export interface AnswerWithLocation extends Answer {
  location?: WorksheetRange | null
  primary_location?: WorksheetRange | null
  secondary_location?: WorksheetRange | null
}

export interface AnswerResponse {
  question: Question
  answer: AnswerWithLocation
  request_id?: string | null
}

// From answerer/types.py AnswererRequest
export interface AnswererRequest {
  request_id: string
  question: Question
  response_type: AnswerResponseType
  options: unknown
}

export interface GetAnswerRequest {
  question: Question
  analyticsProduct: AnalyticsEventProduct
  analyticsSurface: AnalyticsEventSurface
}

export interface GetAnswerResponse {
  request: AnswererRequest
  answer: Answer
}

export interface SheetText {
  text: string
  location?: SheetsRange | null
}

export interface AnswerAction {
  uid: string
  email: string
  created_at: FirebaseFirestore.Timestamp
}

export interface GeneratedAnsweredQuestion {
  oid: string
  primary_answer: SheetText
  secondary_answer: SheetText | null

  primary_question: SheetText
  secondary_question: SheetText | null

  confidence: number
  references: AnswerReference[]
}

export interface StoredGeneratedAnsweredQuestion
  extends GeneratedAnsweredQuestion {
  created_at: FirebaseFirestore.Timestamp
  updated_at: FirebaseFirestore.Timestamp

  answer_attempted: boolean

  last_quilt_answer_request_id?: string | null
  last_reviewed_by?: AnswerAction | null

  last_assigned_by?: AnswerAction | null
  last_assigned_to?: AnswerAction | null
}

import { ONBOARDING_ITEMS } from "../../pages/HomePage/OnboardingTasks"

export interface TourStep {
  title: string
  description: React.ReactNode
  target: string
  placement: "top" | "right" | "left" | "bottom" | "center"
  navigateTo?: string
}

export const title = "Let's get your Quilt Assistants set up!"
export const message =
  "This tour will show you how to get the most out of Quilt"
export const actionButtonLabel = "Take the tour"

const onboardingIdToTitle: Record<string, string> = Object.fromEntries(
  ONBOARDING_ITEMS.map((item) => [item.id, item.name]),
)

export const STEPS: TourStep[] = [
  {
    title: "Welcome to Quilt!",
    description: (
      <p>This tour will show you how to get the most out of Quilt.</p>
    ),
    placement: "center",
    target: "",
    navigateTo: "/",
  },
  {
    title: "Get Onboarded",
    description: <p>We will walk you through your onboarding tasks.</p>,
    placement: "right",
    target: "#home-page-onboarding-tasks",

    navigateTo: "/",
  },
  {
    title: onboardingIdToTitle["onboarding-task-document_uploaded"],
    placement: "right",
    description: (
      <p>
        Before answering questions, we need to process some of your documents.
      </p>
    ),
    target: "#onboarding-task-document_uploaded",

    navigateTo: "/",
  },
  {
    title: onboardingIdToTitle["onboarding-task-document_uploaded"],
    description: <p>This is where you can view and upload Source Documents.</p>,
    placement: "right",
    target: "[data-menu-id='sidebar-main-menu-source-documents']",

    navigateTo: "/source-documents",
  },
  {
    title: onboardingIdToTitle["onboarding-task-document_uploaded"],
    placement: "bottom",
    description: (
      <p>
        We support many document types including PDF, Word, and Excel. Try
        uploading a SOC 2 report, a previously answered questionnaire, or a web
        page from your knowledge base.
      </p>
    ),
    target: "#source-documents-actions",

    navigateTo: "/source-documents",
  },
  {
    title: "Answer Bank",
    description: (
      <p>
        You can add structured data such as completed questionnaires, or view
        your Answer Bank here.
      </p>
    ),
    placement: "right",
    target: "[data-menu-id='sidebar-main-menu-knowledge-items']",

    navigateTo: "/knowledge-items",
  },
  {
    title: onboardingIdToTitle["onboarding-task-question_asked"],
    description: <p>Next we&#39;ll show you the Knowledge Assistant.</p>,
    placement: "right",
    target: "#onboarding-task-question_asked",

    navigateTo: "/",
  },
  {
    title: "Knowledge Assistant",
    description: <p>This is where you can use the Knowledge Assistant.</p>,
    placement: "right",
    target: "[data-menu-id='sidebar-main-menu-chat']",

    navigateTo: "/chat",
  },
  {
    title: "Knowledge Assistant",
    description: (
      <p>
        Use the Knowledge Assistant to ask questions and get answers from your
        Source Documents and Answer Bank. You can update and save new answers as
        well.
      </p>
    ),
    placement: "top",
    target: "#chat-container",
  },
  {
    title: onboardingIdToTitle["onboarding-task-questionnaire_uploaded"],
    description: (
      <p>
        Next we&#39;ll show you the Questionnaire Assistant, which can answer
        long questionnaires for you in the background.
      </p>
    ),
    placement: "right",
    target: "#onboarding-task-questionnaire_uploaded",

    navigateTo: "/",
  },
  {
    title: "Questionnaire Assistant",
    description: <p>This is where you can use the Questionnaire Assistant</p>,
    placement: "right",
    target: "[data-menu-id='sidebar-main-menu-questionnaire-assistant']",

    navigateTo: "/questionnaire-assistant",
  },
  {
    title: "Upload a Questionnaire",
    description: (
      <p>
        Upload your questionnaire and Quilt will automatically complete it using
        your Source Documents and Answer Bank. We&#39;ll send you a notification
        and email when it&#39;s done!
      </p>
    ),
    placement: "bottom",
    target: "#questionnaire-assistant-add-button",
    navigateTo: "/questionnaire-assistant",
  },
  {
    title: onboardingIdToTitle["onboarding-task-teammember_invited"],
    description: (
      <p>
        Quilt works best as a tool for collaboration. Invite your team members
        to share Source Docs and collaborate on Questionnaires.
      </p>
    ),
    placement: "right",
    target: "#onboarding-task-teammember_invited",

    navigateTo: "/",
  },
  {
    title: onboardingIdToTitle["onboarding-task-teammember_invited"],
    description: <p>Invite new or existing quilt users by email address.</p>,
    target: "#groups-invite-member-button",
    placement: "left",

    navigateTo: "/group/settings/members",
  },
  {
    title: "Chrome Extension",
    description: (
      <p>
        Last one! Download the Chrome extension to use Quilt on any website. You
        can highlight text on any page to ask a question, or use it to fill out
        Google Sheets questionnaires.
      </p>
    ),
    placement: "right",
    target: "#onboarding-task-extension_loaded",

    navigateTo: "/",
  },
  {
    title: "All Done!",
    description: (
      <p>
        Thanks for reading! You can always restart this tour by clicking
        &quot;Take the Tour&quot; again.
      </p>
    ),
    placement: "center",
    target: "",
    navigateTo: "/",
  },
]

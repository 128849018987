import type { WorksheetRange } from "../types/sheets.js"

export const DISCUSSION_COLLECTION = "discussions"
export const COMMENTS_SUBCOLLECTION = "comments"

export interface Comment {
  oid: string
  deleted: boolean
  author_uid: string
  author_email: string
  created_at: FirebaseFirestore.Timestamp
  updated_at: FirebaseFirestore.Timestamp
  text: string
  // This field will be used to display "assigned to me" or "assigned to someone else" in the comment level
  assignment?: {
    uid: string
    email: string
  }
}

interface BaseDiscussion {
  oid: string
  deleted: boolean
  created_at: FirebaseFirestore.Timestamp
  updated_at: FirebaseFirestore.Timestamp
  author_email: string
  author_uid: string
  assignment?: {
    // User who the discussion is assigned to.
    uid: string
    email: string
    timestamp: FirebaseFirestore.Timestamp

    // TODO(mgraczyk): Migrate and make this required.
    assigned_by_uid?: string
    assigned_by_email?: string
  }
  resolution?: {
    // User who resolved the discussion.
    uid: string
    email: string
    timestamp: FirebaseFirestore.Timestamp
  }
}

export type KnowledgeDiscussionProps = {
  kind: "KNOWLEDGE_ITEM"
  user_document_oid: string
}

export type UserDocumentDiscussionProps = {
  kind: "USER_DOCUMENT"
  user_document_oid: string
}

export type SheetDiscussionProps = {
  kind: "SHEET"
  location: WorksheetRange
  // TODO(mgraczyk): Migrate to job_oid and remove discussions for missing jobs.
  // When migrating, be sure to change the untyped queries in jobs/helpers.py
  // and elsewhere.
  job_id: string

  // TODO(mgraczyk): Migrate and make required (null when missing).
  response_id?: string | null
}

export type KnowledgeDiscussion = BaseDiscussion & KnowledgeDiscussionProps
export type UserDocumentDiscussion = BaseDiscussion &
  UserDocumentDiscussionProps
export type SheetDiscussion = BaseDiscussion & SheetDiscussionProps

export type Discussion =
  | KnowledgeDiscussion
  | SheetDiscussion
  | UserDocumentDiscussion
export type DiscussionProps =
  | KnowledgeDiscussionProps
  | SheetDiscussionProps
  | UserDocumentDiscussionProps

export interface DiscussionDeleteRequest {
  group_oid: string
  discussion_oid: string
}

export interface DiscussionResolutionRequest {
  group_oid: string
  discussion_oid: string
}

export interface CommentDeleteRequest {
  group_oid: string
  discussion_oid: string
  comment_oid: string
}

export interface BaseCommentCreationRequest {
  text: string
  group_oid: string
  discussion_oid?: string
  assignment?: {
    uid: string
    email: string
  }
}

export type SheetCommentCreationRequest = BaseCommentCreationRequest &
  SheetDiscussionProps

export type KnowledgeCommentCreationRequest = BaseCommentCreationRequest &
  KnowledgeDiscussionProps

export type UserDocumentCommentCreationRequest = BaseCommentCreationRequest &
  UserDocumentDiscussionProps

export type CommentCreationRequest =
  | SheetCommentCreationRequest
  | KnowledgeCommentCreationRequest
  | UserDocumentCommentCreationRequest

export interface CommentUpdateRequest {
  text?: string
  assignment?: {
    uid: string
    email: string
  } | null
  group_oid: string
  discussion_oid: string
  comment_oid: string
}

export interface AssignKnowledgeItemsInBulkRequest {
  text: string
  group_oid: string
  user_document_oids: string[]
  assignment: {
    uid: string
    email: string
  }
}

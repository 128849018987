// Wrapper around the application that handles showing the signin page if the user is not signed in.
import { Suspense, lazy } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"

import LoadingSpinner from "../components/LoadingSpinner"
import PageBackgroundContainer from "../components/PageBackgroundContainer"
import QuiltLogo from "../components/QuiltLogo"
import TopLevelPageContainer from "../components/TopLevelPageContainer"
import { useQuiltAuthState } from "../contexts/useQuiltAuthState"
import AuthResponsePage from "../pages/AuthResponsePage"
import SignInPage from "../pages/SignInPage"
import ImpersonatorLogin from "./ImpersonatorLogin"

const ExtensionGoogleAuth = lazy(() => import("../pages/ExtensionGoogleAuth"))
const ZoomHomePage = lazy(() => import("./ZoomLive"))

const AppSignInWrapper: React.FC = () => {
  const { authUser, loading, error } = useQuiltAuthState()

  let page: React.ReactNode
  if (authUser) {
    page = <TopLevelPageContainer />
  } else {
    let content: React.ReactNode
    if (loading) {
      content = (
        <div className="flex h-full w-full flex-col items-center">
          <QuiltLogo className="mt-64" width="16rem" />
          <LoadingSpinner className="!h-20" />
        </div>
      )
    } else if (error) {
      // TODO: Add error page
      content = (
        <>
          <h2>Auth load Error:</h2> <div>{error.toString()}</div>
        </>
      )
    } else {
      content = <SignInPage />
    }

    page = <PageBackgroundContainer>{content}</PageBackgroundContainer>
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/extension-auth/google"
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <ExtensionGoogleAuth user={authUser} />
            </Suspense>
          }
        />
        <Route path="/auth/response" element={<AuthResponsePage />} />
        <Route path="/zoom/home" element={<ZoomHomePage />} />
        <Route path="/impersonator-login" element={<ImpersonatorLogin />} />
        <Route path="*" element={page} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppSignInWrapper

import { Popover, Skeleton } from "antd"
import { useState } from "react"

import { getFrontendUrl } from "../constants"
import { getErrorMessage } from "../errors"
import {
  getReferenceColorClass,
  useEnrichedReference,
} from "../hooks/useEnrichedReference"
import ReferenceBadge from "../hooks/useEnrichedReference/ReferenceBadge"
import type { AnswerReference } from "../types/answerer"

const ChatReference: React.FC<{
  reference: AnswerReference
  idx: number
  loading: boolean
  loadKnowledgeItemEagerly?: boolean
  isVerbatimFromAnswerBank?: boolean
}> = ({
  reference,
  idx,
  loadKnowledgeItemEagerly,
  isVerbatimFromAnswerBank,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
  const { enrichedReference, isLoading, error } = useEnrichedReference(
    reference,
    "popover",
    loadKnowledgeItemEagerly || isPopoverOpen,
  )

  const content = isLoading ? (
    <Skeleton active paragraph={{ rows: 4 }} />
  ) : error ? (
    getErrorMessage({
      prefix: "Could not load the reference. It may have been deleted",
      error,
    })
  ) : (
    enrichedReference.content
  )

  const title = isLoading
    ? "Loading..."
    : error
      ? "Error Loading"
      : enrichedReference.title
  const subtitle = isLoading ? null : enrichedReference.subtitle

  const linkHref = getFrontendUrl() + enrichedReference.path
  const contentUrl = isLoading || error ? "" : enrichedReference.contentUrl

  const colorClass = getReferenceColorClass(reference)
  // TODO(mgraczyk): Make the title a direct external link (contentUrl).
  return (
    <>
      <Popover
        onOpenChange={(e) => {
          setIsPopoverOpen(e.valueOf())
        }}
        title={
          <div className="w-64">
            <a className="font-bold" href={contentUrl} target="_blank">
              {title}
              <br />
              <ReferenceBadge reference={reference} />
            </a>
            {subtitle && (
              <div className="text-sm font-normal text-slate-500">
                {subtitle}
              </div>
            )}
          </div>
        }
        content={<div className="max-h-96 w-64 overflow-y-auto">{content}</div>}
        placement="bottom"
      >
        <div>
          <a
            href={linkHref}
            target="_blank"
            className={
              "b mr-1 flex cursor-pointer items-center justify-center text-pretty rounded-sm " +
              (isVerbatimFromAnswerBank ? " px-2 " : " h-5 w-5 ") +
              colorClass
            }
          >
            {isVerbatimFromAnswerBank
              ? "Answer straight from answer bank"
              : idx + 1}
          </a>
        </div>
      </Popover>
    </>
  )
}

export default ChatReference

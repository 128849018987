import { Button } from "antd"
import { Image } from "antd"
import { useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import QuiltLogo from "../assets/img/logo_only.svg"
import SlackLogo from "../assets/img/slack_logo.svg"
import useErrorPopup from "../hooks/useErrorPopup"
import { associateSlackIdWithUserUid } from "../slack/api"
import LoadingSpinner from "./LoadingSpinner"

const Header: React.FC = () => {
  return (
    <div className="m-8 flex items-center justify-center gap-2">
      <Image
        width={124}
        height={124}
        preview={false}
        src={SlackLogo}
        alt="Slack Logo"
      />
      <span className="text-large mx-4">➕</span>
      <Image
        width={124}
        height={124}
        preview={false}
        src={QuiltLogo}
        alt="Quilt Logo"
      />
    </div>
  )
}

const SlackLinkAccount: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [result, setResult] = useState<"success" | "error" | undefined>(
    undefined,
  )
  const navigate = useNavigate()
  const { handleSuccess, handleError } = useErrorPopup()
  const [searchParams] = useSearchParams()
  const token = searchParams.get("token")
  const handleSignUp = async (): Promise<void> => {
    setLoading(true)
    try {
      await associateSlackIdWithUserUid({ token: token || "" })
      handleSuccess("Successfully linked your Slack account")
      setResult("success")
    } catch (error) {
      handleError({ error, prefix: "Couldn't link Slack account" })
      setResult("error")
    } finally {
      setLoading(false)
    }
  }

  const Content: React.FC = () => {
    if (loading) {
      return (
        <div className="flex items-center justify-center p-12">
          <LoadingSpinner />
        </div>
      )
    }
    if (result === "success") {
      return (
        <>
          <p className="text-center">Successfully linked your Slack account!</p>
          <Button
            onClick={() => navigate("/")}
            disabled={loading}
            type="primary"
            size="large"
          >
            Return Home
          </Button>
        </>
      )
    }
    if (result === "error") {
      return (
        <>
          <p className="text-center">
            Failed to link your Slack account. Please try again later.
          </p>
        </>
      )
    }

    return (
      <>
        {token ? (
          <p className="text-center">
            Link your Slack account to get started with the{" "}
            <strong>Quilt for Slack</strong> app.
          </p>
        ) : (
          <p className="text-center">
            Your association link is invalid. Please try again from the Slack
            app.
          </p>
        )}
        <Button
          onClick={handleSignUp}
          disabled={loading || !token}
          type="primary"
          size="large"
        >
          Link Slack Account
        </Button>
      </>
    )
  }

  return (
    <div className="flex items-center justify-center px-8 py-16">
      <div className="flex max-w-[50vw] flex-col space-y-4">
        <Header />
        <Content />
      </div>
    </div>
  )
}

export default SlackLinkAccount

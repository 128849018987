import { doc, updateDoc } from "firebase/firestore"

import { db } from "../firebaseApp"
import {
  LIVE_ASSISTED_CALLS_COLLECTION,
  LIVE_UI_CARDS_SUBCOLLECTION,
} from "./db"
import type { LiveUICard } from "./types"

export const dismissCard = async (
  callOid: string,
  card: LiveUICard,
): Promise<void> => {
  await updateDoc(
    doc(
      db,
      LIVE_ASSISTED_CALLS_COLLECTION,
      callOid,
      LIVE_UI_CARDS_SUBCOLLECTION,
      card.oid,
    ),
    { dismissed: true },
  )
}

export const markCardSeen = async (
  callOid: string,
  card: LiveUICard,
): Promise<void> => {
  await updateDoc(
    doc(
      db,
      LIVE_ASSISTED_CALLS_COLLECTION,
      callOid,
      LIVE_UI_CARDS_SUBCOLLECTION,
      card.oid,
    ),
    { seen: true },
  )
}

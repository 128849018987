import { Table } from "antd"
import type { ColumnType } from "antd/es/table"
import type { Timestamp } from "firebase/firestore"

import { getInterpretableTimeString } from "../../timeUtils"

const { Column } = Table

interface Args {
  title: string
  dataIndex: string
  width?: number
  sortable?: boolean
}

const getSorter =
  (dataIndex: string) =>
  (
    a: { [dataIndex: string]: FirebaseFirestore.Timestamp },
    b: { [dataIndex: string]: FirebaseFirestore.Timestamp },
  ): number => {
    const aD = a[dataIndex] ?? null
    const bD = b[dataIndex] ?? null
    if (aD === null && bD === null) {
      return 0
    } else if (aD === null) {
      return -1
    } else if (bD === null) {
      return 1
    } else {
      return aD._compareTo(bD)
    }
  }

const renderTimestamp = (timestamp?: Timestamp) =>
  timestamp && (
    <div className="text-gray-700">{getInterpretableTimeString(timestamp)}</div>
  )

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dateColumnProps = <T extends Record<PropertyKey, any>>({
  title,
  dataIndex,
  width,
  sortable = false,
}: Args): ColumnType<T> => {
  return {
    title,
    dataIndex,
    width,
    defaultSortOrder: "descend",
    sorter: sortable && getSorter(dataIndex),
    render: renderTimestamp,
  }
}

const dateColumn = ({ title, dataIndex, width, sortable = false }: Args) => {
  return <Column {...dateColumnProps({ title, dataIndex, width, sortable })} />
}

export default dateColumn

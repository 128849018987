import { Tag } from "antd"

import { type AnswerReference, AnswerReferenceKind } from "../../types/answerer"
import { getReferenceTagColorClass } from "./index"

const ReferenceBadge: React.FC<{
  reference: AnswerReference
  isVerbatimFromAnswerBank?: boolean
}> = ({ reference, isVerbatimFromAnswerBank }) => (
  <Tag
    className="text-xs font-semibold"
    rootClassName={getReferenceTagColorClass(reference)}
  >
    {isVerbatimFromAnswerBank
      ? "Answer straight from answer bank"
      : reference.kind === AnswerReferenceKind.KNOWLEDGE_ITEM
        ? "Answer Bank"
        : "Source Document"}
  </Tag>
)

export default ReferenceBadge

import { Avatar } from "antd"

import fallbackImage from "../../assets/img/logo_only.svg"

const GroupImage: React.FC<{ imageUrl?: string; size?: number }> = ({
  imageUrl,
  size,
}) => {
  return (
    <div>
      <Avatar
        className="border p-3 shadow-md"
        size={size ?? 96}
        src={imageUrl ? imageUrl : fallbackImage}
      />
    </div>
  )
}

export default GroupImage

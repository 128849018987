import { Alert, Button } from "antd"
import { Link, useNavigate } from "react-router-dom"

import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import "./SubscriptionExpiredBanner.css"

const SubscriptionExpiredBanner: React.FC = () => {
  const navigate = useNavigate()
  const { isDisabled } = useActiveUserAuthorizationFromContext()

  if (!isDisabled) {
    return null
  }
  return (
    <Alert
      className="free-trial-banner fixed bottom-0 h-[20vh] w-full text-center"
      closable={true}
      message={
        <div>
          Your free trial has expired. Please{" "}
          <Link className="text-purple-500" to="/billing/plans">
            upgrade
          </Link>{" "}
          to continue using Quilt
          <Button
            className="ml-[10px]"
            type="primary"
            onClick={() => navigate("/billing/plans")}
          >
            Upgrade Plan
          </Button>
        </div>
      }
      type="warning"
      showIcon
      banner
    />
  )
}
export default SubscriptionExpiredBanner

import type { GetRef, Table } from "antd"
import { useEffect, useRef, useState } from "react"
import { useDebounceCallback, useResizeObserver } from "usehooks-ts"

const DEFAULT_SCROLL = { y: 200 }

// Get a ref and a scroll prop for a table that resizes based on the container
// height.
const useTableScroll = (): [
  React.LegacyRef<GetRef<typeof Table>>,
  { y: number },
] => {
  const [result, setResult] = useState<{ y: number }>(DEFAULT_SCROLL)
  const tableContainerRef: React.ComponentProps<typeof Table>["ref"] =
    useRef(null)

  const onResize = useDebounceCallback(() => {
    const container = tableContainerRef.current?.nativeElement
    if (!container) {
      setResult(DEFAULT_SCROLL)
      return
    }
    const { height } = container.getBoundingClientRect()

    const tableWrapper = tableContainerRef.current?.nativeElement
    if (!tableWrapper) {
      setResult(DEFAULT_SCROLL)
      return
    }

    // Subtract off the height of the table header.
    const header = tableWrapper.getElementsByTagName("thead")[0]
    if (!header) {
      return
    }

    const { height: headerHeight } = header.getBoundingClientRect()
    const extraBorderPixel = 1

    const y = height - headerHeight - extraBorderPixel
    setResult({ y })
  }, 250)

  useResizeObserver({
    ref: { current: tableContainerRef.current?.nativeElement ?? null },
    onResize,
  })

  // Call once on load.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onResize, [])

  return [tableContainerRef, result]
}
export default useTableScroll

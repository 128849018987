import { Skeleton } from "antd"
import { ListTodoIcon } from "lucide-react"

import AddDocumentPickerButton from "../../components/AddDocumentPickerButton"

interface Props {
  numDocs: number
  loading: boolean
}

const SourceDocumentTile: React.FC<Props> = ({ numDocs, loading }) => {
  return (
    <div className="flex flex-1 flex-col gap-4 overflow-hidden md:flex-row">
      <div className="flex-grow rounded-2xl border border-gray-100 p-6">
        <div className="mb-4 flex items-center text-sm font-bold text-gray-400">
          <ListTodoIcon className="mr-2" size={18} />{" "}
          <div>Source Documents</div>
        </div>
        <h4>Documents you have uploaded</h4>
        <Skeleton
          className="mb-2"
          active
          loading={loading}
          paragraph={{ rows: 1 }}
        >
          <div className="text-7xl">{numDocs}</div>
        </Skeleton>
        <p className="mb-1">
          Start by uploading a{" "}
          <b>SOC2 document, previously answered questionnaires</b>, etc.
        </p>
        <AddDocumentPickerButton isCompletedQuestionnaireActions={false} />
      </div>
    </div>
  )
}

export default SourceDocumentTile

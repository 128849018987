import { Button, Popover, Select, Skeleton } from "antd"
import { VoteIcon } from "lucide-react"
import { useCallback, useState } from "react"

import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { useKeydown } from "../hooks/events"
import useErrorPopup from "../hooks/useErrorPopup"
import { useGroupMembers } from "../hooks/useGroupMembers"
import {
  assignQuestionnaireJobAnswers,
  unassignQuestionnaireJobAnswers,
} from "../pages/QuestionnaireAssistant/api"
import type { AnswerActor } from "../types/jobs"

interface AssignFormProps {
  onClose: () => void
  jobOid: string
  answerOid: string
  assignedTo?: AnswerActor | null
  isSubmitting: boolean
  setSubmitting: (value: boolean) => void
}

const AssignQuestionnaireAnswerForm: React.FC<AssignFormProps> = ({
  onClose,
  jobOid,
  answerOid,
  assignedTo,
  isSubmitting,
  setSubmitting,
}) => {
  const [selectedMember, setSelectedMember] = useState<string | undefined>(
    assignedTo?.uid ?? "",
  )
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()
  const [members, loading] = useGroupMembers(activeGroupOid)
  const { handleSuccess, handleError } = useErrorPopup()

  useKeydown(27 /*escape*/, onClose)

  const member = members?.find((m) => m.uid === selectedMember)

  const unAssign = useCallback(async () => {
    if (!assignedTo) {
      return
    }

    setSubmitting(true)
    try {
      await unassignQuestionnaireJobAnswers(jobOid, [answerOid])
      handleSuccess("Unassigned the questionnaire answer")
      onClose()
      setSelectedMember(undefined)
    } catch (error) {
      handleError({
        error,
        prefix: "Couldn't unassign questionnaire answer",
      })
    } finally {
      setSubmitting(false)
    }
  }, [
    assignedTo,
    handleError,
    setSelectedMember,
    handleSuccess,
    onClose,
    jobOid,
    answerOid,
    setSubmitting,
  ])

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      e.stopPropagation()
      if (!selectedMember || !member) {
        return
      }

      setSubmitting(true)
      try {
        await assignQuestionnaireJobAnswers(jobOid, [answerOid], {
          uid: member.uid,
          email: member.email,
        })
        handleSuccess("Assigned the questionnaire answer")
        onClose()
      } catch (error) {
        handleError({
          error,
          prefix: "Couldn't assign questionnaire answer",
        })
      } finally {
        setSubmitting(false)
      }
    },
    [
      handleError,
      handleSuccess,
      selectedMember,
      onClose,
      jobOid,
      answerOid,
      member,
      setSubmitting,
    ],
  )

  if (loading) {
    return <Skeleton.Input active className="block h-4 w-full" />
  }

  return (
    <form className="flex w-[400px] text-sm" action="" onSubmit={onSubmit}>
      <Select
        placeholder="Select the assignee..."
        value={selectedMember}
        onChange={setSelectedMember}
        showSearch
        className="w-full"
        optionFilterProp="label"
        options={members?.map((member) => ({
          label: member.email,
          value: member.uid,
        }))}
      />
      {assignedTo && (
        <Button
          disabled={isSubmitting}
          className="ml-2"
          type="default"
          onClick={unAssign}
        >
          Remove
        </Button>
      )}
      <Button
        disabled={
          isSubmitting || !selectedMember || selectedMember === assignedTo?.uid
        }
        className="ml-2"
        type="primary"
        htmlType="submit"
      >
        {isSubmitting ? "Assigning..." : "Assign"}
      </Button>
    </form>
  )
}

interface ButtonProps {
  jobOid: string
  answerOid: string
  assignedTo?: AnswerActor | null
}

const AssignQuestionnaireAnswerButton: React.FC<ButtonProps> = ({
  jobOid,
  answerOid,
  assignedTo,
}) => {
  const [open, setOpen] = useState(false)
  const hide = useCallback(() => {
    setOpen(false)
  }, [setOpen])
  const [isSubmitting, setSubmitting] = useState<boolean>(false)

  return (
    <Popover
      content={
        <AssignQuestionnaireAnswerForm
          jobOid={jobOid}
          answerOid={answerOid}
          onClose={hide}
          assignedTo={assignedTo}
          isSubmitting={isSubmitting}
          setSubmitting={setSubmitting}
        />
      }
      trigger="click"
      placement="bottomLeft"
      open={open}
      onOpenChange={setOpen}
    >
      <Button
        type="primary"
        icon={<VoteIcon />}
        title="Change Owner"
        className="flex items-center justify-center"
        disabled={isSubmitting}
        loading={isSubmitting}
      >
        <span className="font-bold">
          {isSubmitting
            ? "Changing owner..."
            : assignedTo
              ? "Owned"
              : "Change Owner"}
        </span>
      </Button>
    </Popover>
  )
}

export default AssignQuestionnaireAnswerButton

import { Card, Skeleton } from "antd"

import emptyTags from "../../assets/img/empty_icons/source_document.svg"
import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import type { Group } from "../../groups/types"
import { useGroupTags } from "../../hooks/useGroupTags"
import Tag from "../../tags/Tag"
import type { GroupTag } from "../../tags/types"
import { toHumanReadableString } from "../../utils"
import EditTagDropdown from "./EditTagDropdown"

interface TagCardProps {
  tag: GroupTag
  group: Group
  canEdit: boolean
}

const TagCard: React.FC<TagCardProps> = ({ tag, group, canEdit }) => {
  return (
    <Card
      key={tag.oid}
      className="relative flex items-center rounded-sm border p-2 shadow-sm"
      classNames={{
        body: "!p-0 w-full justify-between flex-wrap-reverse flex-row flex",
      }}
    >
      <div className="mr-auto flex items-center">
        <div className="mx-2">
          <div className="mb-2 text-sm text-slate-500">
            {toHumanReadableString(tag.created_at)}
          </div>
          <Tag tag={tag} />
        </div>
      </div>
      {canEdit && (
        <div className="flex flex-row justify-end">
          <EditTagDropdown group={group} tag={tag} />
        </div>
      )}
    </Card>
  )
}

const TagLoadingCards: React.FC = () => (
  <>
    {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      [...Array(6)].map((_, i) => (
        <Card
          key={i}
          className="relative flex items-center rounded-sm border p-2 shadow-sm"
          classNames={{
            body: "!p-0 w-full",
          }}
        >
          <Skeleton loading active title={false} paragraph={{ rows: 1 }} />
        </Card>
      ))
    }
  </>
)

interface GroupTagsManagerProps {
  group: Group
}

const GroupTagsManager: React.FC<GroupTagsManagerProps> = ({ group }) => {
  const { hasPerm } = useActiveUserAuthorizationFromContext()

  const [tags, loading] = useGroupTags(group.oid)

  return (
    <div className="mb-8">
      <section>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          {loading ? (
            <TagLoadingCards />
          ) : (
            tags?.map((tag) => (
              <TagCard
                key={tag.oid}
                group={group}
                tag={tag}
                canEdit={hasPerm("admin")}
              />
            ))
          )}
        </div>
        {!loading && (!tags || tags.length === 0) ? (
          <div className="rounded border border-gray-50 p-4 text-center">
            <div>
              <img src={emptyTags} className="mx-auto w-20" />
            </div>
            <span className="text-center text-gray-500">
              Your tag list is empty
            </span>
          </div>
        ) : null}
      </section>
    </div>
  )
}

export default GroupTagsManager

import { Button, Checkbox, Mentions } from "antd"
import { collection, limit, orderBy, query, where } from "firebase/firestore"
import { useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"

import { makeConverter } from "../../dbUtils"
import { createComment, deleteComment } from "../../discussions/api"
import {
  COMMENTS_SUBCOLLECTION,
  type Comment,
  DISCUSSION_COLLECTION,
  type Discussion,
  type DiscussionProps,
} from "../../discussions/types"
import { db } from "../../firebaseApp"
import type { GroupMembership } from "../../groups/types"
import useErrorPopup from "../../hooks/useErrorPopup"
import { GROUPS_COLLECTION } from "../../types/common"
import { getColumnLetter } from "../../utils"
import LoadingSpinner from "../LoadingSpinner"
import { CommentComponent } from "./Comment"

export const DiscussionComponent = (
  props: {
    group_oid: string
    members?: GroupMembership[]
    className?: string
    discussion?: Discussion
  } & DiscussionProps,
) => {
  const { handleError } = useErrorPopup()
  const { group_oid, members, discussion, className, ...args } = props
  const [draftComment, setDraftComment] = useState<string>("")
  const [assignedMember, setAssignedMember] = useState<GroupMembership>()
  const [loading, setLoading] = useState(false)

  const locationLabel =
    props.kind === "SHEET"
      ? `${getColumnLetter(props.location.firstColIndex)}${props.location.firstRowIndex}`
      : undefined

  const colRef = discussion
    ? collection(
        db,
        GROUPS_COLLECTION,
        group_oid,
        DISCUSSION_COLLECTION,
        discussion.oid,
        COMMENTS_SUBCOLLECTION,
      ).withConverter(makeConverter<Comment>())
    : null

  const commentsQuery = colRef
    ? query(
        colRef,
        where("deleted", "==", false),
        orderBy("created_at", "asc"),
        limit(200),
      )
    : null
  const [comments, commentsLoading] = useCollectionData(commentsQuery)

  const onSubmitComment = async () => {
    if (!draftComment || !group_oid) {
      return
    }
    const createCommentArgs = {
      group_oid,
      discussion_oid: discussion?.oid,
      text: draftComment,
      assignment: assignedMember
        ? {
            uid: assignedMember.uid,
            email: assignedMember.email,
          }
        : undefined,
      ...args,
    }
    setLoading(true)

    try {
      await createComment(createCommentArgs)
      setDraftComment("")
    } catch (error) {
      handleError({ error })
    } finally {
      setLoading(false)
    }
  }

  if (commentsLoading) return <LoadingSpinner />
  const mentionedMember = members?.find((member) =>
    Mentions.getMentions(draftComment)
      .map((m) => m.value)
      .includes(member.email),
  )

  return (
    <>
      <div className={className}>
        {comments?.map((comment, index) => (
          <CommentComponent
            key={index}
            comment={comment}
            onDelete={async () => {
              if (!discussion) return
              await deleteComment({
                group_oid,
                discussion_oid: discussion.oid,
                comment_oid: comment.oid,
              })
            }}
          />
        ))}
      </div>
      <Mentions
        autoSize
        allowClear
        placeholder={
          discussion
            ? "Write a reply..."
            : locationLabel
              ? `Start a new discussion at ${locationLabel}...`
              : "Start a new discussion..."
        }
        options={members?.map((member) => ({
          value: member.email,
          label: member.email,
        }))}
        value={draftComment}
        onChange={(text) => setDraftComment(text)}
      />
      <Button
        className="mr-2 mt-4"
        type="primary"
        onClick={onSubmitComment}
        loading={loading}
        disabled={loading || !draftComment}
      >
        Comment
      </Button>
      {mentionedMember ? (
        <Checkbox
          onChange={(e) => {
            if (e.target.checked) {
              setAssignedMember(mentionedMember)
            } else {
              setAssignedMember(undefined)
            }
          }}
          className="mt-2 text-xs"
        >
          Assign to {mentionedMember.email}
        </Checkbox>
      ) : null}
    </>
  )
}

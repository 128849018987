import { Button, Result } from "antd"
import { useNavigate } from "react-router-dom"

import imageSrc from "../assets/img/quilt_coming_soon.jpeg"
import Header from "./Header"

const ComingSoonPage: React.FC<{ title: string }> = ({ title }) => {
  const navigate = useNavigate()
  const goHome = () => {
    navigate("/")
  }

  const goContactSales = () => {
    window.open("mailto:founders@quilt.app", "_blank", "noopener,noreferrer")
  }

  return (
    <>
      <Header title={title} subtitle="Nothing here yet, coming soon!"></Header>
      <div className="m-8">
        <Result
          icon={
            <img
              className="m-auto h-[256px] w-[256px]"
              src={imageSrc}
              alt="Coming Soon"
              width={1024}
              height={1024}
            />
          }
          subTitle="We are launching soon, please contact sales for details!"
          extra={
            <>
              <Button type="primary" onClick={goHome}>
                Go Home
              </Button>
              <Button type="primary" onClick={goContactSales}>
                Contact Sales
              </Button>
            </>
          }
        />
      </div>
    </>
  )
}

export default ComingSoonPage

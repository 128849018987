import { Button, DatePicker, Form, Input, Select } from "antd"
import dayjs from "dayjs"
import { useCallback, useEffect, useState } from "react"

import { getGongUsers } from "../gong/api"
import type { GongUser } from "../gong/types"
import { useKeydown } from "../hooks/events"
import useErrorPopup from "../hooks/useErrorPopup"
import { updateOrCreateSource } from "../sources/api"
import type { GongSource } from "../sources/types"
import type { GongFilterPredicate, GongFilterSpec } from "../sources/types"

interface FormDataType {
  userIds: string[]
  // Antd DatePicker requires DayJs object
  startDate: dayjs.Dayjs
  name: string
}

const getInitialValues = (source: GongSource | undefined) => {
  if (!source) {
    return {
      userIds: [],
      startDate: undefined,
      name: undefined,
    }
  }

  const filterSpec = source.source_meta.filter_spec
  const userIds = filterSpec.AND?.find(
    (predicate) => predicate.field === "primaryUserId",
  )?.value
  const startDate = filterSpec.AND?.find(
    (predicate) => predicate.field === "started",
  )?.value
  const name = filterSpec.AND?.find(
    (predicate) => predicate.field === "title",
  )?.value

  const startDateAsDayjs =
    startDate instanceof Date
      ? dayjs(startDate)
      : startDate
        ? dayjs(startDate.toDate())
        : undefined
  return {
    userIds,
    startDate: startDateAsDayjs,
    name,
  }
}

const GongPickerForm: React.FC<{
  source: GongSource | undefined
  onClose: () => void
  gongUsers: GongUser[]
  loading: boolean
  error: Error | null
}> = ({ source, onClose, gongUsers, loading, error }) => {
  const [form] = Form.useForm<FormDataType>()
  const { handleSuccess, handleError } = useErrorPopup()
  const initialValues = getInitialValues(source)
  const [submitting, setSubmitting] = useState<boolean>(false)

  const getFilterSpecFromForm = (formData: FormDataType): GongFilterSpec => {
    const requiredPredicates: GongFilterPredicate[] = [
      {
        field: "primaryUserId",
        operator: "in",
        value: formData.userIds,
      },
      {
        field: "started",
        operator: ">=",
        value: formData.startDate.toDate(),
      },
    ]
    const titlePredicate: GongFilterPredicate[] = formData.name
      ? [
          {
            field: "title",
            operator: "contains",
            value: formData.name,
          },
        ]
      : []

    return {
      AND: [...requiredPredicates, ...titlePredicate],
    }
  }

  const onSubmitForm = useCallback(
    async (formData: FormDataType) => {
      try {
        setSubmitting(true)
        const filterSpec = getFilterSpecFromForm(formData)
        await updateOrCreateSource({
          kind: "GONG",
          source_meta: {
            filter_spec: filterSpec,
          },
        })
        handleSuccess(
          "Successfully updated Gong data source. Your calls will be synced in the background",
        )
        onClose()
      } catch (error) {
        handleError({ error, message: "Could not update Gong Source" })
      } finally {
        setSubmitting(false)
      }
    },
    [handleSuccess, handleError, onClose],
  )

  const userIdOptions = gongUsers.map(({ id, emailAddress }) => ({
    label: emailAddress,
    value: id,
  }))
  console.log("userIdOptions", userIdOptions)

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmitForm}
      initialValues={initialValues}
      className="min-w-[500px]"
      disabled={loading || submitting}
    >
      {error ? (
        <div>Error loading data: {error.message}</div>
      ) : (
        <>
          <Form.Item
            label="Users"
            name="userIds"
            rules={[
              { required: true, message: "Please select at least one user" },
            ]}
          >
            <Select<string[]>
              mode="multiple"
              placeholder="Select users"
              options={userIdOptions}
              optionFilterProp="label"
              loading={loading}
            />
          </Form.Item>
          <Form.Item
            label="Start Date"
            name="startDate"
            rules={[{ required: true, message: "Please select a start date" }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Call Title Includes"
            tooltip="If provided, only calls with titles that include this text will be synced"
            name="name"
            rules={[{ required: false }]}
          >
            <Input placeholder="Intro..." />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Confirm
            </Button>
          </Form.Item>
        </>
      )}
    </Form>
  )
}

const GongPicker: React.FC<{
  source: GongSource | undefined
  onClose: () => void
}> = ({ source, onClose }) => {
  const [allGongUsers, setAllGongUsers] = useState<GongUser[]>([])
  const [error, setError] = useState<Error | null>(null)
  const [dataLoading, setDataLoading] = useState(true)

  useKeydown(
    27, // Escape
    onClose,
  )

  useEffect(() => {
    const fetchGongUsers = async () => {
      setDataLoading(true)
      try {
        const gongUsers = await getGongUsers()
        setAllGongUsers(gongUsers)
      } catch (error) {
        console.error("Error fetching Gong users", error)
        if (error instanceof Error) {
          setError(error)
        }
      } finally {
        setDataLoading(false)
      }
    }
    void fetchGongUsers()
  }, [])

  return (
    <GongPickerForm
      source={source}
      onClose={onClose}
      gongUsers={allGongUsers}
      error={error}
      loading={dataLoading}
    />
  )
}

export default GongPicker

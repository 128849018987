import { functionsAxiosInstance } from "../../api"
import {
  ATLASSIAN_PROVIDER,
  GONG_PROVIDER,
  NOTION_PROVIDER,
  ZOOM_PROVIDER,
} from "../../credentials/providerTypes"
import { openUrlInPopup } from "../../windowUtils"

interface OAuthProviderInfo {
  baseUrl: string
  redirectSuffix: string
  client_id?: string
  extraParams?: Record<string, string>
}

const OAUTH_PROVIDER_MAPPING: Record<string, OAuthProviderInfo> = {
  [NOTION_PROVIDER]: {
    baseUrl: "https://api.notion.com/v1/oauth/authorize",
    redirectSuffix: "/v1/notionAuthCallback",
    client_id: import.meta.env.VITE_NOTION_OAUTH_CLIENT_ID,
  },
  [GONG_PROVIDER]: {
    baseUrl: "https://app.gong.io/oauth2/authorize",
    redirectSuffix: "/v1/gongAuthCallback",
    client_id: import.meta.env.VITE_GONG_OAUTH_CLIENT_ID,
  },
  [ZOOM_PROVIDER]: {
    baseUrl: "https://zoom.us/oauth/authorize",
    redirectSuffix: "/v1/zoomAuthCallback",
    client_id: import.meta.env.VITE_ZOOM_OAUTH_CLIENT_ID,
  },
  [ATLASSIAN_PROVIDER]: {
    baseUrl: "https://auth.atlassian.com/authorize",
    redirectSuffix: "/v1/atlassianAuthCallback",
    client_id: import.meta.env.VITE_ATLASSIAN_OAUTH_CLIENT_ID,
    // Required params for Atlassian only
    extraParams: {
      audience: "api.atlassian.com",
      scope:
        "read:page:confluence+read:confluence-content.all+read:confluence-content.permission+read:confluence-content.summary+read:confluence-groups+read:confluence-props+read:confluence-space.summary+read:confluence-user+readonly:content.attachment:confluence+search:confluence+offline_access",
      prompt: "consent",
    },
  },
}

const getOauthUrl = (
  provider: keyof typeof OAUTH_PROVIDER_MAPPING,
  state: string,
): string => {
  const { baseUrl, redirectSuffix, client_id } =
    OAUTH_PROVIDER_MAPPING[provider]

  if (!client_id) {
    throw new Error(`Missing ${provider} client id`)
  }

  const redirectUri = `${functionsAxiosInstance.defaults.baseURL}${redirectSuffix}`

  const params = new URLSearchParams({
    client_id,
    response_type: "code",
    owner: "user",
    redirect_uri: redirectUri,
    state,
    ...(OAUTH_PROVIDER_MAPPING[provider].extraParams ?? {}),
  })

  return `${baseUrl}?${params.toString()}`
}

export type OAUTH_PROVIDERS = keyof typeof OAUTH_PROVIDER_MAPPING

export async function doOauthFlow(
  provider: OAUTH_PROVIDERS,
  state: string,
): Promise<void> {
  const url = getOauthUrl(provider, state)
  return await openUrlInPopup(
    url,
    `${provider[0].toUpperCase()}${provider.slice(1)} OAuth`,
  )
}

import { Modal } from "antd"

export const confirmAndExecute = (
  onConfirmFunction: () => void,
  confirmationText: string,
) => {
  return () =>
    Modal.confirm({
      title: "Are you sure?",
      content: confirmationText,
      onOk: onConfirmFunction,
      okText: "Yes",
      cancelText: "No",
    })
}

// Shows confirmation modal, returns true on confirm, false on cancel.
export const getConfirmation = (content: string): Promise<boolean> => {
  return new Promise((resolve) =>
    Modal.confirm({
      title: "Are you sure?",
      content,
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
      okText: "Yes",
      cancelText: "No",
    }),
  )
}

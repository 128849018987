import { Skeleton } from "antd"
import { LoaderCircleIcon } from "lucide-react"
import React from "react"

const GridLoadingState = () => {
  return (
    <div className="flex w-full overflow-hidden">
      {/* Main spreadsheet area */}
      <div className="relative flex-grow overflow-auto border">
        <LoaderCircleIcon
          size={120}
          className="text-primary absolute left-[50%] top-16 animate-spin"
        />
        <div className="grid grid-cols-[auto_repeat(4,1fr)] gap-[1px] bg-gray-100">
          {/* Column headers */}
          <div className="h-8 bg-gray-50"></div>
          {"ABCD".split("").map((letter) => (
            <div
              key={letter}
              className="h-8 bg-gray-50 p-2 font-semibold leading-4 text-gray-500"
            >
              {letter}
            </div>
          ))}

          {/* Rows */}
          {[...Array<number>(25)].map((_, rowIndex) => (
            <React.Fragment key={rowIndex}>
              {/* Row first index */}
              <div className="flex h-8 w-12 items-center justify-center bg-gray-50" />
              {/* Cells */}
              {[...Array<number>(4)].map((_, colIndex) => (
                <div
                  key={colIndex}
                  className="flex h-8 items-center bg-white px-2"
                ></div>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>

      {/* Sidebar */}
      <div className="ml-2 w-80 overflow-y-hidden bg-gray-50 p-4">
        <div className="mb-4">
          <Skeleton.Input style={{ width: "100%" }} active size="small" />
        </div>
        {[...Array<number>(4)].map((_, index) => (
          <div key={index} className="mb-6">
            <div className="mb-2 flex items-center">
              <Skeleton.Avatar active size="small" />
              <div className="ml-2 flex-grow">
                <Skeleton.Input style={{ width: "60%" }} active size="small" />
              </div>
            </div>
            <Skeleton paragraph={{ rows: 2 }} active />
          </div>
        ))}
      </div>
    </div>
  )
}

export default GridLoadingState

import { Avatar } from "antd"

import { getBackgroundColor, getInitials } from "./utils"

interface UserBadgeProps {
  name: string
  photoURL?: string
  size?: "lg" | "sm" | "xs"
}

const UserAvatar: React.FC<UserBadgeProps> = ({
  name,
  photoURL,
  size = "lg",
}) => {
  return (
    <Avatar
      size={size === "lg" ? 50 : size === "sm" ? 40 : 30}
      className="text-lg"
      src={photoURL}
      rootClassName={getBackgroundColor(name)}
    >
      {getInitials(name)}
    </Avatar>
  )
}

export default UserAvatar

import { getGoogleOauthClientId } from "../constants"
import { httpsCallable } from "../serverUtils"

export const getGoogleAccessToken = httpsCallable<
  { requiredScopes: string[] },
  { accessToken: string }
>("getGoogleAccessToken")

export const getGoogleOauthTokenFromCode = httpsCallable<
  { code: string },
  { success: true; accessToken: string }
>("getGoogleOauthTokenFromCode")

const getGoogleOauthToken = (
  state: string,
  scopes: string[],
  expectedEmail: string | undefined,
): Promise<google.accounts.oauth2.CodeResponse> => {
  return new Promise((resolve, reject) => {
    const client = google.accounts.oauth2.initCodeClient({
      client_id: getGoogleOauthClientId(),
      scope: scopes.join(" "),
      ux_mode: "popup",
      redirect_uri: "postmessage",
      state,
      login_hint: expectedEmail,
      callback: (response) => {
        if (response.error) {
          console.error("Error getting auth code", response)
          return reject(new Error(response.error))
        }

        return resolve(response)
      },
      error_callback: (error) => {
        reject(error)
      },
    })
    client.requestCode()
  })
}

// Gets an OAuth code from Google with the required scopes.
// If the code cannot be gotten or the user does not give us the required
// scopes, throws an error with a user facing message.
//
// The code can be exchanged for a token using getGoogleOauthTokenFromCode.
export const getAndCheckGoogleOauthCode = async (
  requiredScopes: string[],
  expected_email: string | undefined = undefined,
): Promise<string> => {
  // Try to get the token.
  const state = Math.random().toString()
  const response = await getGoogleOauthToken(
    state,
    requiredScopes,
    expected_email,
  )
  if (response.error) {
    throw new Error(response.error_description)
  }
  if (response.state !== state) {
    throw new Error("State mismatch")
  }
  for (const scope of requiredScopes) {
    if (!response.scope.includes(scope)) {
      const errorMessage =
        "Google access permissions are required, please check all the boxes in the popup"
      throw new Error(errorMessage)
    }
  }

  return response.code
}

import type { User as AuthUser } from "firebase/auth"
import { useParams } from "react-router"

import Header from "../../components/Header"
import { BrowserCall } from "./Call"

const WebLiveAssistedSimulationPage: React.FC<{ user: AuthUser }> = () => {
  const { callOid = "" } = useParams<{ callOid: string }>()
  return (
    <>
      <Header
        title=""
        breadcrumbs={[
          { title: "Simulation", href: "/live-assistant-web" },
          {
            title: `Call ${callOid}`,
            href: `/live-assistant-simulation/${callOid}`,
          },
        ]}
        className="max-sm:hidden"
      />
      <BrowserCall callOid={callOid} isSimulation />
    </>
  )
}

export default WebLiveAssistedSimulationPage

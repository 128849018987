import type { PickOverUnion } from "../types/typeHelpers"

export const SOURCES_COLLECTION = "sources"

interface CarbonSourceMeta {
  data_source_external_id?: string | null
}

interface SourceBase {
  oid: string
  active: boolean

  sync_end: FirebaseFirestore.Timestamp | null
  last_synced_at: FirebaseFirestore.Timestamp | null

  removal_requested: boolean

  external_id: string

  group_oid: string
  creator_uid?: string | null
  creator_email?: string | null
  credential_oid?: string | null

  created_at: FirebaseFirestore.Timestamp
  updated_at: FirebaseFirestore.Timestamp
}

export interface CarbonSource extends SourceBase {
  kind: "CARBON_ONEDRIVE" | "CARBON_NOTION" | "CARBON_CONFLUENCE"
  source_meta: CarbonSourceMeta
}

export interface GongSource extends SourceBase {
  kind: "GONG"
  source_meta: {
    filter_spec: GongFilterSpec
  }
}

export interface SlackSource extends SourceBase {
  kind: "SLACK"
  source_meta: {
    channels: {
      channel_id: string
      name: string
      start_time: FirebaseFirestore.Timestamp | Date | undefined
    }[]
  }
}

export type Source = GongSource | CarbonSource | SlackSource

export type SourceUpdateOrCreateRequest = PickOverUnion<
  Source,
  "kind" | "source_meta"
>

interface FieldPredicateBase {
  AND?: undefined
  OR?: undefined
}

export interface GongStartDatePredicate extends FieldPredicateBase {
  field: "started"
  operator: ">="
  value: FirebaseFirestore.Timestamp | Date
}

export interface GongUserIdsPredicate extends FieldPredicateBase {
  field: "primaryUserId"
  operator: "in"
  value: string[]
}

export interface GongNamePredicate extends FieldPredicateBase {
  field: "title"
  operator: "contains"
  value: string
}

interface GongAndFilter {
  AND: GongFilterPredicate[]
  OR?: undefined
}
interface GongOrFilter {
  AND?: undefined
  OR: GongFilterPredicate[]
}

export type GongFilterPredicate =
  | GongStartDatePredicate
  | GongUserIdsPredicate
  | GongNamePredicate

export type GongFilterSpec = GongAndFilter | GongOrFilter | GongFilterPredicate

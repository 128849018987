import { LockIcon } from "lucide-react"
import { useEffect, useState } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import { useIntercom } from "react-use-intercom"

import HelpButton from "../components/HelpButton"
import QuiltLogo from "../components/QuiltLogo"
import SSOForm from "../components/SSOForm"
import SignInButton from "../components/SignInButton"
import SocialLoginForm from "../components/SocialLoginForm"
import { PRIVACY_URL, TERMS_URL } from "../constants"

const FormTitle: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <span className="px-2 pb-6 pt-5 text-center text-2xl font-bold">
    {children}
  </span>
)

const LegalSection: React.FC<{ actionText: string }> = ({ actionText }) => (
  <div>
    By clicking {actionText}, you agree to our{" "}
    <a
      className="text-primary font-medium hover:underline"
      href={TERMS_URL}
      target="_blank"
    >
      Terms
    </a>{" "}
    and{" "}
    <a
      className="text-primary font-medium hover:underline"
      href={PRIVACY_URL}
      target="_blank"
    >
      Privacy Policy
    </a>
  </div>
)

const SignInPage: React.FC = () => {
  const [errorString, setErrorString] = useState<string | undefined>()
  const { pathname } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const { showNewMessage: showIntercom } = useIntercom()

  // Show intercom on error.
  useEffect(() => {
    if (errorString) {
      showIntercom(
        `I'm having trouble signing in. I got this error: "${errorString}"`,
      )
    }
  }, [errorString, showIntercom])

  const isSignup = pathname.includes("/signup")
  const isSSO = searchParams.has("sso")
  const actionText = isSignup ? "Sign up" : "Continue"

  let loginForm: React.ReactNode
  const ssoButtonText = `${actionText} with SSO`
  if (isSSO) {
    loginForm = (
      <>
        <FormTitle>{"What's your email address?"}</FormTitle>
        <SSOForm
          ssoButtonText={ssoButtonText}
          setErrorString={setErrorString}
        />
      </>
    )
  } else {
    loginForm = (
      <>
        <SocialLoginForm
          actionText={actionText}
          setErrorString={setErrorString}
        />
        <SignInButton
          logo={<LockIcon className="text-yellow-800" size={20} />}
          loading={false}
          buttonText={ssoButtonText}
          onClick={() => setSearchParams({ sso: "1" })}
        />
      </>
    )
  }

  return (
    <div className="m-auto flex h-full w-[480px] flex-col items-center justify-start pt-[15%]">
      <QuiltLogo width="200px" className="mb-4" />
      <div className="flex w-[480px] flex-col items-center justify-center rounded-[20px] bg-white p-6 backdrop-blur-md">
        {!isSSO && <FormTitle>Enterprise AI for Technical Sales</FormTitle>}
        <div className="flex w-[370px] flex-col items-center space-y-4 p-5">
          {loginForm}
          {errorString && (
            <div className="text-center text-red-600">{errorString}</div>
          )}
        </div>
      </div>
      <div className="mt-4 flex w-[390px] flex-col items-center text-sm text-gray-600">
        <LegalSection actionText={actionText} />
        <span>
          Need help?{" "}
          <HelpButton className="text-primary font-medium hover:underline">
            Contact Us
          </HelpButton>
        </span>
      </div>
    </div>
  )
}

export default SignInPage

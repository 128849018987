import { LoadingOutlined } from "@ant-design/icons"
import { Button, Skeleton, Tooltip } from "antd"
import type { User as AuthUser } from "firebase/auth"
import {
  CloudUploadIcon,
  CopyIcon,
  PencilIcon,
  ThumbsUpIcon,
  TrashIcon,
  Undo2Icon,
} from "lucide-react"
import { useCallback, useEffect, useRef, useState } from "react"

import type { AnalyticsEventSurface } from "../../analytics/types"
import { EMPTY_ARRAY } from "../../constants"
import useAnswerFeedback from "../../hooks/useAnswerFeedback"
import { useChat } from "../../hooks/useChat"
import { type ChatMessageUIState } from "../../hooks/useChat/types"
import useErrorPopup from "../../hooks/useErrorPopup"
import BadAnswerButton from "../BadAnswerButton"
import AutoExpandingTextArea from "./../AutoExpandingTextArea"
import ConfidenceScore from "./../ConfidenceScore"
import { type Size, getSpaceClassName } from "./../chatShared"
import ChatMessageError from "./ChatMessageError"
import ChatReferenceList from "./ChatReferenceList"

interface Props {
  message: ChatMessageUIState
  user: AuthUser
  surface: AnalyticsEventSurface
  isLast?: boolean
  size?: Size
}

const ChatMessage: React.FC<Props> = ({ message, surface, size = "small" }) => {
  const {
    error,
    knowledge_item_oid: knowledgeItemOid,
    content: { answer, answererRequestId },
  } = message
  const { primary_answer: answerText = "" } = answer ?? {}
  const [internalAnswer, setInternalAnswer] = useState("")
  const { handleSuccess, handleError } = useErrorPopup()

  const [editing, setEditing] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const { selectedSessionId, updateMessage, deleteMessage } = useChat()
  const answerRef = useRef<HTMLTextAreaElement>(null)
  const { onGoodAnswer } = useAnswerFeedback(surface, answererRequestId)

  useEffect(() => {
    if (answerRef?.current) {
      if (editing) {
        answerRef?.current.focus()
      } else {
        answerRef?.current.blur()
      }
    }
  }, [editing])

  useEffect(() => {
    setInternalAnswer(answerText)
  }, [answerText])

  const onClickEdit = useCallback(() => {
    setEditing(true)
  }, [])

  const saveAnswer = useCallback(
    async (answerText: string) => {
      if (!answerText) {
        return
      }
      setSaving(true)
      try {
        const { createdKnowledgeItem } = await updateMessage(
          selectedSessionId,
          message.oid,
          answerText,
          surface,
        )
        handleSuccess(
          createdKnowledgeItem
            ? "Added to Answer Bank"
            : "Updated item in Answer Bank",
        )
        setEditing(false)
      } catch (error) {
        handleError({ error, prefix: "Can't save answer" })
      } finally {
        setSaving(false)
      }
    },
    [
      handleSuccess,
      handleError,
      selectedSessionId,
      message.oid,
      updateMessage,
      surface,
    ],
  )

  const onSave = useCallback(async () => {
    await saveAnswer(internalAnswer)
  }, [saveAnswer, internalAnswer])

  const onChangeInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInternalAnswer(e.target.value)
  }

  const onSubmitAnswer = useCallback(
    async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault()
        await saveAnswer(internalAnswer)
      }
    },
    [internalAnswer, saveAnswer],
  )

  const showBigSaveButton = editing
  const loading = message.answerLoading ?? false
  const deletingClasses = message.deleting ? "animate-pulse opacity-50" : ""

  const spaceClassName = getSpaceClassName(size)

  const leftColumn = (
    <div className="py-3 pr-6 sm:w-1/2">
      <div className="mt-2 text-xs font-bold text-gray-400">QUESTION</div>
      <div className={"flex items-start " + spaceClassName}>
        <div className="whitespace-pre-wrap text-3xl font-bold text-gray-800">
          {message.content.question.text}
        </div>
      </div>
      <div className="mt-7 text-xs font-bold text-gray-400">ANSWER</div>
      {error && <ChatMessageError error={error} size={size} />}
      {loading ? (
        <Skeleton active paragraph={{ rows: 2 }} />
      ) : answer ? (
        <>
          <AutoExpandingTextArea
            className="bg-purple-25 text-semibold mt-4 flex w-full resize-none rounded-md p-3 text-lg font-medium text-purple-800"
            forwardedRef={answerRef}
            value={internalAnswer}
            onFocus={() => setEditing(true)}
            onChange={onChangeInput}
            onKeyDown={onSubmitAnswer}
            disabled={loading || saving}
          />
          <div className="flex flex-wrap items-center justify-between">
            <div className="mt-4 flex justify-end gap-2 justify-self-end">
              <Tooltip placement="topRight" title="Copy to clipboard">
                <Button
                  icon={<CopyIcon />}
                  size="small"
                  disabled={
                    editing || loading || answer.primary_answer === undefined
                  }
                  onClick={() => {
                    void navigator.clipboard.writeText(internalAnswer || "")
                    void handleSuccess("Copied to clipboard")
                  }}
                />
              </Tooltip>
              <Tooltip placement="topRight" title="Good Answer">
                <Button
                  icon={<ThumbsUpIcon />}
                  size="small"
                  disabled={
                    editing || loading || answer.primary_answer === undefined
                  }
                  onClick={onGoodAnswer}
                />
              </Tooltip>
              <BadAnswerButton
                surface={surface}
                answererRequestId={answererRequestId}
                unableToAnswer={answer.confidence === 0}
                disabled={
                  editing || loading || answer.primary_answer === undefined
                }
              />
              <Tooltip placement="topRight" title="Delete chat message">
                <Button
                  icon={<TrashIcon />}
                  size="small"
                  disabled={
                    editing || loading || answer.primary_answer === undefined
                  }
                  onClick={() => deleteMessage(selectedSessionId, message)}
                />
              </Tooltip>
              <Tooltip placement="topRight" title="Cancel edit">
                <Button
                  icon={<Undo2Icon />}
                  size="small"
                  disabled={loading || saving}
                  className={editing ? "" : "hidden"}
                  onClick={() => {
                    setInternalAnswer(answerText)
                    setEditing(false)
                  }}
                >
                  Cancel
                </Button>
              </Tooltip>
              <Tooltip placement="topRight" title="Edit Answer">
                <Button
                  icon={<PencilIcon />}
                  size="small"
                  className={editing ? "hidden" : ""}
                  disabled={
                    editing || loading || answer?.primary_answer === undefined
                  }
                  onClick={onClickEdit}
                />
              </Tooltip>
              <Tooltip
                placement="topRight"
                title={
                  knowledgeItemOid
                    ? "Update Answer Bank Item"
                    : "Save answer to Answer Bank"
                }
              >
                <Button
                  icon={saving ? <LoadingOutlined /> : <CloudUploadIcon />}
                  type={showBigSaveButton ? "primary" : "default"}
                  size="small"
                  htmlType="submit"
                  disabled={loading || saving || !internalAnswer}
                  onClick={onSave}
                >
                  {!showBigSaveButton
                    ? ""
                    : knowledgeItemOid
                      ? "Update"
                      : "Save"}
                </Button>
              </Tooltip>
            </div>
            <div className="mr-2 mt-4 whitespace-nowrap">
              <ConfidenceScore score={answer.confidence} />
            </div>
          </div>
        </>
      ) : null}
    </div>
  )

  // We must always load eagerly because otherwise we'd need to load when they
  // scroll into view. The contents are always showing.
  const rightColumn = (
    <div className="flex flex-col border-l border-gray-100 p-3 sm:w-1/2 sm:pl-6">
      <div className="mb-2 text-xs font-bold text-gray-400">REFERENCES</div>
      <Skeleton active loading={loading} paragraph={{ rows: 4 }}>
        <ChatReferenceList
          references={answer?.references ?? EMPTY_ARRAY}
          size="small"
          loadEagerly
        />
      </Skeleton>
    </div>
  )

  return (
    <div
      className={
        "flex h-auto min-h-[100%] shrink-0 p-3 max-sm:flex-col " +
        deletingClasses
      }
    >
      {leftColumn}
      {rightColumn}
    </div>
  )
}

export default ChatMessage

import { useCallback } from "react"
import useDrivePicker from "react-google-drive-picker"

import DriveLogo from "../assets/img/drive_logo.svg"
import ZoomLogo from "../assets/img/zoom_logo.svg"
import { useActiveGroup } from "../contexts/useActiveGroup"
import {
  GONG_PROVIDER,
  GOOGLE_PROVIDER,
  ZOOM_PROVIDER,
} from "../credentials/providerTypes"
import useSources from "../hooks/useSources"
import useUserCredential from "../hooks/useUserCredential"
import CarbonIntegrationsBlock from "./CarbonIntegrationsBlock"
import GongIntegrationBlock from "./GongIntegrationBlock"
import IntegrationBlock from "./IntegrationBlock"
import SlackBotIntegrationBlock from "./SlackBotIntegrationBlock"
import SlackSourceIntegrationBlock from "./SlackSourceIntegrationBlock"

const IntegrationManager: React.FC = () => {
  const {
    error: userCredentialError,
    loading: userCredentialLoading,
    userCredentials,
    removeCredentialEntry,
    connectGoogleDrive,
    connectGong,
    connectZoom,
  } = useUserCredential()

  const [sources, sourcesLoading, sourcesError] = useSources()

  const { flags } = useActiveGroup()
  useDrivePicker()

  const credentialsByProvider = Object.fromEntries(
    userCredentials.map((credential) => [credential.provider, credential]),
  )

  const onDisconnectCredential = useCallback(
    async (provider: string) => {
      if (!credentialsByProvider[provider]) return
      await removeCredentialEntry(credentialsByProvider[provider])
    },
    [removeCredentialEntry, credentialsByProvider],
  )

  if (userCredentialError || sourcesError) {
    return (
      <div className="m-8">
        <h2>Error loading integrations</h2>
        {userCredentialError && <p>{userCredentialError.message}</p>}
        {sourcesError && <p>{sourcesError.message}</p>}
      </div>
    )
  }

  return (
    <div>
      <h2>Your Integrations</h2>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        <IntegrationBlock
          loading={userCredentialLoading}
          name="Google Drive"
          connected={!!credentialsByProvider[GOOGLE_PROVIDER]}
          onConnect={connectGoogleDrive}
          onDisconnect={() => onDisconnectCredential(GOOGLE_PROVIDER)}
          logo={DriveLogo}
        />
        <SlackBotIntegrationBlock integrationsLoading={userCredentialLoading} />
        {flags.showSlackSource && (
          <SlackSourceIntegrationBlock
            source={
              sources
                ? sources.find((source) => source.kind === "SLACK")
                : undefined
            }
            loading={sourcesLoading || userCredentialLoading}
          />
        )}
        {flags.showGong && (
          <GongIntegrationBlock
            source={
              sources
                ? sources.find((source) => source.kind === "GONG")
                : undefined
            }
            loading={sourcesLoading || userCredentialLoading}
            credential={credentialsByProvider[GONG_PROVIDER]}
            connectCredential={connectGong}
            disconnectCredential={() => onDisconnectCredential(GONG_PROVIDER)}
          />
        )}
        {flags.showZoom && (
          <IntegrationBlock
            loading={userCredentialLoading}
            name="Zoom"
            connected={!!credentialsByProvider[ZOOM_PROVIDER]}
            onConnect={connectZoom}
            onDisconnect={() => onDisconnectCredential(ZOOM_PROVIDER)}
            logo={ZoomLogo}
          />
        )}
        {flags.showCarbonOnedrive && (
          <CarbonIntegrationsBlock
            integrationName="ONEDRIVE"
            source={
              sources
                ? sources.find((source) => source.kind === "CARBON_ONEDRIVE")
                : undefined
            }
            sourcesLoading={sourcesLoading}
          />
        )}
        {(flags.showCarbonNotion || flags.showNotion) && (
          <CarbonIntegrationsBlock
            integrationName="NOTION"
            source={
              sources
                ? sources.find((source) => source.kind === "CARBON_NOTION")
                : undefined
            }
            sourcesLoading={sourcesLoading}
          />
        )}
        {(flags.showAtlassian || flags.showCarbonConfluence) && (
          <CarbonIntegrationsBlock
            integrationName="CONFLUENCE"
            source={
              sources
                ? sources.find((source) => source.kind === "CARBON_CONFLUENCE")
                : undefined
            }
            sourcesLoading={sourcesLoading}
          />
        )}
      </div>
    </div>
  )
}
export default IntegrationManager

import { Button, Dropdown, type MenuProps, Popover } from "antd"
import { MoreHorizontal } from "lucide-react"
import { useCallback, useState } from "react"

import type { Group } from "../../groups/types"
import type { GroupTag } from "../../tags/types"
import DeleteTagConfirmation from "./DeleteTagConfirmation"
import EditTagForm from "./EditTagForm"

interface EditTagDropdownProps {
  group: Group
  tag: GroupTag
}

const EditTagDropdown: React.FC<EditTagDropdownProps> = ({ group, tag }) => {
  const [deleteTagOpen, setDeleteTagOpen] = useState(false)
  const [editTagOpen, setEditTagOpen] = useState(false)

  const hide = useCallback(() => {
    setDeleteTagOpen(false)
    setEditTagOpen(false)
  }, [])

  const menu: MenuProps = {
    items: [
      {
        key: "edit",
        onClick: () => setEditTagOpen(true),
        label: "Edit Tag",
      },
      {
        key: "delete",
        onClick: () => setDeleteTagOpen(true),
        label: "Delete Tag",
      },
    ],
  }

  return (
    <Popover
      content={
        deleteTagOpen ? (
          <DeleteTagConfirmation group={group} tag={tag} onClose={hide} />
        ) : editTagOpen ? (
          <EditTagForm group={group} tag={tag} onClose={hide} />
        ) : null
      }
      trigger="click"
      placement="bottomRight"
      open={deleteTagOpen || editTagOpen}
      onOpenChange={(visible) => {
        if (!visible) {
          hide()
        }
      }}
    >
      <Dropdown menu={menu}>
        <Button
          icon={<MoreHorizontal className="text-gray-400" />}
          className="flex items-center justify-center"
          style={{ width: "32px" }}
          type="default"
          size="small"
          title="Delete Tag"
        />
      </Dropdown>
    </Popover>
  )
}

export default EditTagDropdown

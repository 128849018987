import { Button, Form, Select } from "antd"
import { useCallback, useEffect, useState } from "react"

import { useKeydown } from "../hooks/events"
import useErrorPopup from "../hooks/useErrorPopup"
import { getSlackChannelsApi } from "../slack/api"
import type { SlackChannel } from "../slack/apiTypes"
import { updateOrCreateSource } from "../sources/api"
import type { SlackSource } from "../sources/types"

interface FormDataType {
  channelIds: string[]
}

const getInitialValues = (source: SlackSource | undefined) => {
  if (!source) {
    return {
      channelIds: [],
    }
  }

  const channelIds = source.source_meta.channels.map((c) => c.channel_id)
  return {
    channelIds,
  }
}

const SlackPickerForm: React.FC<{
  source: SlackSource | undefined
  onClose: () => void
  channels: SlackChannel[]
  loading: boolean
  error: Error | null
}> = ({ source, onClose, channels, loading, error }) => {
  const [form] = Form.useForm<FormDataType>()
  const { handleSuccess, handleError } = useErrorPopup()
  const initialValues = getInitialValues(source)
  const [submitting, setSubmitting] = useState<boolean>(false)

  const onSubmitForm = useCallback(
    async (formData: FormDataType) => {
      try {
        setSubmitting(true)
        await updateOrCreateSource({
          kind: "SLACK",
          source_meta: {
            channels: formData.channelIds.map((channel_id) => ({
              channel_id,
              name: channels.find((c) => c.id === channel_id)!.name!,
              start_time: undefined,
            })),
          },
        })
        handleSuccess(
          "Successfully updated data source. Your data will be synced in the background",
        )
        onClose()
      } catch (error) {
        handleError({ error, message: "Could not update Slack Source" })
      } finally {
        setSubmitting(false)
      }
    },
    [handleSuccess, handleError, onClose, channels],
  )

  const channelOptions = channels.map(({ id, name }) => ({
    label: `#${name}`,
    value: id,
  }))

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmitForm}
      initialValues={initialValues}
      className="min-w-[500px]"
      disabled={loading || submitting}
    >
      {error ? (
        <div>Error loading data: {error.message}</div>
      ) : (
        <>
          <Form.Item
            label="Channels"
            name="channelIds"
            tooltip="Threads from these channels will be synced as data sources for question answering"
            rules={[
              { required: true, message: "Please select at least one channel" },
            ]}
          >
            <Select<string[]>
              mode="multiple"
              placeholder="Select channels"
              options={channelOptions}
              optionFilterProp="label"
              loading={loading}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Confirm
            </Button>
          </Form.Item>
        </>
      )}
    </Form>
  )
}

const SlackPicker: React.FC<{
  source: SlackSource | undefined
  onClose: () => void
}> = ({ source, onClose }) => {
  const [channels, setChannels] = useState<SlackChannel[]>([])
  const [error, setError] = useState<Error | null>(null)
  const [dataLoading, setDataLoading] = useState(true)

  useKeydown(
    27, // Escape
    onClose,
  )

  useEffect(() => {
    void (async () => {
      setDataLoading(true)
      try {
        const { data: channels } = await getSlackChannelsApi()
        setChannels(channels)
      } catch (error) {
        console.error("Error fetching data", error)
        if (error instanceof Error) {
          setError(error)
        }
      } finally {
        setDataLoading(false)
      }
    })()
  }, [])

  return (
    <SlackPickerForm
      source={source}
      onClose={onClose}
      channels={channels}
      error={error}
      loading={dataLoading}
    />
  )
}

export default SlackPicker

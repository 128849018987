import { Button, Tooltip, Typography } from "antd"
import { createElement, useCallback } from "react"

import { withHeader } from "../components/Header"
import LoadingSpinner from "../components/LoadingSpinner"
import { QUILT_EMAIL_ADDRESS } from "../constants"
import { useActiveGroup } from "../contexts/useActiveGroup"
import { useActiveSubscription } from "../hooks/useActiveSubscription"
import useErrorPopup from "../hooks/useErrorPopup"
import { createCheckoutSession } from "./api"
import {
  BASE_PLAN_DETAILS,
  ENTERPRISE_PLAN_DETAILS,
  type PlanDetails,
  type QuiltPlanId,
  TEAM_PLAN_DETAILS,
  getPlanOverviewText,
  getStripePriceIdForPlan,
} from "./planDetails"

const plans: PlanDetails[] = [
  BASE_PLAN_DETAILS,
  TEAM_PLAN_DETAILS,
  ENTERPRISE_PLAN_DETAILS,
]

interface FeaturesComponentProps {
  features: string[]
}

const FeaturesComponent: React.FC<FeaturesComponentProps> = ({ features }) => {
  return (
    <div className="flex basis-[55%] flex-col justify-start bg-white p-2">
      <Typography.Title level={5} className="mb-2 mt-2 text-center">
        FEATURES
      </Typography.Title>
      <div className="space-y-2">
        {features.map((feature, idx) => {
          return (
            <div
              key={`${feature.slice(0, 10)}-${idx}`}
              className="rounded-lg bg-purple-50 p-2 text-center text-purple-700"
            >
              {feature}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const PlansPage: React.FC = () => {
  const { handleError } = useErrorPopup()
  const { activeGroupOid, activeGroup, activeGroupLoading } = useActiveGroup()

  const [planAndStatus, , subscriptionsLoading, subscriptionsError] =
    useActiveSubscription(activeGroupOid)
  const hasActivePlan = planAndStatus.status === "active"

  const handleClickPurchase = useCallback(
    async (quiltPlanId: QuiltPlanId) => {
      const stripeCustomerId = activeGroup?.stripe_customer_id
      if (!stripeCustomerId) {
        handleError({ message: "Missing a Stripe customer account" })
        return
      }
      try {
        const stripePriceId = getStripePriceIdForPlan(quiltPlanId)

        const { data } = await createCheckoutSession({
          priceId: stripePriceId,
          mode: "subscription",
          stripeCustomerId,
          groupOid: activeGroupOid,
        })
        window.open(data.url, "_blank", "noopener, noreferrer")?.focus()
      } catch (e) {
        handleError({ error: e, prefix: "Unable to purchase a plan" })
      }
    },
    [activeGroup?.stripe_customer_id, activeGroupOid, handleError],
  )

  if (subscriptionsLoading || activeGroupLoading) {
    return <LoadingSpinner />
  }

  if (subscriptionsError) {
    handleError({
      error: subscriptionsError,
      prefix: "Unable to fetch Quilt plan",
    })
  }

  return (
    <div className="container mx-auto max-h-[870px] max-w-[1000px] px-4 py-8">
      <div className="flex flex-col justify-center">
        <Typography.Text className="p-4">
          {getPlanOverviewText(planAndStatus)}
          If you would like to {hasActivePlan ? "change your plan or" : ""}{" "}
          speak with our sales team{" "}
          <a href={`mailto:${QUILT_EMAIL_ADDRESS}`} target="_blank">
            contact us
          </a>
        </Typography.Text>
        <div className="flex flex-row flex-wrap justify-center gap-4">
          {plans.map((plan) => (
            <div
              key={plan.id}
              className={
                "card flex max-w-[300px] flex-col justify-between rounded-lg border bg-white p-4 shadow-md"
              }
            >
              <div className="flex-start flex basis-[85%] flex-col">
                <div className="flex h-auto flex-col">
                  <div className="flex flex-col justify-between">
                    <div className="icon block flex justify-center p-2 text-center text-3xl">
                      {createElement(plan.icon)}
                    </div>
                    <h2 className="mb-2 text-center text-xl font-bold">
                      {plan.name}
                    </h2>
                    <p className="mb-4 text-center text-gray-600">
                      {plan.description}
                    </p>
                  </div>
                  <div className="mt-auto flex flex-col">
                    {plan.id === "ENTERPRISE" ? (
                      <Button
                        size="large"
                        className="mt-auto bg-purple-500 text-white"
                        href={`mailto:${QUILT_EMAIL_ADDRESS}`}
                        target="_blank"
                      >
                        Talk to Sales
                      </Button>
                    ) : (
                      <Tooltip
                        title={
                          hasActivePlan
                            ? `Email us at ${QUILT_EMAIL_ADDRESS} to change your plan`
                            : ""
                        }
                      >
                        <Button
                          size="large"
                          className={`${hasActivePlan ? "bg-gray-400" : "bg-purple-500"} text-white`}
                          disabled={hasActivePlan}
                          onClick={() => handleClickPurchase(plan.id)}
                        >
                          Purchase
                        </Button>
                      </Tooltip>
                    )}
                  </div>
                </div>
                <FeaturesComponent features={plan.features} />
              </div>
              <div className="mt-auto flex basis-[10%] flex-col">
                <div className="flex min-h-32 flex-col justify-start text-center">
                  <p className="font-bold uppercase text-gray-500">Support</p>
                  <Typography.Text strong>{plan.support}</Typography.Text>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const breadCrumbs = [
  {
    title: "Group Settings",
    href: "/group/settings",
  },
  {
    title: "Plans",
    href: "/group/settings/billing/plans",
  },
]
export default withHeader({
  title: "Billing Plans",
  breadcrumbs: breadCrumbs,
})(PlansPage)

import type { CellObject, WorkBook } from "xlsx"
import { utils } from "xlsx"

import type { TagsFilterPredicate } from "../../search/types"
import type { QuestionAnswerLayoutForAnswering } from "../../types/sheets"
import type {
  DocumentFields,
  DocumentSheet,
  FileDocument,
  GoogleDriveDocument,
  WorkbookData,
} from "./state"

const removeEmptyRowsFromEnd = (rows: string[][]): string[][] => {
  const minRows = 5

  if (rows.length === 0) {
    // Make sure to return at least one cell so it doesn't look horrible.
    // TODO(mgraczyk): Add minimum column and row count.
    return [[""]]
  }

  for (let i = rows.length - 1; i >= minRows; i--) {
    if (rows[i].some((cell) => cell.trim() !== "")) {
      return rows.slice(0, i + 1)
    }
  }

  return rows.slice(0, Math.min(minRows, rows.length))
}

// Returns a map of sheet names to rows of cells.
// The rows are trimmed of empty rows at the end.
//
// If extraEmptyCols is provided, it will add that many empty columns to the end.
export const getDataFromWorkbook = (
  workbook: WorkBook,
  extraEmptyCols: number = 0,
): WorkbookData => {
  const sheetRows: Record<string, string[][]> = {}

  const sheetNames = workbook.SheetNames.filter(
    (s) => !workbook.Workbook?.Sheets?.find((ws) => ws.name === s)?.Hidden,
  )
  for (const sheetName of sheetNames) {
    const worksheet = workbook.Sheets[sheetName]
    const range = utils.decode_range(worksheet["!ref"] ?? "")
    const thisSheetRows = []

    // Add extra empty columns so that the user
    // can add a column for the answer at the end.
    const maxCol = Math.min(range.e.c + extraEmptyCols, 40)

    for (
      let rowNum = range.s.r;
      rowNum <= range.e.r && rowNum < range.s.r + 200;
      rowNum++
    ) {
      const row = []
      for (let colNum = 0; colNum <= maxCol; colNum++) {
        const cellAddress = { c: colNum, r: rowNum }
        const cellRef = utils.encode_cell(cellAddress)

        // Try to use formatted text when available, fall back to raw if it's missing.
        const cell = worksheet[cellRef] as CellObject | undefined
        row.push(cell ? String(cell.w ?? cell.v) : "")
      }
      thisSheetRows.push(row)
    }

    sheetRows[sheetName] = removeEmptyRowsFromEnd(thisSheetRows)
  }

  return { sheetRows, sheetNames }
}

export const fileReaderAsync = (
  blob: Blob,
): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result)
    }

    reader.onerror = reject

    reader.readAsArrayBuffer(blob)
  })
}

export const getXlsxSheetNameFromGoogleWorksheet = (googleWorksheet: {
  properties: { title: string }
}): string => {
  // XLSX is more restrictive than sheets in sheet names.
  // https://support.microsoft.com/en-us/office/rename-a-worksheet-3f1f7148-ee83-404d-8ef0-9ff99fbad1f9
  const invalidCharsRegex = /:|\/|\\|\?|\*|\]|\[/g
  const maxLength = 31
  const title = googleWorksheet.properties.title
    .replace(invalidCharsRegex, "")
    .slice(0, maxLength)
  if (title === "History") {
    return "History (Sheet)"
  }
  // TODO(mgraczyk): Fix Begin or end with an apostrophe (')
  return title
}

// If the selection is valid, returns a partial layout.
// If not valid, returns null.
const convertUiAnswerFieldsToLayoutIndices = (
  fields: DocumentFields,
): Pick<
  QuestionAnswerLayoutForAnswering,
  | "answer_kind"
  | "primary_question_index"
  | "secondary_question_index"
  | "primary_answer_index"
  | "secondary_answer_index"
> | null => {
  if (fields.primaryQuestion.index === undefined) {
    // All layouts require a primary question.
    return null
  }

  if (
    fields.answerExplanation.index !== undefined &&
    fields.answerCategorical.index !== undefined
  ) {
    return {
      answer_kind: "CATEGORICAL_WITH_EXPLANATION",
      primary_question_index: fields.primaryQuestion.index,
      secondary_question_index: fields.secondaryQuestion.index,
      primary_answer_index: fields.answerCategorical.index,
      secondary_answer_index: fields.answerExplanation.index,
    }
  }

  if (fields.answerCategorical.index !== undefined) {
    return {
      answer_kind: "CATEGORICAL",
      primary_question_index: fields.primaryQuestion.index,
      secondary_question_index: fields.secondaryQuestion.index,
      primary_answer_index: fields.answerCategorical.index,
      secondary_answer_index: null,
    }
  }

  if (fields.answerExplanation.index !== undefined) {
    return {
      answer_kind: "SINGLE_TEXT",
      primary_question_index: fields.primaryQuestion.index,
      secondary_question_index: fields.secondaryQuestion.index,
      primary_answer_index: fields.answerExplanation.index,
      secondary_answer_index: null,
    }
  }

  return null
}

export const isValidSheet = (sheet: DocumentSheet): boolean => {
  return (
    // Whether or not it is verbatim from the answer bank does not affect
    // validity.
    convertUiAnswerFieldsToLayoutIndices(sheet.fields) != null
  )
}

const generateTagsFilterPredicate = (
  doc: FileDocument | GoogleDriveDocument,
): TagsFilterPredicate | null => {
  const { selectedTags, tagOperator } = doc
  if (!tagOperator) return null
  if (!selectedTags || selectedTags.length === 0) return null

  if (selectedTags.length === 1) {
    return { kind: "UNARY", value: selectedTags[0] }
  }

  return selectedTags.reduce((acc, tag, index) => {
    if (index === 0) return { kind: "UNARY", value: tag }
    return {
      kind: "BINARY",
      op: tagOperator,
      left: acc,
      right: { kind: "UNARY", value: tag },
    }
  }, {} as TagsFilterPredicate)
}

export const getQuestionAnswerLayouts = (
  doc: FileDocument | GoogleDriveDocument,
): Record<string, QuestionAnswerLayoutForAnswering> => {
  const { sheets } = doc

  const questionAnswerLayouts: Record<
    string,
    QuestionAnswerLayoutForAnswering
  > = {}
  for (const { sheetName, fields, isRow } of sheets) {
    // sheetName is guaranteed to be defined above
    // This is just to make typescript happy
    // completed is actualy what we are checking
    if (sheetName === undefined) {
      continue
    }

    const maybeLayout = convertUiAnswerFieldsToLayoutIndices(fields)
    if (maybeLayout === null) {
      continue
    }
    const tags_filter = generateTagsFilterPredicate(doc)
    const response_locale = doc.locale
    questionAnswerLayouts[sheetName] = {
      is_row: isRow,
      ...maybeLayout,
      question_id_index: fields.questionId.index,
      header_index: null,
      require_verbatim_kind: doc.verbatimFromAnswerBank ? "ANSWER_BANK" : null,
      answerer_controls:
        tags_filter === null && response_locale === "en_US"
          ? null
          : { tags_filter, response_locale },
    }
  }

  if (Object.keys(questionAnswerLayouts).length === 0) {
    throw new Error("No valid sheets")
  }

  return questionAnswerLayouts
}

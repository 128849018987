import { Button, Empty, Skeleton } from "antd"
import type { User as AuthUser } from "firebase/auth"
import { collection, limit, orderBy, query } from "firebase/firestore"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useNavigate } from "react-router-dom"

import Header from "../components/Header"
import { makeConverter } from "../dbUtils"
import { db } from "../firebaseApp"
import NotificationItem from "../notifications/NotificationItem"
import type { Notification } from "../notifications/types"

interface Props {
  user: AuthUser
}

const NotificationsPage: React.FC<Props> = ({ user }) => {
  const navigate = useNavigate()
  const [notifications, notificationLoading] = useCollectionData(
    query(
      collection(db, "users", user.uid, "notifications"),
      orderBy("created_at", "desc"),
      limit(500),
    ).withConverter(makeConverter<Notification>()),
  )

  const noNotifications = (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description="You don't have any notifications yet"
    />
  )

  return (
    <>
      <Header title="Notifications">
        <Button
          onClick={() => navigate("/profile/notification-preferences")}
          type="primary"
        >
          Preferences
        </Button>
      </Header>
      <div className="w-full">
        <div className="max-w-[600px] space-y-2 p-4">
          <Skeleton
            className="rounded-lg p-4 shadow"
            active
            avatar
            loading={notificationLoading}
            paragraph={{ rows: 1 }}
          >
            {!notifications || notifications.length === 0
              ? noNotifications
              : notifications.map((notification) => (
                  <NotificationItem
                    key={notification.oid}
                    notification={notification}
                    user={user}
                  />
                ))}
          </Skeleton>
          <Skeleton
            className="rounded-lg p-4 shadow"
            active
            avatar
            loading={notificationLoading}
            paragraph={{ rows: 1 }}
          />
        </div>
      </div>
    </>
  )
}

export default NotificationsPage

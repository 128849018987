import { Button, List } from "antd"
import Checkbox from "antd/es/checkbox/Checkbox"
import { useState } from "react"

import { updateUserGroupRole } from "../../groups/api"
import type { Group, GroupMembership, GroupRole } from "../../groups/types"
import { useKeydown } from "../../hooks/events"
import useErrorPopup from "../../hooks/useErrorPopup"
import { ROLE_INFOS } from "./constants"

const roleData = [
  ROLE_INFOS.ADMIN,
  ROLE_INFOS.MEMBER,
  ROLE_INFOS.VIEWER,
  ROLE_INFOS.CONTENT_MANAGER,
]

interface UpdateMemberConfirmationProps {
  group: Group
  membership: GroupMembership
  onClose: () => void
}

const UpdateMemberConfirmation: React.FC<UpdateMemberConfirmationProps> = ({
  group,
  onClose,
  membership,
}) => {
  const [isSubmitting, setSubmitting] = useState<boolean>(false)
  const [selectedRole, setSelectedRole] = useState<GroupRole>(membership.role)

  useKeydown(27 /*escape*/, onClose)

  const { handleSuccess, handleError } = useErrorPopup()

  const onSubmit = async () => {
    if (!selectedRole) {
      return
    }
    setSubmitting(true)
    try {
      await updateUserGroupRole({
        uid: membership.uid,
        group_oid: group.oid,
        role: selectedRole,
      })
      handleSuccess("Updated group member!")
    } catch (error) {
      handleError({ error, prefix: "Couldn't update member role" })
    } finally {
      setSubmitting(false)
      onClose()
    }
  }

  return (
    <div className="max-w-[500px]">
      <h4>Edit Member Role</h4>
      <p>{`Select a new role for ${membership.email}`}</p>
      <List
        dataSource={roleData}
        renderItem={({ name, role, description }) => (
          <List.Item onClick={() => setSelectedRole(role)}>
            <Checkbox checked={selectedRole === role} className="pr-4" />
            <List.Item.Meta title={name} description={description} />
          </List.Item>
        )}
      />
      <Button
        type="primary"
        htmlType="submit"
        loading={isSubmitting}
        disabled={isSubmitting}
        onClick={onSubmit}
      >
        {isSubmitting ? "Updating..." : "Confirm"}
      </Button>
    </div>
  )
}
export default UpdateMemberConfirmation

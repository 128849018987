import { ArrowDownOutlined, InfoCircleOutlined } from "@ant-design/icons"
import { Empty, Skeleton, Tooltip } from "antd"
import { FloatButton } from "antd"
import { useCallback, useEffect, useState } from "react"

import emptyTranscriptImage from "../assets/img/empty_icons/source_document.svg"
import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import type { LiveTranscriptSegment } from "./types"

interface Props {
  segments: LiveTranscriptSegment[]
  loading?: boolean
  autoScroll?: boolean
}

interface Message {
  segmentId: string
  displayText: string
  startTime: Date
  endTime: Date
}

interface MessageGroup {
  key: string
  speakerId: string
  createdAt: Date
  messages: Message[]
}

/*
  Group consecutive messages from the same speaker into a single message group.
 */
const _groupMessages = (segments: LiveTranscriptSegment[]): MessageGroup[] => {
  const messageGroups: MessageGroup[] = []
  for (const {
    speaker_id,
    created_at,
    oid,
    text,
    start_time,
    end_time,
  } of segments) {
    const createdAt = created_at.toDate()
    const message: Message = {
      segmentId: oid,
      displayText: text,
      startTime: new Date(start_time * 1000),
      endTime: new Date(end_time * 1000),
    }
    const lastGroup = messageGroups[messageGroups.length - 1]
    if (lastGroup && lastGroup.speakerId === speaker_id) {
      lastGroup.messages.push(message)
    } else {
      messageGroups.push({
        key: oid, // key of group is the first message's oid
        speakerId: speaker_id,
        messages: [message],
        createdAt,
      })
    }
  }
  return messageGroups
}

const TooltipContent: React.FC<{ message: Message }> = ({ message }) => (
  <div>
    <div>Start time: {message.startTime.toLocaleTimeString()}</div>
    <div>End time: {message.endTime.toLocaleTimeString()}</div>
  </div>
)

const TranscriptMessageGroup: React.FC<MessageGroup> = ({
  createdAt,
  messages,
  speakerId,
}) => {
  const { authUser } = useActiveUserAuthorizationFromContext()

  if (messages.length === 0) {
    return null
  }
  const isUser = speakerId === "user"

  const displayName = isUser ? authUser?.displayName || "You" : "Guests"
  const displayDate = createdAt.toLocaleTimeString()

  const userClassName = isUser ? "border-blue-300" : "border-red-300"
  const hoverClassName = isUser ? "hover:bg-blue-25" : "hover:bg-red-25"

  return (
    <div className="flex min-w-full space-x-2 py-2 pt-2">
      <div className="w-32 text-right">
        <div className="sticky top-0">
          <div className="font-bold">{displayName}</div>
          <div className="text-xs">{displayDate}</div>
        </div>
      </div>
      <div className={`w-full border-l-2 pl-2 ${userClassName}`}>
        {messages.map((message) => (
          <div
            key={message.segmentId}
            className={`${hoverClassName} group flex items-end p-1`}
          >
            <div className="grow">{message.displayText}</div>
            <div className="invisible group-hover:visible">
              <Tooltip
                title={<TooltipContent message={message} />}
                placement="left"
              >
                <InfoCircleOutlined className="text-gray-300 hover:text-gray-600" />
              </Tooltip>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const TranscriptFeed: React.FC<Props> = ({
  segments,
  loading = false,
  autoScroll = true,
}) => {
  const [isUserScrolling, setIsUserScrolling] = useState(false)

  const groupedMessages = _groupMessages(segments)
  const containerId = "transcript-container"
  const floatingButtonClassName = `mr-5 transition-opacity duration-500 ease-in-out ${isUserScrolling ? "opacity-100" : "opacity-0 cursor-default"}`

  const scrollToBottom = () => {
    const container = document.getElementById(containerId)
    if (container) {
      container.scroll({
        top: container.scrollHeight,
        behavior: "smooth",
      })
    }
  }

  const onClickScrollToBottom = useCallback(() => {
    setIsUserScrolling(false)
    scrollToBottom()
  }, [])

  // Auto scroll
  useEffect(() => {
    const container = document.getElementById(containerId)
    if (autoScroll && container && !isUserScrolling) {
      scrollToBottom()
    }
  }, [autoScroll, groupedMessages, isUserScrolling])

  // Detect manual scrolling
  const onScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      if (loading) {
        return
      }
      const container = event.currentTarget
      // Add 0.5 pixels to account for border?
      // TODO(mgraczyk): Figure out why this is necessary.
      setIsUserScrolling(
        container.scrollTop + container.clientHeight + 0.5 <
          container.scrollHeight,
      )
    },
    [loading],
  )

  // TODO(mgraczyk): Adding grow on the inner div looks better but causes the
  // items to start at the bottom when the transcript container is not full.
  return (
    <>
      <h3 className="my-2 flex h-8 flex-row items-center">Transcript</h3>
      <div
        id={containerId}
        className="flex flex-col items-center overflow-y-scroll rounded-lg border p-3"
        onScroll={onScroll}
      >
        <Skeleton loading={loading} avatar active>
          <FloatButton
            icon={<ArrowDownOutlined />}
            onClick={onClickScrollToBottom}
            className={floatingButtonClassName}
          />
          {groupedMessages.length === 0 ? (
            <Empty
              image={emptyTranscriptImage}
              description="No speech detected."
              className="m-auto mt-32 flex flex-col items-center"
            />
          ) : (
            groupedMessages.map((group) => (
              <TranscriptMessageGroup
                key={group.key}
                speakerId={group.speakerId}
                createdAt={group.createdAt}
                messages={group.messages}
              />
            ))
          )}
        </Skeleton>
      </div>
    </>
  )
}

export default TranscriptFeed

import { useContext } from "react"

import { WizardContext } from "./context.js"

const SheetProgress: React.FC = () => {
  const { state, dispatch } = useContext(WizardContext)
  const { documentIndex } = state
  const document = state.documents[documentIndex]
  const { sheetIndex } = document

  return (
    <div>
      <div
        role="tablist"
        aria-label="Sheet Tabs"
        className="bg-gray-25 flex items-stretch justify-start overflow-x-auto border border-t border-gray-100"
      >
        <div className="text-grey-500 bg-gray-25 w-12"></div>
        {document.sheets.map((sheet, idx) => (
          <div
            key={idx}
            role="tab"
            tabIndex={idx === sheetIndex ? 0 : -1}
            aria-selected={idx === sheetIndex ? "true" : "false"}
            className={`flex cursor-pointer items-center justify-between gap-2 px-4 py-2 ${
              idx === sheetIndex
                ? "border-b-2 border-b-gray-800 bg-white pb-[6px] font-bold text-gray-800 "
                : "text-gray-600 "
            }${idx ? "border-r" : "border-x"}`}
            onClick={() => {
              dispatch({
                type: "SHEET_INDEX",
                documentIndex,
                payload: idx,
              })
            }}
          >
            <span>{sheet.sheetName}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SheetProgress

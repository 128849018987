import type { CustomTooltipProps } from "@ag-grid-community/react"
import { Button } from "antd"
import { ListIcon } from "lucide-react"

import type { GridContext, RowData } from "./types"

interface Props extends CustomTooltipProps<RowData, never, GridContext> {}

const Tooltip: React.FC<Props> = ({ context, hideTooltipCallback }) => {
  const onClick = () => {
    context.openSidebar()
    if (hideTooltipCallback) hideTooltipCallback()
  }
  return (
    <div
      className={`flex w-[130px] justify-around rounded-md border bg-white px-1 py-0.5 shadow-md`}
    >
      <Button
        className="flex w-full items-center text-sm font-semibold text-purple-500"
        icon={<ListIcon className="w-4" />}
        type="text"
        onClick={onClick}
      >
        Open Sidebar
      </Button>
    </div>
  )
}

export default Tooltip

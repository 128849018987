import { Skeleton } from "antd"
import type { User as AuthUser } from "firebase/auth"
import { useEffect, useRef } from "react"

import ChatMessage from "../../components/Chatv2/ChatMessage"
import { useChat } from "../../hooks/useChat"
import ChatCTA from "./ChatCTA"

interface ChatSessionComponentProps {
  user: AuthUser
  onSubmitCTAQuestion: (question: string) => Promise<void>
}

const ChatSessionComponent: React.FC<ChatSessionComponentProps> = ({
  user,
  onSubmitCTAQuestion,
}) => {
  const { messages, messagesLoading } = useChat()
  const scrollRef = useRef<HTMLDivElement>(null)
  const lastMessage =
    messages.length === 0 ? null : messages[messages.length - 1]

  useEffect(() => {
    const children = scrollRef.current?.children
    if (!children || children.length === 0) {
      return
    }

    children[children.length - 1].scrollIntoView({
      block: "start",
      // TODO(mgraczyk): Smooth scrolling is broken on initial page load with
      // many chat messages. Fix it before enabling.
    })
  }, [lastMessage])

  if (messagesLoading) {
    return (
      <div className="w-full grow p-4" ref={scrollRef}>
        <Skeleton active loading />
        <br />
        <Skeleton active loading />
      </div>
    )
  }

  if (messages.length === 0) {
    return <ChatCTA onClick={onSubmitCTAQuestion} />
  }

  return (
    <div
      className="flex w-full grow flex-col gap-4 overflow-y-auto p-4"
      ref={scrollRef}
    >
      {messages.map((message, i) => (
        <ChatMessage
          key={message.oid}
          message={message}
          user={user}
          isLast={i === messages.length - 1}
          surface="WEB_CHAT"
          size="large"
        />
      ))}
    </div>
  )
}

export default ChatSessionComponent

import { Button } from "antd"
import { useState } from "react"

import { removeGroupMember } from "../../groups/api"
import type { Group, GroupMembership } from "../../groups/types"
import { useKeydown } from "../../hooks/events"
import useErrorPopup from "../../hooks/useErrorPopup"

interface DeleteMemberConfirmationProps {
  group: Group
  membership: GroupMembership
  onClose: () => void
}

const DeleteMemberConfirmation: React.FC<DeleteMemberConfirmationProps> = ({
  group,
  membership,
  onClose,
}) => {
  const [isSubmitting, setSubmitting] = useState<boolean>(false)
  useKeydown(27 /*escape*/, onClose)

  const { handleSuccess, handleError } = useErrorPopup()

  const onSubmit = async () => {
    setSubmitting(true)
    try {
      await removeGroupMember({
        uid: membership.uid,
        group_oid: group.oid,
      })
      handleSuccess("Deleted group member!")
    } catch (error) {
      handleError({ error, prefix: "Couldn't delete group member" })
    } finally {
      setSubmitting(false)
      onClose()
    }
  }

  return (
    <div className="max-w-[500px]">
      <h4>Delete Member</h4>
      {
        <p>
          {"Member "}
          {<strong>{membership.email}</strong>}
          {` will be deleted from ${group.name}. Click below to confirm.`}
        </p>
      }
      <Button
        type="primary"
        htmlType="submit"
        loading={isSubmitting}
        disabled={isSubmitting}
        onClick={onSubmit}
      >
        {isSubmitting ? "Removing..." : "Confirm"}
      </Button>
    </div>
  )
}

export default DeleteMemberConfirmation

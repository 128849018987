import { httpsCallable } from "../serverUtils"
import type {
  SlackChannel,
  SlackInstallationMeta,
  SlackTeamUserAssociationRequest,
} from "./apiTypes"

export const associateSlackIdWithUserUid = httpsCallable<
  SlackTeamUserAssociationRequest,
  void
>("associateSlackIdWithUserUid")

export const getSlackInstallationApi = httpsCallable<
  { groupOid: string },
  SlackInstallationMeta | undefined
>("getSlackInstallation")

export const getSlackChannelsApi = httpsCallable<void, SlackChannel[]>(
  "getSlackChannels",
)

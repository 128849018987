import {
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  Select,
  Skeleton,
  Tabs,
  type TabsProps,
  Tooltip,
} from "antd"
import { collection, limit, orderBy, query, where } from "firebase/firestore"
import {
  CalendarIcon,
  CheckIcon,
  CircleUserRoundIcon,
  SparklesIcon,
  XIcon,
} from "lucide-react"
import { type PropsWithChildren, useCallback, useMemo, useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { Link, useParams } from "react-router-dom"

import { sendAnalyticsEvent } from "../analytics"
import { getAnswer } from "../api"
import AssignKnowledgeItemButton from "../components/AssignKnowledgeItemForm"
import { Discussions } from "../components/Discussions/Discussions"
import LoadingSpinner from "../components/LoadingSpinner"
import { ANALYTICS_PRODUCT } from "../constants"
import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../dbUtils"
import {
  DISCUSSION_COLLECTION,
  type KnowledgeDiscussion,
} from "../discussions/types"
import { db } from "../firebaseApp"
import useErrorPopup from "../hooks/useErrorPopup"
import { useGroupMembers } from "../hooks/useGroupMembers"
import { useGroupTags } from "../hooks/useGroupTags"
import { editKnowledgeItemsApi, reviewKnowledgeItems } from "../knowledge/api"
import { TagListItem } from "../tags/Tag"
import "../types/common"
import { GROUPS_COLLECTION } from "../types/common"
import { toHumanReadableString } from "../utils"
import KnowledgeItemHistoryCard from "./KnowledgeItemHistoryCard"
import { LastUpdatedBy } from "./LastUpdatedBy"
import type { KnowledgeItem, KnowledgeItemHistory } from "./types"

interface FormDataType {
  primary_question: string
  secondary_question: string
  primary_answer: string
  secondary_answer: string
  question_context?: string
  tags: string[]
}

const getDataForSubmission = (formData: FormDataType) => ({
  question: {
    primary: formData.primary_question,
    secondary: formData.secondary_question || undefined,
    context: formData.question_context || undefined,
  },
  answer: {
    primary: formData.primary_answer,
    secondary: formData.secondary_answer || undefined,
  },
  tags: formData.tags
    ? Object.fromEntries(formData.tags.map((tag) => [tag, null]))
    : undefined,
})

const DiscussionsLoading = () => (
  <Skeleton
    active
    className="w-36"
    title={false}
    paragraph={{ className: "m-0", rows: 1 }}
  />
)

const FormTextItemStyle: React.FC<PropsWithChildren> = ({ children }) => (
  <div className="flex flex-wrap items-center gap-1 overflow-y-auto text-[14px] leading-5 text-gray-600">
    {children}
  </div>
)

interface Props {
  item: KnowledgeItem
  history?: KnowledgeItemHistory[]
  removing: boolean
  sidebarOpen: boolean
  setSidebarOpen: (open: boolean) => void
}

const KnowledgeItemForm: React.FC<Props> = ({
  item,
  history,
  removing,
  sidebarOpen,
  setSidebarOpen,
}) => {
  const [form] = Form.useForm<FormDataType>()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [reviewing, setReviewing] = useState<boolean>(false)
  const [unreviewing, setUnreviewing] = useState<boolean>(false)
  const [autoAnswering, setAutoAnswering] = useState<boolean>(false)
  const { handleSuccess, handleError } = useErrorPopup()
  const { hasPerm, activeGroupOid } = useActiveUserAuthorizationFromContext()
  const primaryQuestion = Form.useWatch("primary_question", form)

  const [groupMembers, groupMembersLoading] = useGroupMembers(activeGroupOid)
  const [tags, tagsLoading] = useGroupTags(activeGroupOid)
  const { docOid } = useParams<{ docOid: string }>()

  const discussionsColRef = collection(
    db,
    GROUPS_COLLECTION,
    activeGroupOid,
    DISCUSSION_COLLECTION,
  ).withConverter(makeConverter<KnowledgeDiscussion>())

  const discussionsQuery = query(
    discussionsColRef,
    where("user_document_oid", "==", docOid),
    where("deleted", "==", false),
    orderBy("created_at", "desc"),
    limit(100),
  )
  const [discussions, discussionsLoading, discussionError] =
    useCollectionData<KnowledgeDiscussion>(discussionsQuery)

  const autoAnswer = useCallback(async () => {
    setAutoAnswering(true)
    try {
      const response = await getAnswer({
        question: {
          text: form.getFieldValue("primary_question") as string,
          details: (form.getFieldValue("secondary_question") as string) || null,
          location: null,
          details_location: null,
        },
        analyticsProduct: ANALYTICS_PRODUCT,
        analyticsSurface: "WEB_KNOWLEDGE_ITEM_PAGE",
      })
      void sendAnalyticsEvent({
        surface: "WEB_KNOWLEDGE_REPOSITORY",
        event_type: "AUTOGENERATE_ANSWER",
        event_data: {
          entity_type: "KNOWLEDGE_ITEM",
          entity_id: item.oid,
          answerer_request_id: response.request.request_id,
        },
      })

      form.setFieldValue("primary_answer", response.answer.primary_answer)

      // Set the form values.
      handleSuccess("Automatically generated answer")
    } catch (error) {
      handleError({ error, prefix: "Couldn't generate answer" })
    } finally {
      setAutoAnswering(false)
    }
  }, [item.oid, form, handleSuccess, handleError])

  const onChangeReview = useCallback(
    async (
      kind: "REVIEW" | "UNREVIEW",
      setStatus: (status: boolean) => void,
    ) => {
      setStatus(true)
      try {
        await reviewKnowledgeItems({
          requests: [
            {
              oid: item.oid,
              kind,
            },
          ],
        })
        handleSuccess(
          kind === "REVIEW" ? "Answer reviewed!" : "Answer unreviewed!",
        )
      } catch (error) {
        handleError({ error, prefix: "Couldn't change review status" })
      } finally {
        setStatus(false)
      }
    },
    [handleSuccess, handleError, item.oid],
  )

  const reviewItem = useCallback(async () => {
    return await onChangeReview("REVIEW", setReviewing)
  }, [onChangeReview])

  const unreviewItem = useCallback(async () => {
    return await onChangeReview("UNREVIEW", setUnreviewing)
  }, [onChangeReview])

  const onSubmit = useCallback(
    async (formData: FormDataType) => {
      setSubmitting(true)
      try {
        await editKnowledgeItemsApi({
          requests: [
            {
              oid: item.oid,
              ...getDataForSubmission(formData),
            },
          ],
        })
        void sendAnalyticsEvent({
          surface: "WEB_KNOWLEDGE_ITEM_PAGE",
          event_type: "UPDATE_ENTITY",
          event_data: {
            entity_type: "KNOWLEDGE_ITEM",
            entity_id: item.oid,
          },
        })
        handleSuccess("Updated answer in Answer Bank")
      } catch (error) {
        handleError({ error, prefix: "Couldn't save answer" })
      } finally {
        setSubmitting(false)
      }
    },
    [handleSuccess, handleError, item],
  )

  const onReuse = useCallback(
    (item: KnowledgeItemHistory) => {
      if (item.kind === "EDIT" || !item.kind) {
        if (item.question) {
          form.setFieldValue("primary_question", item.question.primary)
          form.setFieldValue(
            "secondary_question",
            item.question.secondary || "",
          )
        }
        if (item.answer) {
          form.setFieldValue("primary_answer", item.answer.primary)
          form.setFieldValue("secondary_answer", item.answer.secondary || "")
        }
      }
    },
    [form],
  )

  const initialValues = useMemo(
    () => ({
      primary_question: item.content.question.primary,
      secondary_question: item.content.question.secondary || "",
      primary_answer: item.content.answer.primary,
      secondary_answer: item.content.answer.secondary || "",
      question_context: item.content.question.context || "",
      tags: item.tags ? Object.keys(item.tags) : [],
    }),
    [item],
  )

  let sourceContents: React.ReactNode = "Unknown"
  if (item.source.kind === "MANUAL") {
    sourceContents = <div>Added Manually via Quilt Answers</div>
  } else if (item.source.kind === "COMPLETED_QUESTIONNAIRE") {
    const sourceDocUrl = `/source-documents/${item.source.source_oid}${item.source.piece_oid ? `?pieceOid=${item.source.piece_oid}` : ""}`
    sourceContents = (
      <div>
        Imported from questionnaire{" "}
        <Link to={sourceDocUrl}>{item.source.title}</Link>
      </div>
    )
  }

  const autoSize = { minRows: 1, maxRows: 8 }

  const submitTitle = hasPerm("doc.modify")
    ? "Update"
    : "Cannot update, you do not have write permissions"

  if (removing) {
    return (
      <div>
        <div>Removing answer bank item...</div>
        <LoadingSpinner />
      </div>
    )
  }

  const drawerTabs: TabsProps["items"] = [
    {
      key: "discussions",
      label: "Discussions",
      children: (
        <Discussions
          group_oid={activeGroupOid}
          // We reverse the discussions so that the most recent one is at the bottom
          discussions={discussions}
          user_document_oid={item.oid}
          kind="KNOWLEDGE_ITEM"
          discussionsError={discussionError}
        />
      ),
    },
  ]

  if (history?.length) {
    drawerTabs.push({
      key: "history",
      label: "History",
      children: (
        <div className="border-t border-gray-100">
          {history.map((h, index) => (
            <KnowledgeItemHistoryCard
              key={index}
              item={h}
              onReuse={onReuse}
              groupMembers={groupMembers}
              tags={tags}
              tagsLoading={tagsLoading}
              groupMembersLoading={groupMembersLoading}
            />
          ))}
        </div>
      ),
    })
  }

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        className="flex-1 p-8"
        disabled={submitting || reviewing}
        requiredMark="optional"
        initialValues={initialValues}
        onFinish={onSubmit}
      >
        <div className="flex w-full flex-row gap-8">
          <div className="w-72">
            <div>
              <Form.Item label="Created By" required>
                <FormTextItemStyle>
                  <CircleUserRoundIcon size={14} />
                  {item.creator.email}
                </FormTextItemStyle>
                <FormTextItemStyle>
                  <CalendarIcon size={14} />
                  {toHumanReadableString(item.created_at)}
                </FormTextItemStyle>
              </Form.Item>
              <Form.Item label="Last Updated By" required>
                <FormTextItemStyle>
                  <CircleUserRoundIcon size={14} />
                  <LastUpdatedBy item={item} />
                </FormTextItemStyle>
                <FormTextItemStyle>
                  <CalendarIcon size={14} />
                  {toHumanReadableString(item.updated_at)}
                </FormTextItemStyle>
              </Form.Item>
              <Form.Item label="Source" required>
                <FormTextItemStyle>{sourceContents}</FormTextItemStyle>
              </Form.Item>
              {item.last_assigned_to && item.last_assigned_at && (
                <Form.Item label="Last Assigned To" required>
                  <FormTextItemStyle>
                    <CircleUserRoundIcon size={14} />
                    {item.last_assigned_to.email}
                  </FormTextItemStyle>
                  <FormTextItemStyle>
                    <CalendarIcon size={14} />
                    {toHumanReadableString(item.last_assigned_at)}
                  </FormTextItemStyle>
                </Form.Item>
              )}
              {item.last_reviewed_at && item.last_reviewed_by && (
                <Form.Item label="Last Reviewed By" required>
                  <FormTextItemStyle>
                    <CircleUserRoundIcon size={14} />
                    {item.last_reviewed_by.email}
                  </FormTextItemStyle>
                  <FormTextItemStyle>
                    <CalendarIcon size={14} />
                    {toHumanReadableString(item.last_reviewed_at)}
                  </FormTextItemStyle>
                </Form.Item>
              )}
            </div>
          </div>
          <div className="grow">
            <Form.Item
              label="Question"
              required
              name="primary_question"
              rules={[{ required: true }]}
            >
              <Input.TextArea autoSize={autoSize} />
            </Form.Item>
            <Form.Item label="Question Details" name="secondary_question">
              <Input.TextArea autoSize={autoSize} />
            </Form.Item>
            <Form.Item label="Question Context" name="question_context">
              <Input.TextArea autoSize={autoSize} />
            </Form.Item>
            <Divider />
            <div className="mb-[24px] flex flex-row items-end">
              <Form.Item
                className="mb-0 w-full"
                label={
                  <div className="flex flex-row gap-1">
                    <span>Answer</span>
                    <span className="font-normal text-gray-400">
                      (Short Form or Yes/No)
                    </span>
                  </div>
                }
                required
                name="primary_answer"
                rules={[{ required: true }]}
              >
                <Input.TextArea autoSize={autoSize} className="rounded-md" />
              </Form.Item>
              <Tooltip
                title={
                  autoAnswering
                    ? "Generating..."
                    : "Automatically generate an answer with AI"
                }
              >
                <Button
                  type="default"
                  onClick={autoAnswer}
                  className="text-primary ml-2 flex bg-purple-50"
                  htmlType="button"
                  disabled={!primaryQuestion || submitting || autoAnswering}
                >
                  <SparklesIcon />
                </Button>
              </Tooltip>
            </div>
            <Form.Item label="Explanation of Answer" name="secondary_answer">
              <Input.TextArea
                disabled={submitting || autoAnswering}
                autoSize={autoSize}
              />
            </Form.Item>
            <Form.Item label="Tags" name="tags" className="mb-0">
              <Select
                mode="multiple"
                className="w-full"
                options={tags?.map((tag) => ({
                  value: tag.oid,
                  label: <TagListItem tag={tag} />,
                  name: tag.name,
                }))}
                optionFilterProp="name"
                placeholder={
                  tagsLoading ? (
                    <Skeleton
                      active
                      loading
                      title={false}
                      paragraph={{ rows: 1 }}
                    />
                  ) : (
                    "Select Tags"
                  )
                }
                disabled={tagsLoading}
              />
            </Form.Item>
            <span className="text-xs text-gray-500">
              To add a new tag visit{" "}
              <a href="/groups" className="font-semibold">
                groups settings
              </a>
            </span>
            <div className="mt-8 flex flex-row pb-2">
              <Button
                type="primary"
                className="submit mr-2 flex font-bold"
                htmlType="submit"
                title={submitTitle}
                disabled={
                  submitting ||
                  !hasPerm("doc.modify") ||
                  autoAnswering ||
                  tagsLoading
                }
              >
                {submitting ? "Updating..." : "Update"}
              </Button>
              <AssignKnowledgeItemButton
                knowledgeOid={item.oid}
                assignedTo={item.last_assigned_to}
                isUserDocument={false}
              />
              {hasPerm("doc.modify") && (
                <>
                  <Button
                    type="primary"
                    className="submit ml-2 flex items-center font-bold"
                    onClick={reviewItem}
                    icon={<CheckIcon />}
                    title="Mark as Reviewed"
                    disabled={
                      submitting || reviewing || unreviewing || autoAnswering
                    }
                  >
                    {reviewing ? "Reviewing..." : "Review"}
                  </Button>
                  <Button
                    type="primary"
                    className="submit ml-2 flex items-center font-bold"
                    onClick={unreviewItem}
                    icon={<XIcon />}
                    title="Remove review status"
                    disabled={
                      submitting ||
                      reviewing ||
                      unreviewing ||
                      autoAnswering ||
                      !item.last_reviewed_at
                    }
                  >
                    {unreviewing ? "Removing..." : "Remove Review"}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </Form>

      <Drawer
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
        title="Options"
      >
        {discussionsLoading ? (
          <DiscussionsLoading />
        ) : (
          <Tabs
            rootClassName="quilt-questionnaire-sidebar"
            items={drawerTabs}
          />
        )}
      </Drawer>
    </>
  )
}

export default KnowledgeItemForm

import { Button, Empty, Input, Modal, Table, Tooltip } from "antd"
import type { User } from "firebase/auth"
import {
  collection,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore"
import { TrashIcon } from "lucide-react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"

import { EMPTY_ARRAY, EMPTY_OBJ } from "../constants"
import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../dbUtils"
import { ASSIGNED_COLOR_CLASSNAMES } from "../documents/colors"
import { getErrorMessage } from "../errors"
import { MimeTypeToDisplay } from "../files/mimetype"
import { db } from "../firebaseApp"
import { useDocumentFilters } from "../hooks/useDocumentFilters"
import useErrorPopup from "../hooks/useErrorPopup"
import { useGroupTags } from "../hooks/useGroupTags"
import { deleteUserDocumentsApi } from "../knowledge/api"
import type { KnowledgeItem } from "../knowledge/types"
import type { AnyUserDocument } from "../knowledge/types"
import { STATE_MAP } from "../pages/UserDocumentPage/state"
import useSearchResults from "../search/useSearchResults"
import Tag from "../tags/Tag"
import { AnswerReferenceKind } from "../types/answerer"
import { USER_DOCUMENTS_COLLECTION } from "../types/common"
import {
  type MimeType,
  QUESTIONNAIRE_SUPPORTED_MIMETYPES,
} from "../types/mimetype"
import type { UserDocument, UserDocumentState } from "../types/userDocument"
import {
  batchArray,
  getLexicalSorter,
  shorten,
  sortByCreatedAtDesc,
  sortByUpdatedAtDesc,
  uniqueByKey,
} from "../utils"
import BulkAssignButton from "./BulkAssignForm"
import BulkReviewButton from "./BulkReviewButton"
import BulkTagButton from "./BulkTagForm"
import CopyToClipboardButton from "./CopyToClipboardButton"
import UnifiedFilterPopover from "./UnifiedKnowledgeItemsFilter"
import dateColumn from "./table/dateColumn"
import useTableScroll from "./table/useTableScroll"

const { Column } = Table

const _MAX_DOCS = 10000
const _BATCH_SIZE = 30

const getRowKey = (item: AnyUserDocument) => item.oid

const getDocumentTitle = ({
  title,
  source_kind,
  document_url,
}: UserDocument): string => {
  if (source_kind == "PUBLIC_WEB_PAGE") {
    try {
      const asUrl = new URL(document_url)
      const path = asUrl.pathname === "/" ? "" : asUrl.pathname
      const simpleUrl = asUrl.hostname + path
      if (title) {
        return `${title} (${simpleUrl})`
      }
      return simpleUrl
    } catch (err) {
      return `${title} (${document_url})`
    }
  }

  return title || document_url || "Unknown"
}

interface Props {
  user: User
  isUserDocumentMode: boolean
}

const KnowledgeItemsManager = <TItem extends AnyUserDocument>({
  user,
  isUserDocumentMode,
}: Props): React.ReactNode => {
  const { activeGroupOid, hasPerm } = useActiveUserAuthorizationFromContext()

  const [knowledgeItems, setKnowledgeItems] = useState<TItem[]>(EMPTY_ARRAY)
  const [removing, setRemoving] = useState<Record<string, boolean>>(EMPTY_OBJ)
  const [loading, setLoading] = useState<boolean>(true)
  const { hasSearchQuery, searchResults, setQuery } = useSearchResults(
    isUserDocumentMode
      ? AnswerReferenceKind.SOURCE_DOCUMENT_PIECE
      : AnswerReferenceKind.KNOWLEDGE_ITEM,
  )
  const { handleSuccess, handleError } = useErrorPopup()
  const [tags, tagsLoading] = useGroupTags(activeGroupOid)

  const [unifiedFilters, resetFilters, setFilterValue] = useDocumentFilters()
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>(EMPTY_ARRAY)

  useEffect(() => {
    const allDocsQuery = query(
      collection(db, USER_DOCUMENTS_COLLECTION),
      where("group_oid", "==", activeGroupOid),
      where("is_knowledge_item", "==", !isUserDocumentMode),
      orderBy(isUserDocumentMode ? "created_at" : "updated_at", "desc"),
      limit(_MAX_DOCS),
    ).withConverter(makeConverter<TItem>())

    return onSnapshot(allDocsQuery, (querySnapshot) => {
      setLoading(false)
      querySnapshot.docChanges().forEach((change) => {
        setKnowledgeItems((prevDocs) => {
          const prevWithoutChange = prevDocs.filter(
            (d) => d.oid !== change.doc.id,
          )

          if (change.type === "removed") {
            return prevWithoutChange
          }

          const prevWithChange = [change.doc.data(), ...prevWithoutChange].sort(
            isUserDocumentMode ? sortByCreatedAtDesc : sortByUpdatedAtDesc,
          )
          return prevWithChange
        })
      })
    })
  }, [user.uid, activeGroupOid, isUserDocumentMode])

  const unselectAll = useCallback(() => setSelectedRowKeys(EMPTY_ARRAY), [])

  const getNumChildDocs = useCallback(
    async (selectedDocs: TItem[]) => {
      // TODO(iashris): Check also for is_completed_questionnaire
      const docs = selectedDocs.filter(
        (doc) =>
          doc.mimetype &&
          QUESTIONNAIRE_SUPPORTED_MIMETYPES.includes(doc.mimetype),
      )
      if (docs.length === 0) return 0

      const batches = batchArray(docs, _BATCH_SIZE)
      let totalChildDocs = 0

      for (const batch of batches) {
        const childDocsQuery = query(
          collection(db, USER_DOCUMENTS_COLLECTION),
          where("group_oid", "==", activeGroupOid),
          where(
            "source.source_oid",
            "in",
            batch.map((doc) => doc.oid),
          ),
        )

        const childDocsSnapshot = await getDocs(childDocsQuery)
        totalChildDocs += childDocsSnapshot.size
      }

      return totalChildDocs
    },
    [activeGroupOid],
  )

  const proceedWithDeletion = useCallback(async () => {
    const removingOids = Object.fromEntries(
      selectedRowKeys.map((oid) => [oid, true]),
    )

    setRemoving((prev) => ({ ...prev, ...removingOids }))
    try {
      await deleteUserDocumentsApi({ oids: selectedRowKeys })
      unselectAll()
      handleSuccess(
        isUserDocumentMode
          ? "Removed user documents"
          : "We will remove these Answer Bank items in the background",
      )
    } catch (error) {
      handleError({
        error,
        prefix: isUserDocumentMode
          ? "Couldn't remove user documents"
          : "Couldn't remove items from Answer Bank",
      })
    } finally {
      setRemoving(EMPTY_OBJ)
    }
  }, [
    handleSuccess,
    handleError,
    selectedRowKeys,
    isUserDocumentMode,
    unselectAll,
  ])

  const bulkDelete = useCallback(async () => {
    const selectedDocs = knowledgeItems.filter((item) =>
      selectedRowKeys.includes(item.oid),
    )
    const childCount = await getNumChildDocs(selectedDocs)

    if (childCount > 0) {
      Modal.confirm({
        title: "Confirm Deletion",
        content: `${childCount} associated Answer bank item${childCount > 1 ? "s" : ""} will be deleted. Are you sure you want to proceed?`,
        okText: "Delete",
        cancelText: "Cancel",
        okButtonProps: { danger: true },
        onOk: proceedWithDeletion,
      })
    } else {
      await proceedWithDeletion()
    }
  }, [selectedRowKeys, knowledgeItems, getNumChildDocs, proceedWithDeletion])

  const [tableContainerRef, tableScrollProp] = useTableScroll()

  const anyRemoving = Object.values(removing).some((v) => v)

  const rowSelection = useMemo(
    () =>
      hasPerm("doc.modify")
        ? {
            selectedRowKeys,
            onChange: (keys: React.Key[]) =>
              // Cast is safe because we use string keys for table rows.
              setSelectedRowKeys(keys as string[]),
            columnWidth: 48,
            getCheckboxProps: () => ({
              disabled: anyRemoving,
            }),
          }
        : undefined,
    [hasPerm, anyRemoving, selectedRowKeys],
  )

  const filteredKnowledgeItems = useMemo(() => {
    let filtered = knowledgeItems

    if (searchResults) {
      const docsByOid = new Map(knowledgeItems.map((d) => [d.oid, d]))
      if (isUserDocumentMode) {
        filtered = uniqueByKey(
          searchResults,
          (r) => r.document.source_document_oid!,
        )
          .map((r) => docsByOid.get(r.document.source_document_oid!))
          .filter((r) => !!r)
      } else {
        filtered = uniqueByKey(
          searchResults,
          (r) => r.document.knowledge_item_oid!,
        )
          .map((r) => docsByOid.get(r.document.knowledge_item_oid!))
          .filter((r) => !!r)
      }
    }

    if (unifiedFilters.creator) {
      filtered = filtered.filter(
        (item) => item.creator.email === unifiedFilters.creator,
      )
    }

    if (unifiedFilters.source) {
      filtered = filtered.filter(
        (item) =>
          item.is_knowledge_item &&
          item.source.kind === "COMPLETED_QUESTIONNAIRE" &&
          item.source.source_oid === unifiedFilters.source,
      )
    }

    if (unifiedFilters.assignedTo) {
      filtered = filtered.filter(
        (item) => item.last_assigned_to?.email === unifiedFilters.assignedTo,
      )
    }

    if (unifiedFilters.tags.length > 0) {
      filtered = filtered.filter((item) =>
        unifiedFilters.tags.every((tag) => item.tags && tag in item.tags),
      )
    }

    if (unifiedFilters.sourceKind) {
      filtered = filtered.filter(
        (item) => unifiedFilters.sourceKind === item.source_kind,
      )
    }

    return filtered
  }, [isUserDocumentMode, searchResults, knowledgeItems, unifiedFilters])

  return (
    <div className="flex grow flex-col overflow-y-hidden p-8">
      <div className="mb-2 flex gap-2">
        <Input.Search
          placeholder={
            isUserDocumentMode
              ? "search source documents..."
              : "search answer bank..."
          }
          onChange={(e) => setQuery(e.target.value)}
          onSearch={setQuery}
          name="search"
          className="w-full flex-grow"
        />
        <UnifiedFilterPopover
          tags={tags}
          knowledgeItems={knowledgeItems}
          onFilterChange={setFilterValue}
          filters={unifiedFilters}
          onClear={resetFilters}
        />
      </div>
      <div className="m-2 flex items-center justify-start gap-2 font-bold text-gray-600">
        {loading ? (
          <div className="leading-7">&nbsp;</div>
        ) : (
          <div className="leading-7">
            Showing {filteredKnowledgeItems.length.toLocaleString()} of{" "}
            {knowledgeItems.length.toLocaleString()} items
          </div>
        )}
        {selectedRowKeys.length > 0 && (
          <>
            <span> · </span>
            <span>{`${selectedRowKeys.length} selected:`}</span>
            <BulkAssignButton
              oids={selectedRowKeys}
              onFinish={unselectAll}
              isUserDocument={isUserDocumentMode}
            />
            <BulkTagButton
              items={knowledgeItems.filter((item) =>
                selectedRowKeys.includes(item.oid),
              )}
              onFinish={unselectAll}
              tags={tags}
              loading={tagsLoading}
              isUserDocument={isUserDocumentMode}
            />
            <BulkReviewButton oids={selectedRowKeys} onFinish={unselectAll} />
            <Button
              size="small"
              loading={anyRemoving}
              disabled={anyRemoving}
              className="ml-1 flex items-center"
              type="default"
              danger
              onClick={bulkDelete}
              icon={<TrashIcon size={12} />}
            >
              <span className="text-[12px] font-bold">Remove</span>
            </Button>
          </>
        )}
      </div>
      <Table
        ref={tableContainerRef}
        virtual
        size="middle"
        className="min-w-[900px] max-w-full grow overflow-y-hidden"
        scroll={tableScrollProp}
        dataSource={filteredKnowledgeItems}
        rowKey={getRowKey}
        locale={{
          emptyText: (
            <Empty
              description={
                hasSearchQuery
                  ? `No ${isUserDocumentMode ? "documents" : "items"} match your search`
                  : isUserDocumentMode
                    ? `No documents${hasPerm("doc.modify") ? ", add one to get started!" : ""}`
                    : "No items in your Answer Bank, import something to get started!"
              }
            />
          ),
        }}
        loading={loading || tagsLoading}
        pagination={false}
        bordered
        rowSelection={rowSelection}
      >
        {isUserDocumentMode ? (
          <>
            <Column
              title="Type"
              dataIndex="mimetype"
              key="mimetype"
              width={68}
              render={(mimetype: MimeType) => {
                const fileDisplay =
                  MimeTypeToDisplay[mimetype] ?? MimeTypeToDisplay.UNKNOWN
                return (
                  <div className="pt-1">
                    <Tooltip title={fileDisplay.name} className="h-4">
                      {fileDisplay.icon}
                    </Tooltip>
                  </div>
                )
              }}
            />
            <Column
              title="Title"
              dataIndex="title"
              key="title"
              sorter={getLexicalSorter("title")}
              render={(_title, data: UserDocument) => (
                <div className="flex">
                  <Link to={`/source-documents/${data.oid}`}>
                    {shorten(getDocumentTitle(data), 100)}
                  </Link>
                  <CopyToClipboardButton text={data.document_url} height="16" />
                </div>
              )}
            />
          </>
        ) : (
          <>
            <Column
              title="Content"
              key="content"
              render={(item: KnowledgeItem) => {
                const { question, answer } = item.content

                return (
                  <Link to={`/knowledge-items/${item.oid}`}>
                    <div>
                      <span className="font-semibold">Q: </span>
                      {shorten(question.primary, 150)}
                    </div>
                    <div>
                      <span className="font-semibold">A: </span>
                      {shorten(
                        `${answer.primary}\n${answer.secondary ?? ""}`,
                        150,
                      )}
                    </div>
                  </Link>
                )
              }}
            />
            <Column
              title="Source"
              key="source"
              defaultSortOrder="ascend"
              width={180}
              render={(item: KnowledgeItem) => {
                if (item.source.kind !== "MANUAL") {
                  return item.source.title
                }

                const creator = item.creator
                const value =
                  creator.uid === user.uid ? "Added by you" : creator.email
                return value
              }}
            />
          </>
        )}

        <Column
          title="Tags"
          key="tags"
          className="max-w-40 overflow-hidden"
          width={135}
          render={(item: AnyUserDocument) => (
            <div className="flex flex-wrap">
              {Object.keys(item.tags ?? {}).map((oid) => {
                const tagObj = tags.find((t) => t.oid === oid)
                return tagObj ? (
                  <Tag
                    key={oid}
                    tag={tagObj}
                    className="mb-1 overflow-hidden"
                  />
                ) : null
              })}
            </div>
          )}
        />
        <Column
          title="Status"
          dataIndex="state"
          key="state"
          sorter={getLexicalSorter("state")}
          width={150}
          render={(state: UserDocumentState, item: AnyUserDocument) => {
            // Show REMOVING as soon as the user changes the state.
            const stateInfo =
              STATE_MAP[
                item.removal_requested && state !== "REMOVED"
                  ? "REMOVING"
                  : state
              ] ?? STATE_MAP.UNKNOWN
            const assignedLine = !item.last_assigned_to
              ? ""
              : item.last_assigned_to.uid === user.uid
                ? "You"
                : item.last_assigned_to.email
            const { error_type } = item
            return (
              <>
                <Tooltip
                  title={
                    error_type
                      ? getErrorMessage({ error: error_type }, false)
                      : stateInfo.tooltip
                  }
                >
                  <div
                    className={
                      "w-fit rounded px-2 py-0.5 text-xs font-bold " +
                      stateInfo.classNames
                    }
                  >
                    {stateInfo.name}
                  </div>
                </Tooltip>
                {assignedLine && (
                  <div className="mt-2 max-w-36 truncate">
                    <Tooltip
                      title={`Assigned to ${assignedLine}`}
                      className={
                        "rounded-md p-1 text-sm font-semibold " +
                        ASSIGNED_COLOR_CLASSNAMES
                      }
                    >
                      {assignedLine}
                    </Tooltip>
                  </div>
                )}
              </>
            )
          }}
        />
        {isUserDocumentMode &&
          dateColumn({
            title: "Added On",
            dataIndex: "created_at",
            width: 140,
            sortable: true,
          })}
        {!isUserDocumentMode &&
          dateColumn({
            title: "Reviewed On",
            dataIndex: "last_reviewed_at",
            width: 140,
            sortable: true,
          })}
        {!isUserDocumentMode &&
          dateColumn({
            title: "Updated On",
            dataIndex: "updated_at",
            width: 140,
            sortable: true,
          })}
      </Table>
    </div>
  )
}

export default KnowledgeItemsManager

import type { GeneratedAnsweredQuestion } from "./answerer"
import type { UserFacingErrorType } from "./common"
import type { MimeType } from "./mimetype"
import type { QuestionAnswerLayout, WorksheetRange } from "./sheets"

export const ANSWER_QUESTIONNAIRE_JOBS_COLLECTION = "answer_questionnaire_jobs"
export const ANSWER_QUESTIONNAIRE_JOBS_ANSWERS_SUBCOLLECTION = "answers"
export const ANSWER_QUESTIONNAIRE_JOBS_EDITS_SUBCOLLECTION = "edits"
export const ANSWER_QUESTIONNAIRE_JOBS_HISTORY_SUBCOLLECTION = "history"

// types from jobs/types.py
export enum AnswerQuestionnaireJobState {
  START = "START",
  VERIFYING_UPLOAD = "VERIFYING_UPLOAD",
  DOWNLOADING = "DOWNLOADING",
  DOWNLOADING_ERROR = "DOWNLOADING_ERROR",
  IMPORTING_QUESTIONS = "IMPORTING_QUESTIONS",
  IMPORTING_QUESTIONS_ERROR = "IMPORTING_QUESTIONS_ERROR",
  ANALYZING = "ANALYZING",
  ANALYZING_ERROR = "ANALYZING_ERROR",
  ANSWERING = "ANSWERING",
  ANSWERING_ERROR = "ANSWERING_ERROR",
  SAVING = "SAVING",
  SAVING_ERROR = "SAVING_ERROR",
  REVIEWING = "REVIEWING",
  DONE = "DONE",
  REMOVING = "REMOVING",
  REMOVED = "REMOVED",
}

export interface AnswerActor {
  uid: string
  email: string
}

export interface AnswerQuestionnaireJob {
  oid: string
  creator: AnswerActor
  created_at: FirebaseFirestore.Timestamp
  updated_at: FirebaseFirestore.Timestamp

  group_oid: string

  idempotency_key: string
  question_answer_layouts: Record<string, QuestionAnswerLayout>
  external_document_url?: string | null
  internal_document_uri?: string | null
  document_mimetype?: MimeType | null

  original_filename?: string | null
  title?: string | null

  num_questions: number | null
  num_answers_attempted: number

  state: AnswerQuestionnaireJobState
  error_type?: UserFacingErrorType | null
  error_detail?: string | null
  finished_questionnaire_uri?: string | null
  needs_attention?: boolean
  needs_attention_detail?: string

  exported_source_document_oid: string | null
  exported_source_document_timestamp: FirebaseFirestore.Timestamp | null

  removal_requested?: boolean | null
}

export interface AnswerQuestionnaireCellEdit {
  oid: string
  location: WorksheetRange
  text_content: string

  created_at: FirebaseFirestore.Timestamp
  creator_uid: string
  creator_email: string
}

interface SheetTextWithoutLocation {
  text: string
}

export interface UserAnswer {
  primary_answer: SheetTextWithoutLocation
  secondary_answer: SheetTextWithoutLocation | null
}

interface HistoryItemBase {
  oid: string
  created_at: FirebaseFirestore.Timestamp
  actor: AnswerActor | null
}

interface ResponseHistoryItemBase extends HistoryItemBase {
  question_oid: string
}

interface EditResponseHistoryItem extends ResponseHistoryItemBase {
  kind: "EDIT_RESPONSE"
  answer: UserAnswer
  actor: AnswerActor
}

interface QuiltResponseHistoryItem extends ResponseHistoryItemBase {
  kind: "QUILT_RESPONSE"

  actor: null
  answer: GeneratedAnsweredQuestion
}

interface ReviewResponseHistoryItem extends ResponseHistoryItemBase {
  kind: "REVIEW"
  actor: AnswerActor
}

interface UnReviewResponseHistoryItem extends ResponseHistoryItemBase {
  kind: "UNREVIEW"
  actor: AnswerActor
}

interface AssignResponseHistoryItem extends ResponseHistoryItemBase {
  kind: "ASSIGN"
  actor: AnswerActor
  assign_to: AnswerActor
  message: string
}

interface UnassignResponseHistoryItem extends ResponseHistoryItemBase {
  kind: "UNASSIGN"
  actor: AnswerActor
}

interface EditCellHistoryItem extends HistoryItemBase {
  kind: "EDIT_CELL"
  actor: AnswerActor

  location: WorksheetRange
  text_content: string
}

export type AnswerQuestionnaireHistoryItem =
  | EditResponseHistoryItem
  | QuiltResponseHistoryItem
  | ReviewResponseHistoryItem
  | UnReviewResponseHistoryItem
  | AssignResponseHistoryItem
  | UnassignResponseHistoryItem
  | EditCellHistoryItem

export interface AnswerQuestionnaireCellEditRequest {
  job_oid: string
  location: WorksheetRange
  text_content: string
}

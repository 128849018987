import { Popover } from "antd/lib"
import { useCallback, useState } from "react"

import GongLogo from "../assets/img/gong_logo.svg"
import { type CredentialMetadata } from "../credentials/providerTypes"
import useErrorPopup from "../hooks/useErrorPopup"
import { removeSource } from "../sources/api"
import type { GongSource } from "../sources/types"
import GongPicker from "./GongPicker"
import IntegrationBlock from "./IntegrationBlock"

interface GongIntegrationBlockProps {
  source: GongSource | undefined
  loading: boolean
  credential: CredentialMetadata | undefined
  connectCredential: () => Promise<void>
  disconnectCredential: () => Promise<void>
}

const GongIntegrationBlock: React.FC<GongIntegrationBlockProps> = ({
  source,
  loading,
  credential,
  connectCredential,
  disconnectCredential,
}) => {
  const [pickerOpen, setPickerOpen] = useState(false)

  const { handleSuccess, handleError } = useErrorPopup()

  const onEdit = useCallback(() => {
    setPickerOpen(true)
  }, [])

  const connectSource = useCallback(async () => {
    try {
      if (!credential) {
        await connectCredential()
      }
      onEdit()
    } catch (error) {
      handleError({ error, prefix: "Failed to connect Gong" })
    }
  }, [connectCredential, credential, onEdit, handleError])

  const hidePicker = useCallback(() => {
    setPickerOpen(false)
  }, [setPickerOpen])

  const onDisconnect = useCallback(async () => {
    if (!source) {
      return
    }
    try {
      await removeSource(source.oid)
      await disconnectCredential()
      handleSuccess(
        "Successfully disconnected. Your calls will be removed in the background",
      )
    } catch (error) {
      handleError({ error, prefix: "Failed to disconnect Gong" })
    }
  }, [source, disconnectCredential, handleSuccess, handleError])

  return (
    <Popover
      content={<GongPicker source={source} onClose={hidePicker} />}
      onOpenChange={setPickerOpen}
      open={pickerOpen}
      trigger="click"
    >
      <IntegrationBlock
        loading={loading}
        name="Gong"
        connected={!!source}
        onEdit={onEdit}
        onConnect={connectSource}
        onDisconnect={onDisconnect}
        logo={GongLogo}
        disabledReason={
          source?.removal_requested
            ? "Removal requested"
            : pickerOpen
              ? "Use the form above to modify the source"
              : undefined
        }
      />
    </Popover>
  )
}
export default GongIntegrationBlock

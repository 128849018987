import { Button, Typography } from "antd"

import { useTour } from "./TourContext"
import { actionButtonLabel, message, title } from "./steps"

interface Props {
  className?: string
}

const TourCTA: React.FC<Props> = ({ className = "" }) => {
  const { startTour } = useTour()

  return (
    <div
      className={`flex flex-1 flex-col justify-between rounded-xl bg-purple-50 p-6 ${className}`}
    >
      <div className="overflow-hidden">
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
          }}
          className="!m-0 text-[16px] font-bold text-purple-700"
        >
          {title}
        </Typography.Paragraph>
        <Typography.Paragraph
          className="m-0"
          ellipsis={{
            rows: 2,
          }}
        >
          {message}
        </Typography.Paragraph>
      </div>
      <Button
        className="text-primary w-full border-none font-semibold"
        onClick={startTour}
      >
        {actionButtonLabel}
      </Button>
    </div>
  )
}

export default TourCTA

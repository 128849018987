import { Skeleton } from "antd"
import {
  CircleCheckIcon,
  CircleHelpIcon,
  CircleXIcon,
  XIcon,
} from "lucide-react"
import { forwardRef } from "react"

import ChatReferenceList from "../components/ChatReferenceList"
import TextWithBulletPoints from "../components/TextWithBulletPoints"
import type {
  AnsweredQuestionUICard,
  AutoSearchUICard,
  CardDetail,
  LiveUICard,
} from "./types"

const cardClassName =
  "relative group mb-2 w-full rounded border border-gray-50 bg-white p-2 "
const messageContainerClassName = "flex mb-1 p-1 pr-7 items-start"
const iconClassName = "shrink-0 w-[20px] mr-1"
const textClassName = "p-1 px-2 rounded-xl"

const UNANSWERED_MESSAGE = "Unable to answer."

const AnsweredQuestionCardView: React.FC<{
  message: AnsweredQuestionUICard
  showCardDetail: (cardDetail: CardDetail) => void
}> = ({ message, showCardDetail }) => {
  const { question, answer_state, error_message, answer } = message
  const questionElement = (
    <div
      className={messageContainerClassName + " cursor-pointer"}
      onClick={() => showCardDetail(message)}
    >
      <CircleHelpIcon className={`${iconClassName} text-gray-300`} />
      <span className="rounded-xl p-1 px-2 font-bold text-gray-600">
        {question}
      </span>
    </div>
  )

  if (answer_state === "PENDING") {
    return (
      <>
        {questionElement}
        <Skeleton title={false} loading active />
      </>
    )
  } else if (answer_state === "ERROR") {
    return (
      <>
        {questionElement}
        <div className={messageContainerClassName}>
          <CircleXIcon className={`${iconClassName} text-red-300`} />
          <span className={textClassName + " font-semibold text-red-300"}>
            {error_message ?? UNANSWERED_MESSAGE}
          </span>
        </div>
      </>
    )
  } else {
    return (
      <>
        {questionElement}
        {answer && (
          <>
            <div className={messageContainerClassName}>
              <CircleCheckIcon className={`${iconClassName} text-green-400`} />
              <TextWithBulletPoints text={answer.primary_answer} />
            </div>
            <div className="invisible mt-4 flex items-end justify-end group-hover:visible">
              <ChatReferenceList references={answer.references} />
            </div>
          </>
        )}
      </>
    )
  }
}

const AutoSearchCardView: React.FC<{
  message: AutoSearchUICard
}> = ({ message: { query, answer_state, error_message, results } }) => {
  const topPart = (
    <div className={messageContainerClassName}>
      <CircleHelpIcon className={`${iconClassName} text-gray-300`} />
      <span className="rounded-xl p-1 px-2 font-bold text-gray-600">
        {query}
      </span>
    </div>
  )

  if (answer_state === "PENDING") {
    return (
      <>
        {topPart}
        <Skeleton title={false} loading active />
      </>
    )
  } else if (answer_state === "ERROR") {
    return (
      <>
        {topPart}
        <div className={messageContainerClassName}>
          <CircleXIcon className={`${iconClassName} text-red-300`} />
          <span className={textClassName + " font-semibold text-red-300"}>
            {error_message ?? UNANSWERED_MESSAGE}
          </span>
        </div>
      </>
    )
  } else {
    return (
      <>
        {topPart}
        {results && results.length > 0 && (
          <div className={messageContainerClassName}>
            <CircleCheckIcon className={`${iconClassName} text-green-400`} />
            <ul className="m-0 rounded-xl pl-6">
              {results.slice(0, 3).map((r, i) => (
                <li key={i}>
                  <a
                    href={r.link}
                    target="_blank"
                    className="font-semibold text-purple-800"
                  >
                    {r.title}
                  </a>
                  <div className="text-gray-600">{r.snippet}</div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </>
    )
  }
}

interface Props {
  message: LiveUICard
  onDismissCard: (card: LiveUICard) => Promise<void>
  onMarkCardSeen: (card: LiveUICard) => Promise<void>
  showCardDetail: (cardDetail: CardDetail) => void
  className?: string
}

const LiveCard: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { message, onDismissCard, onMarkCardSeen, showCardDetail, className = "" },
  ref,
) => {
  let inner: JSX.Element | null = null
  if (message.kind === "ANSWERED_QUESTION") {
    inner = (
      <AnsweredQuestionCardView
        message={message}
        showCardDetail={showCardDetail}
      />
    )
  } else if (message.kind === "AUTO_SEARCH") {
    inner = <AutoSearchCardView message={message} />
  }

  // TODO(mgraczyk): Call onMarkCardSeen
  void onMarkCardSeen

  if (!inner) {
    return null
  }

  inner = (
    <>
      <div className="absolute right-[10px] top-[10px] z-10 flex shrink-0 items-start bg-white opacity-80">
        <XIcon
          size="30"
          className="text-gray-400 hover:cursor-pointer hover:text-gray-900"
          onClick={() => onDismissCard(message)}
        />
      </div>
      {inner}
    </>
  )

  return (
    <div ref={ref} className={cardClassName + className}>
      {inner}
    </div>
  )
}

export default forwardRef(LiveCard)

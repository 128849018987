import { Skeleton } from "antd"
import { ArrowRight, ListTodo } from "lucide-react"
import { Link } from "react-router-dom"

interface Props {
  items: { title: string; questions: number; url: string }[]
  loading: boolean
}

const QuestionnaireReviewTile: React.FC<Props> = ({ items, loading }) => {
  const itemElements = items.map((item, index) => (
    <Link
      to={item.url}
      key={index}
      className="bg-gray-25 mb-2 flex cursor-pointer items-center justify-between rounded-md p-2 text-sm font-medium hover:bg-gray-50"
    >
      <div>
        <div className="text-primary">{item.title}</div>
        <span className="text-gray-400">{item.questions} questions</span>
      </div>
      <div>
        <ArrowRight size={18} className="text-primary" />
      </div>
    </Link>
  ))
  return (
    <div className="mt-6 flex flex-1 flex-col gap-4 md:flex-row">
      <div className="flex-grow rounded-2xl border border-gray-100 p-6">
        <div className="flex items-center text-sm font-bold text-gray-400">
          <ListTodo className="mr-2" size={18} />{" "}
          <div>Questionnaire Assistant</div>
        </div>
        <h3 className="mb-4 text-lg font-semibold">
          Questionnaires Ready for Review
        </h3>
        {loading ? (
          <Skeleton active loading />
        ) : (
          <>
            <div className="mb-4 text-7xl">{items.length}</div>
            {itemElements}
          </>
        )}
      </div>
    </div>
  )
}

export default QuestionnaireReviewTile

import { Button, Popover, Skeleton } from "antd"
import { MoveUpRightIcon } from "lucide-react"
import { useState } from "react"

import {
  getReferenceColorClass,
  useEnrichedReference,
} from "../hooks/useEnrichedReference"
import ReferenceBadge from "../hooks/useEnrichedReference/ReferenceBadge"
import type { AnswerReference } from "../types/answerer"

export const ReferenceLink: React.FC<{
  index: number
  reference: AnswerReference
  loadEagerly?: boolean
  isVerbatimFromAnswerBank?: boolean
}> = ({ index, reference, loadEagerly, isVerbatimFromAnswerBank }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
  const { enrichedReference, isLoading } = useEnrichedReference(
    reference,
    "popover",
    loadEagerly || isPopoverOpen,
  )

  const title = isLoading ? "Loading..." : enrichedReference.title
  const contentUrl = isLoading ? "" : enrichedReference.contentUrl
  const content = isLoading ? (
    <Skeleton active paragraph={{ rows: 4 }} />
  ) : (
    enrichedReference.content
  )

  const colorClass = getReferenceColorClass(reference)
  return (
    <Popover
      rootClassName="w-96"
      onOpenChange={setIsPopoverOpen}
      title={
        <a className="font-bold" href={contentUrl} target="_blank">
          {title}
          <br />
          <ReferenceBadge reference={reference} />
        </a>
      }
      content={<div className="max-h-96 overflow-y-auto">{content}</div>}
      placement="leftTop"
    >
      <Button
        className={
          "mb-2 mr-1 flex cursor-pointer items-center justify-between rounded-sm py-1 font-bold " +
          colorClass
        }
        type="text"
        target="_blank"
        rel="noopener, noreferrer"
        href={enrichedReference.path}
      >
        {isVerbatimFromAnswerBank ? (
          "Answer straight from answer bank"
        ) : (
          <div className="flex w-full items-center">
            <div className="max-w-xl truncate">{`${index + 1} ${title}`}</div>{" "}
            <MoveUpRightIcon className="h-4 min-h-4 w-4 min-w-4" />
          </div>
        )}
      </Button>
    </Popover>
  )
}

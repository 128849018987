import { Skeleton } from "antd"
import { collection, doc, query } from "firebase/firestore"
import {
  useCollectionDataOnce,
  useDocumentData,
} from "react-firebase-hooks/firestore"

import TourCTA from "../../components/Tour/TourCTA"
import { EMPTY_ARRAY } from "../../constants"
import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../../dbUtils"
import { db } from "../../firebaseApp"
import {
  FRONTEND_HOMEPAGE_COLLECTION,
  type HomepageMetricDocument,
  MEMBER_METRICS_SUBCOLLECTION,
} from "../../metrics/types"
import { GROUPS_COLLECTION } from "../../types/common"
import type { AnswerQuestionnaireJob } from "../../types/jobs"
import {
  ANSWER_QUESTIONNAIRE_JOBS_COLLECTION,
  AnswerQuestionnaireJobState,
} from "../../types/jobs"
import AssistantTile from "./AssistantTile"
import OnboardingTasks from "./OnboardingTasks"
import QuestionnaireReviewTile from "./QuestionnaireReviewTile"
import SourceDocumentTile from "./SourceDocumentTile"
import TopCard from "./TopCard"

const isJobRemoved = (job: AnswerQuestionnaireJob) => job.removal_requested

const HomePage: React.FC = () => {
  const { authUser, activeGroupOid } = useActiveUserAuthorizationFromContext()
  const [metricDocs, metricsLoading] = useDocumentData(
    doc(
      db,
      FRONTEND_HOMEPAGE_COLLECTION,
      activeGroupOid,
      MEMBER_METRICS_SUBCOLLECTION,
      authUser.uid,
    ).withConverter(makeConverter<HomepageMetricDocument>()),
  )

  const jobsQuery = query(
    collection(
      db,
      GROUPS_COLLECTION,
      activeGroupOid,
      ANSWER_QUESTIONNAIRE_JOBS_COLLECTION,
    ),
  ).withConverter(makeConverter<AnswerQuestionnaireJob>())
  const [
    answerQuestionnaireJobs,
    answerQuestionnaireJobsLoading,
    answerQuestionnaireJobsError,
  ] = useCollectionDataOnce(jobsQuery)

  const numQuestionsAsked = metricDocs?.metrics?.["ASK_QUESTION"] ?? 0
  const docsUploaded = metricDocs?.metrics?.["CREATE_ENTITY"] ?? 0

  if (answerQuestionnaireJobsError) {
    console.error(answerQuestionnaireJobsError)
  }

  const questionnairesOrEmpty = answerQuestionnaireJobs ?? EMPTY_ARRAY
  const runsReadyForReview = questionnairesOrEmpty
    .filter(
      (job) =>
        !isJobRemoved(job) &&
        job.state === AnswerQuestionnaireJobState.REVIEWING &&
        !!job.num_questions,
    )
    .map((job) => ({
      title: job.title || job.original_filename || "Questionnaire",
      questions: job.num_questions ?? 0,
      url: `/questionnaire-assistant/review/${job.oid}`,
    }))
  const loadingTile = (
    <Skeleton
      active
      loading
      className="flex-1 rounded-2xl border border-gray-100 p-6"
    />
  )

  return (
    <div className="m-8">
      <h1 className="mb-6 text-4xl font-bold">Welcome to Quilt!</h1>
      <div className="flex gap-6">
        <div className="w-1/3">
          <TourCTA className="h-48" />
          <OnboardingTasks metric={metricDocs} loading={metricsLoading} />
        </div>
        <div className="w-2/3">
          <div className="mb-6">
            <SourceDocumentTile
              numDocs={docsUploaded}
              loading={metricsLoading}
            />
          </div>
          <div className="flex w-full gap-6">
            {answerQuestionnaireJobsLoading ? (
              loadingTile
            ) : questionnairesOrEmpty.length > 0 ? (
              <TopCard
                title="Questionnaires you have submitted"
                value={questionnairesOrEmpty.length}
              />
            ) : (
              <AssistantTile
                ctaText="Upload Questionnaire"
                type="questionnaire"
                title="Upload your first questionnaire and have it answered in minutes"
                url="/questionnaire-assistant"
              />
            )}

            {metricsLoading ? (
              loadingTile
            ) : numQuestionsAsked ? (
              <TopCard
                title="Questions you have asked"
                value={numQuestionsAsked}
              />
            ) : (
              <AssistantTile
                ctaText="Ask a question"
                type="knowledge"
                title="Ask any question about your product"
                url="/answer-search"
              />
            )}
          </div>
          <QuestionnaireReviewTile
            items={runsReadyForReview}
            loading={answerQuestionnaireJobsLoading}
          />
        </div>
      </div>
    </div>
  )
}

export default HomePage

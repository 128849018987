import { Table, Tag, Typography } from "antd"
import type { ColumnsType } from "antd/es/table"
import type { User as AuthUser } from "firebase/auth"
import { collection, limit, or, query, where } from "firebase/firestore"
import { useMemo } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"

import { withHeader } from "../../components/Header"
import { dateColumnProps } from "../../components/table/dateColumn"
import useTableScroll from "../../components/table/useTableScroll"
import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../../dbUtils"
import {
  DISCUSSION_COLLECTION,
  type SheetDiscussion,
} from "../../discussions/types"
import { db } from "../../firebaseApp"
import type { KnowledgeItem } from "../../knowledge/types"
import {
  GROUPS_COLLECTION,
  USER_DOCUMENTS_COLLECTION,
} from "../../types/common"
import type { WorksheetRange } from "../../types/sheets"
import type { NonKnowledgeItemUserDocument } from "../../types/userDocument"

const { Text } = Typography

interface TaskItem {
  oid: string
  type: "discussion" | "knowledge" | "source"
  title: string
  status: "Assigned" | "Resolved"
  date: FirebaseFirestore.Timestamp | null
  assignedBy?: string
  assignedAt?: FirebaseFirestore.Timestamp | null
  resolvedAt?: FirebaseFirestore.Timestamp | null
  kind?: string
  jobId?: string
  location?: WorksheetRange
}

interface Props {
  user: AuthUser
}

const DiscussionsComponent: React.FC<Props> = ({ user }) => {
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()

  const [assignedDiscussions, assignedDiscussionsLoading] = useCollectionData(
    query(
      collection(
        db,
        GROUPS_COLLECTION,
        activeGroupOid,
        DISCUSSION_COLLECTION,
      ).withConverter(makeConverter<SheetDiscussion>()),
      where("assignment.uid", "==", user.uid),
      limit(500),
    ),
  )

  const [assignedKnowledgeItems, assignedKnowledgeItemsLoading] =
    useCollectionData(
      query(
        collection(db, USER_DOCUMENTS_COLLECTION).withConverter(
          makeConverter<NonKnowledgeItemUserDocument | KnowledgeItem>(),
        ),
        or(
          where("last_assigned_to.uid", "==", user.uid),
          where("last_reviewed_by.uid", "==", user.uid),
        ),
        limit(500),
      ),
    )
  const [tableContainerRef, tableScrollProp] = useTableScroll()

  const taskItems: TaskItem[] = useMemo(() => {
    const discussionItems = (assignedDiscussions || []).map((discussion) => ({
      oid: discussion.oid,
      type: "discussion" as const,
      title: "Discussion",
      status: discussion.resolution
        ? ("Resolved" as const)
        : ("Assigned" as const),
      date: discussion.created_at,
      assignedBy: discussion.author_email,
      assignedAt: discussion.assignment?.timestamp,
      resolvedAt: discussion.resolution?.timestamp,
      kind: discussion.kind,
      jobId: discussion.job_id,
      location: discussion.location,
    }))

    const knowledgeItems = (assignedKnowledgeItems || []).map((item) => ({
      oid: item.oid,
      type: item.is_knowledge_item
        ? ("knowledge" as const)
        : ("source" as const),
      title: item.is_knowledge_item
        ? item.content.question.primary
        : (item.title ?? "Untitled Source Document"),
      status: item.last_reviewed_by
        ? ("Resolved" as const)
        : ("Assigned" as const),
      date: item.last_reviewed_at || item.created_at,
      assignedBy: item.last_assigned_by?.email,
      assignedAt: item.last_assigned_at,
      resolvedAt: item.last_reviewed_at,
    }))

    return [...discussionItems, ...knowledgeItems]
  }, [assignedDiscussions, assignedKnowledgeItems])

  const getItemUrl = (item: TaskItem) => {
    if (item.type === "discussion") {
      if (item.kind === "SHEET" || !item.kind) {
        return `/questionnaire-assistant/review/${item.jobId}?sheetName=${item.location?.sheetName}&firstRowIndex=${item.location?.firstRowIndex}&firstColIndex=${item.location?.firstColIndex}&discussion_oid=${item.oid}`
      } else if (item.kind === "USER_DOCUMENT") {
        return `/source-documents/${item.oid}?discussion_oid=${item.oid}`
      } else if (item.kind === "KNOWLEDGE_ITEM") {
        return `/knowledge-items/${item.oid}?discussion_oid=${item.oid}`
      }
    } else if (item.type === "knowledge") {
      return `/knowledge-items/${item.oid}`
    } else if (item.type === "source") {
      return `/source-documents/${item.oid}`
    }
    return "#"
  }

  const columns: ColumnsType<TaskItem> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (title: string, record) => (
        <div>
          <a
            className="text-purple-800 hover:underline"
            href={getItemUrl(record)}
            target="_blank"
          >
            {title}
          </a>
        </div>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type: string) => (
        <Tag
          color={
            type === "discussion"
              ? "green"
              : type === "knowledge"
                ? "blue"
                : "orange"
          }
        >
          {type === "discussion"
            ? "Questionnaire"
            : type === "knowledge"
              ? "Answer Bank"
              : "Source Document"}
        </Tag>
      ),
      sorter: (a, b) => a.type.localeCompare(b.type),
      width: 180,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: TaskItem["status"]) => (
        <Tag color={status === "Assigned" ? "orange" : "green"}>{status}</Tag>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
      width: 120,
    },
    {
      title: "Assigned By",
      dataIndex: "assignedBy",
      key: "assignedBy",
      sorter: (a, b) => (a.assignedBy ?? "").localeCompare(b.assignedBy ?? ""),
      width: 180,
    },
    dateColumnProps({
      title: "Date Added",
      dataIndex: "assignedAt",
      width: 160,
      sortable: true,
    }),
  ]

  return (
    <Table
      ref={tableContainerRef}
      scroll={tableScrollProp}
      virtual
      className="m-8 min-w-[700px] grow overflow-y-hidden"
      size="small"
      bordered
      dataSource={taskItems}
      columns={columns}
      rowKey="oid"
      loading={assignedDiscussionsLoading || assignedKnowledgeItemsLoading}
      pagination={false}
      locale={{
        emptyText: <Text>No assigned or resolved tasks</Text>,
      }}
    />
  )
}

export default withHeader<Props>({
  title: "Assigned Tasks",
  subtitle: "Tasks that have been assigned to you.",
})(DiscussionsComponent)

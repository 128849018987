import { sendAnalyticsEvent } from "../../analytics"
import { createAnswerQuestionnaireJob } from "../../api"
import uploadUserFile from "../../files/uploadUserFile"
import { isGoogleDriveDocument } from "./state"
import type { FileDocument, GoogleDriveDocument } from "./state"
import { getQuestionAnswerLayouts } from "./utils"

const createJob = async (doc: FileDocument | GoogleDriveDocument) => {
  if (isGoogleDriveDocument(doc)) {
    throw new Error("Google Sheets not supported")
  }

  if (
    doc.fileContents === undefined ||
    doc.fileType === undefined ||
    doc.fileName === undefined
  ) {
    throw new Error("No file")
  }

  const questionAnswerLayouts = getQuestionAnswerLayouts(doc)
  const { id: uploadId, uri: objectUri } = await uploadUserFile(
    doc.fileContents,
    "answer_questionnaire_job_uploads",
    doc.fileType,
  )

  await createAnswerQuestionnaireJob({
    idempotency_key: uploadId,
    question_answer_layouts: questionAnswerLayouts,
    internal_document_uri: objectUri,
    mimetype: doc.fileType,
    original_filename: doc.fileName,
    title: doc.fileName,
  })
  void sendAnalyticsEvent({
    event_type: "SUBMIT_QUESTIONNAIRE",
    surface: "WEB_QUESTIONNAIRE_ASSISTANT",
    event_data: {
      entity_type: "ANSWER_QUESTIONNAIRE_JOB",
      entity_id: null,
    },
  })
}

export default createJob

import { useCallback, useState } from "react"

import ConfluenceLogo from "../assets/img/confluence_logo.svg"
import NotionLogo from "../assets/img/notion_logo.svg"
import OneDriveLogo from "../assets/img/onedrive_logo.svg"
import SlackLogo from "../assets/img/slack_logo.svg"
import useErrorPopup from "../hooks/useErrorPopup"
import { removeSource } from "../sources/api"
import { type Source } from "../sources/types"
import { openUrlInWindow } from "../windowUtils"
import IntegrationBlock from "./IntegrationBlock"

// Ideally we'd import IntegrationName from carbon-connect, but even importing the type clobbers our css
// Also, add logo to this mapping

const CARBON_INTEGRATION_NAME_TO_DISPLAY_NAME = {
  ONEDRIVE: {
    name: "OneDrive",
    logo: OneDriveLogo,
  },
  CONFLUENCE: {
    name: "Confluence",
    logo: ConfluenceLogo,
  },
  NOTION: {
    name: "Notion",
    logo: NotionLogo,
  },
  SLACK: {
    name: "Slack",
    logo: SlackLogo,
  },
}

type CarbonIntegrationName =
  keyof typeof CARBON_INTEGRATION_NAME_TO_DISPLAY_NAME

interface CarbonIntegrationsBlockProps {
  integrationName: CarbonIntegrationName
  source: Source | undefined
  sourcesLoading: boolean
}

const CarbonIntegrationsBlock: React.FC<CarbonIntegrationsBlockProps> = ({
  integrationName,
  source,
  sourcesLoading,
}) => {
  const [connecting, setConnecting] = useState(false)
  const [removing, setRemoving] = useState(false)
  const { handleSuccess, handleError } = useErrorPopup()

  const connectCarbonSource = useCallback(
    async (integrationName: CarbonIntegrationName) => {
      setConnecting(true)
      try {
        await openUrlInWindow(
          `/carbon-connect?integrationName=${integrationName}`,
        )
      } finally {
        setConnecting(false)
      }
    },
    [],
  )

  const onDisconnect = useCallback(async () => {
    if (!source) {
      return
    }
    setRemoving(true)
    try {
      await removeSource(source.oid)
      handleSuccess(
        "Successfully disconnected. Your documents will be removed in the background",
      )
    } catch (error) {
      handleError({
        error,
        prefix: `Failed to disconnect ${CARBON_INTEGRATION_NAME_TO_DISPLAY_NAME[integrationName].name}`,
      })
    } finally {
      setRemoving(false)
    }
  }, [source, handleSuccess, handleError, integrationName])

  const onEdit = useCallback(async () => {
    if (!source) {
      return
    }
    await openUrlInWindow(`/carbon-connect?integrationName=${integrationName}`)
  }, [source, integrationName])

  return (
    <IntegrationBlock
      loading={sourcesLoading || connecting}
      name={CARBON_INTEGRATION_NAME_TO_DISPLAY_NAME[integrationName].name}
      connected={!!source}
      onConnect={() => connectCarbonSource(integrationName)}
      onDisconnect={onDisconnect}
      onEdit={onEdit}
      logo={CARBON_INTEGRATION_NAME_TO_DISPLAY_NAME[integrationName].logo}
      disabledReason={
        source?.removal_requested
          ? "Removal requested"
          : removing
            ? "Removing"
            : undefined
      }
    />
  )
}

export default CarbonIntegrationsBlock

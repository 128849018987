import { Button } from "antd"
import { CheckIcon } from "lucide-react"
import { useCallback, useState } from "react"

import useErrorPopup from "../hooks/useErrorPopup"
import { reviewKnowledgeItems } from "../knowledge/api"

interface Props {
  oids: string[]
  onFinish: () => void
}

const BulkReviewButton: React.FC<Props> = ({ oids, onFinish }) => {
  const [processing, setProcessing] = useState(false)
  const { handleSuccess, handleError } = useErrorPopup()

  const submit = useCallback(async () => {
    setProcessing(true)
    try {
      await reviewKnowledgeItems({
        requests: oids.map((oid) => ({
          oid,
          kind: "REVIEW",
        })),
      })
      handleSuccess(`Reviewed ${oids.length} items`)
      onFinish()
    } catch (error) {
      handleError({ error, prefix: "Couldn't review items" })
    } finally {
      setProcessing(false)
    }
  }, [handleSuccess, handleError, oids, onFinish])

  return (
    <Button
      type="primary"
      size="small"
      icon={<CheckIcon size={14} />}
      title="Assign"
      disabled={processing}
      loading={processing}
      className="flex items-center justify-center"
      onClick={submit}
    >
      <span className="text-[12px] font-bold">Review</span>
    </Button>
  )
}

export default BulkReviewButton

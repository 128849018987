import { Button, Table } from "antd"
import type { ColumnsType } from "antd/es/table"
import type { User } from "firebase/auth"
import { collection, onSnapshot, query, where } from "firebase/firestore"
import { useEffect, useState } from "react"

import { makeConverter } from "../../dbUtils"
import { db } from "../../firebaseApp"
import { useAcceptInvite } from "../../hooks/useAcceptInvite"
import type { InviteItem } from "../../types/common"
import { INVITES_COLLECTION } from "../../types/common"
import { toHumanReadableString } from "../../utils"

const MyInvitesManager: React.FC<{ user: User }> = ({ user }) => {
  const [pendingInvites, setPendingInvites] = useState<InviteItem[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const { loading: isLoadingAccept, handleAcceptInvite } = useAcceptInvite()

  useEffect(() => {
    const colRef = collection(db, INVITES_COLLECTION)
    const allDocsQuery = query(
      colRef,
      where("invitee_email", "==", user.email),
    ).withConverter(makeConverter<InviteItem>())

    return onSnapshot(allDocsQuery, (querySnapshot) => {
      setLoading(false)
      querySnapshot.docChanges().forEach((change) => {
        setPendingInvites((prevDocs) => {
          const prevWithoutChange = prevDocs.filter(
            (d) => d.oid !== change.doc.id,
          )
          const prevWithChange = [change.doc.data(), ...prevWithoutChange]
          return prevWithChange.filter((d) => d.status === "PENDING")
        })
      })
    })
  }, [user.email])

  if (pendingInvites.length === 0) {
    return null
  }

  const columns: ColumnsType<InviteItem> = [
    {
      title: "Group Name",
      dataIndex: "group_name",
      key: "group_name",
      sorter: (a: InviteItem, b: InviteItem) =>
        a.group_name.localeCompare(b.group_name),
    },
    {
      title: "Inviter",
      dataIndex: "creator_email",
      key: "creator_email",
      sorter: (a: InviteItem, b: InviteItem) =>
        a.creator_email.localeCompare(b.creator_email),
    },
    {
      title: "Date Invited",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a: InviteItem, b: InviteItem) =>
        a.created_at.toMillis() - b.created_at.toMillis(),
      render: (created_at: InviteItem["created_at"]) =>
        toHumanReadableString(created_at),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record: InviteItem) => (
        <Button
          type="primary"
          onClick={() => handleAcceptInvite(record.oid)}
          loading={isLoadingAccept}
          disabled={isLoadingAccept}
        >
          Accept
        </Button>
      ),
    },
  ]

  return (
    <section>
      <div className="mb-8">
        <h3>Invitations Received</h3>
        <Table
          dataSource={pendingInvites}
          columns={columns}
          rowKey="oid"
          loading={loading}
          pagination={false}
        />
      </div>
    </section>
  )
}

export default MyInvitesManager

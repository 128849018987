import { ChevronRightIcon } from "lucide-react"
import { Link } from "react-router-dom"

interface HeaderProps {
  title: string
  subtitle?: string
  breadcrumbs?: {
    title: string
    href: string
  }[]
  className?: string
}

const Header: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  title,
  subtitle,
  breadcrumbs = [],
  children,
  className = "",
}) => {
  return (
    <div
      className={`my-0 flex flex-wrap items-center gap-2 border-b border-gray-50 px-8 py-4 ${
        children ? "justify-between" : "justify-start"
      } ${className}`}
    >
      <div>
        {breadcrumbs.length > 0 && (
          <ul className="m-0 flex list-none items-center gap-2 p-0">
            {breadcrumbs.map((breadcrumb) => (
              <li className="flex items-center gap-1" key={breadcrumb.href}>
                <Link className="font-bold text-gray-600" to={breadcrumb.href}>
                  {breadcrumb.title}
                </Link>
                <ChevronRightIcon size={12} className="text-gray-400" />
              </li>
            ))}
          </ul>
        )}
        <h1 className="m-0 text-lg font-bold leading-normal text-gray-800">
          {title}
        </h1>
        {subtitle !== undefined && (
          <h3 className="m-0 text-nowrap text-[14px] font-normal leading-tight text-gray-800">
            {subtitle}
          </h3>
        )}
      </div>
      {children && (
        <div className="flex flex-grow items-center justify-end">
          {children}
        </div>
      )}
    </div>
  )
}

export const withHeader = <P extends object>({
  title,
  subtitle,
  breadcrumbs,
}: HeaderProps) => {
  return (Component: React.FC<P>) => {
    return function HeaderWrappedComponent(props: P) {
      return (
        <>
          <Header title={title} subtitle={subtitle} breadcrumbs={breadcrumbs} />
          <Component {...props} />
        </>
      )
    }
  }
}

export default Header

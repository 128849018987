import { Button } from "antd"

import type { WorksheetRange } from "../../types/sheets"
import { getColumnLetter } from "../../utils"
import { useGridContext } from "./GridContext"
import { focusCell } from "./focusCell"

const TitleCTA: React.FC<{ location: WorksheetRange; className?: string }> = ({
  location: discLocation,
  className = "",
}) => {
  const { gridApi } = useGridContext()
  const locationLabel = `${getColumnLetter(discLocation.firstColIndex)}${discLocation.firstRowIndex}`
  return (
    <div className={"flex items-center font-bold " + className}>
      <Button
        size="small"
        className="m-0 p-0 font-bold"
        type="link"
        onClick={() => {
          const { firstRowIndex, firstColIndex } = discLocation
          focusCell(firstRowIndex - 1, firstColIndex, gridApi)
        }}
      >
        {locationLabel}
      </Button>
      <span className="ml-1">DISCUSSION</span>
    </div>
  )
}

export default TitleCTA

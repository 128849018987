import { Button, Tooltip } from "antd"
import { PlusIcon } from "lucide-react"
import { useCallback, useState } from "react"

import { sendAnalyticsEvent } from "../analytics"
import { createUserDocuments } from "../api"
import useErrorPopup from "../hooks/useErrorPopup"

interface AddPublicWebUrlFormProps {
  onClose: () => void
}

function isValidUrl(s: string): boolean {
  const pattern = new RegExp("^(https?://)?[-_0-9a-zA-Z]+\\.[-_0-9a-zA-Z]+.*$")
  return pattern.test(s)
}

const AddPublicWebUrlForm: React.FC<AddPublicWebUrlFormProps> = ({
  onClose,
}) => {
  const [urlInput, setUrlInput] = useState<string>("")
  const [isSubmitting, setSubmitting] = useState<boolean>(false)
  const { handleError } = useErrorPopup()

  const urls = urlInput.replaceAll(",", "\n").split("\n").filter(Boolean)

  const fixedUrls = urls.map((url) => {
    let fixedUrl = url
    if (url.startsWith("http://")) {
      fixedUrl = url.replace("http://", "https://")
    } else if (!url.startsWith("https://")) {
      fixedUrl = `https://${url}`
    }
    const isInvalid =
      !isValidUrl(fixedUrl) ||
      fixedUrl.includes("drive.google.com") ||
      fixedUrl.includes("docs.google.com")
    return { url: fixedUrl, isInvalid }
  })

  const validUrls = fixedUrls.filter((fixedUrl) => !fixedUrl.isInvalid)

  const onChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setUrlInput(e.currentTarget.value)
  }

  const onSubmit = useCallback(
    async (e: { preventDefault: () => void }) => {
      e.preventDefault()
      if (validUrls.length === 0) {
        return
      }

      setSubmitting(true)
      try {
        await createUserDocuments(
          validUrls.map((urlObj) => ({
            source_kind: "PUBLIC_WEB_PAGE",
            url: urlObj.url,
            external_document_id: "",
            title: null,
            // We don't yet know the mimetype.
            mimetype: null,
          })),
        )
        validUrls.forEach(({ url }) => {
          void sendAnalyticsEvent({
            surface: "WEB_SOURCE_DOCUMENTS",
            event_type: "CREATE_ENTITY",
            event_data: {
              entity_type: "SOURCE_DOCUMENT_URL",
              entity_id: url,
            },
          })
        })
        setUrlInput("")
        onClose()
      } catch (error) {
        handleError({ error, prefix: "Failed to add website" })
      } finally {
        setSubmitting(false)
      }
    },
    [onClose, handleError, validUrls],
  )

  const onInputKeyDown = async (
    e: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    // Submit on cmd+enter or ctrl+enter
    if ((e.metaKey || e.ctrlKey) && e.key === "Enter") {
      await onSubmit(e)
    }
  }

  return (
    <form className="w-[600px] p-4 text-sm" onSubmit={onSubmit}>
      <div className="mb-2 text-xl font-bold text-gray-600">
        Add web page(s)
      </div>
      <div className="text-md mb-3 text-gray-500">
        We will continuously index the specific pages you include here.
      </div>
      <div>
        <textarea
          spellCheck={false}
          onChange={onChange}
          onKeyDown={onInputKeyDown}
          className="border-primary w-full rounded border p-4 focus:outline-none"
          value={urlInput}
          autoFocus
          placeholder="https://example.com ..."
        />
        <div className="max-h-96 overflow-y-auto text-xs text-slate-500">
          {fixedUrls.map((fixedUrl) => (
            <div key={fixedUrl.url} className="my-1 overflow-hidden">
              {fixedUrl.isInvalid ? (
                <Tooltip
                  zIndex={1500}
                  placement="right"
                  title="Please click 'Add from Google Drive' for google drive files"
                >
                  {`❌ ${fixedUrl.url}`}
                </Tooltip>
              ) : (
                `✅ ${fixedUrl.url}`
              )}
            </div>
          ))}
        </div>
        <div className="flex items-center justify-end">
          <Button
            icon={<PlusIcon className="w-4" />}
            disabled={isSubmitting || validUrls.length === 0}
            type="primary"
            className="mt-4 flex h-9 items-center justify-center font-bold"
            htmlType="submit"
          >
            {isSubmitting
              ? "Adding..."
              : validUrls.length <= 1
                ? "Add Web Page"
                : `Add ${validUrls.length} Web Pages`}
          </Button>
        </div>
      </div>
    </form>
  )
}
export default AddPublicWebUrlForm

import SlackLogo from "../assets/img/slack_logo.svg"
import IntegrationBlock from "./IntegrationBlock"
import useSlackInstallation from "./useSlackInstallation"

interface SlackIntegrationBlockProps {
  integrationsLoading: boolean
}

const SlackIntegrationBlock: React.FC<SlackIntegrationBlockProps> = ({
  integrationsLoading,
}) => {
  const { slackInstallation, slackLoading, connectSlack } =
    useSlackInstallation()

  return (
    <IntegrationBlock
      loading={slackLoading || integrationsLoading}
      name="Slack Knowledge Assistant"
      connected={!!slackInstallation}
      onConnect={connectSlack}
      onDisconnect={() => {}}
      logo={SlackLogo}
      disabledReason={
        slackInstallation
          ? "Uninstall Slack via your Slack Workspace Settings"
          : undefined
      }
    />
  )
}

export default SlackIntegrationBlock

import { Button, Empty, Skeleton, Switch, Tooltip } from "antd"
import { ArrowUpToLineIcon, MoveUpIcon } from "lucide-react"
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"

import emptyEventsImage from "../assets/img/empty_icons/source_document.svg"
import useAntdApp from "../hooks/useAntdApp"
import { sortByCreatedAtAsc } from "../utils"
import CardDetailContent from "./CardDetailContent"
import LiveCard from "./LiveCard"
import { scrollToAlignTop } from "./scrollUtils"
import type { CardDetail, LiveUICard } from "./types"

// Returns true if the element is at least partially above the container.
function isAboveContainer(container: HTMLElement, el: HTMLElement): boolean {
  const elemRect = el.getBoundingClientRect()
  const containerRect = container.getBoundingClientRect()

  // TODO(mgraczyk): For some reason there is a 0.4921875px difference
  return elemRect.top + 0.5 < containerRect.top
}

const PENDING_TIMEOUT = 10_000

const fixCardStates = (
  cards: LiveUICard[],
  expirationCheckTime: number,
  setExpirationCheckTime: (t: number) => void,
): LiveUICard[] => {
  // TODO(mgraczyk): Sort input to avoid sorting here.
  // TODO(mgraczyk): Avoid copying if not needed to avoid rerendering the list.
  const result = cards.filter((c) => !c.dismissed).toSorted(sortByCreatedAtAsc)
  let nextExpirationCheckTime = null
  for (const card of result) {
    if (card.answer_state === "PENDING") {
      const createdAtMillis = card.created_at.toMillis()
      const expirationTime = createdAtMillis + PENDING_TIMEOUT
      if (expirationCheckTime >= expirationTime) {
        card.answer_state = "ERROR"
        card.error_message = "Answer took too long"
      } else {
        nextExpirationCheckTime = Math.min(
          nextExpirationCheckTime ?? expirationTime,
          expirationTime,
        )
      }
    }
  }

  if (nextExpirationCheckTime !== null) {
    // Need to check expiration again in the future.
    setTimeout(
      () => setExpirationCheckTime(Date.now()),
      // Add some buffer to make sure we catch the timeout.
      nextExpirationCheckTime - Date.now() + 20,
    )
  }

  return result
}

interface CardsListContainerProps {
  cards: LiveUICard[]
  containerRef: React.LegacyRef<HTMLDivElement>
  cardElements: (HTMLElement | null)[]
  onDismissCard: (card: LiveUICard) => Promise<void>
  onMarkCardSeen: (card: LiveUICard) => Promise<void>
  onScroll: () => void
  showCardDetail: (cardDetail: CardDetail) => void
}

const CardListContainer = memo(function CardListContainer({
  cards,
  containerRef,
  cardElements,
  onDismissCard,
  onMarkCardSeen,
  onScroll,
  showCardDetail,
}: CardsListContainerProps) {
  let inner: JSX.Element | JSX.Element[]
  if (cards.length === 0) {
    inner = (
      <Empty
        image={emptyEventsImage}
        description="No cards yet, start the call and they will appear as people speak."
        className="m-auto flex flex-col items-center"
      />
    )
  } else {
    // Add mb-auto to first (visually last) card so that it goes to the top.
    inner = cards.map((card, i) => (
      <LiveCard
        ref={(node) => {
          cardElements[i] = node
        }}
        key={card.oid}
        className={i === 0 ? "mb-auto" : ""}
        message={card}
        onDismissCard={onDismissCard}
        onMarkCardSeen={onMarkCardSeen}
        showCardDetail={showCardDetail}
      />
    ))
  }

  return (
    <div
      className="overflow-anchor-none bg-gray-25 relative flex w-full grow flex-col-reverse items-start overflow-y-scroll rounded border sm:px-2"
      ref={containerRef}
      onScroll={onScroll}
    >
      {inner}
    </div>
  )
})

interface Props {
  cards: LiveUICard[]
  loading?: boolean
  onDismissCard: (card: LiveUICard) => Promise<void>
  onMarkCardSeen: (card: LiveUICard) => Promise<void>
}

const LiveFeed: React.FC<Props> = ({
  cards,
  loading = false,
  onDismissCard,
  onMarkCardSeen,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const cardsRef = useRef<(HTMLElement | null)[]>([])
  const [autoScrollOn, setAutoScrollOn] = useState<boolean>(true)
  const { modal } = useAntdApp()

  const [expirationCheckTime, setExpirationCheckTime] = useState<number>(
    Date.now(),
  )
  const [numCardsAbove, setNumCardsAbove] = useState<number>()
  const fixedCards = useMemo(
    () => fixCardStates(cards, expirationCheckTime, setExpirationCheckTime),
    [cards, expirationCheckTime],
  )

  const computeCardCounts = useCallback(() => {
    const container = containerRef.current
    if (!container) {
      return
    }

    const cards = cardsRef.current
    let numAbove = 0
    for (const el of cards) {
      if (!el) {
        continue
      }
      if (el.nodeType !== Node.ELEMENT_NODE) {
        continue
      }

      if (isAboveContainer(container, el)) {
        numAbove++
      }
    }
    setNumCardsAbove(numAbove)
  }, [])

  // TODO(mgraczyk): Multiple clicks do not work, need to use a ref or pass
  // "next" state to callback.
  const goToNext = useCallback(() => {
    const container = containerRef.current
    if (!container) {
      return
    }
    const cards = cardsRef.current

    // Find the first card that is above the container.
    for (let i = 0; i < cards.length; ++i) {
      const el = cards[i]
      if (!el) {
        continue
      }
      if (el.nodeType !== Node.ELEMENT_NODE) {
        continue
      }

      if (isAboveContainer(container, el)) {
        scrollToAlignTop(container, el)
        break
      }
    }
  }, [])

  const goToTop = useCallback(() => {
    const container = containerRef.current
    if (!container) {
      return
    }
    // Negative because scroll is reversed.
    container.scrollTop = -container.scrollHeight
  }, [])

  useEffect(computeCardCounts, [computeCardCounts, cards])

  useEffect(() => {
    // TODO(mgraczyk): Remove this hack, start at top with CSS.
    if (!loading) {
      setTimeout(goToTop, 20)
    }
  }, [loading, goToTop])

  const showCardDetail = useCallback(
    async (cardDetail: CardDetail) => {
      await modal.info({
        title:
          cardDetail.kind === "ANSWERED_QUESTION"
            ? cardDetail.question
            : "Card Detail",
        content: <CardDetailContent cardDetail={cardDetail} />,
        centered: true,
        width: "90%",
        type: "info",
        maskClosable: true,
        closable: true,
        footer: null,
      })
    },
    [modal],
  )

  const hasNewerCards = !!numCardsAbove
  const newerCardsLabel = (
    <span
      className={
        "w-20 grow text-right text-sm text-gray-800 " +
        (hasNewerCards ? "font-semibold" : "")
      }
    >
      {numCardsAbove === undefined
        ? "Loading..."
        : hasNewerCards
          ? `${numCardsAbove} more cards`
          : "No more cards"}
    </span>
  )
  const autoScrollSwitch = (
    <Tooltip title="Automatically scroll to new messages">
      <Switch
        checkedChildren="Autoscroll"
        unCheckedChildren="No Autoscroll"
        value={autoScrollOn}
        onChange={setAutoScrollOn}
        disabled={loading}
      />
    </Tooltip>
  )
  // TODO(mgraczyk): Finish implementing.
  void autoScrollSwitch

  return (
    <>
      <div className="my-2 flex h-8 items-center gap-2 max-sm:mt-2">
        <h3 className="">Feed</h3>
        <span className="grow" />
        <Tooltip title="Go to top">
          <Button
            onClick={goToTop}
            icon={<ArrowUpToLineIcon />}
            disabled={loading || numCardsAbove === 0}
            className="gap-0 p-1"
          />
        </Tooltip>
        <Tooltip title="Go to next card">
          <Button
            icon={<MoveUpIcon />}
            onClick={goToNext}
            disabled={loading || numCardsAbove === 0}
            className={"pl-1 " + hasNewerCards ? "" : ""}
          >
            {newerCardsLabel}
          </Button>
        </Tooltip>
      </div>
      <Skeleton active loading={loading}>
        <CardListContainer
          cards={fixedCards}
          containerRef={containerRef}
          onScroll={computeCardCounts}
          cardElements={cardsRef.current}
          onDismissCard={onDismissCard}
          onMarkCardSeen={onMarkCardSeen}
          showCardDetail={showCardDetail}
        />
      </Skeleton>
    </>
  )
}

export default LiveFeed

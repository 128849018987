import { Button, Layout, theme } from "antd"
import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

import PageBackgroundContainer from "../components/PageBackgroundContainer"
import QuiltLogo from "../components/QuiltLogo"

const { Header, Content } = Layout

const AuthResponsePage: React.FC = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  const [searchParams] = useSearchParams()

  const providerName = searchParams.get("providerName")
  const error = searchParams.get("error")

  useEffect(() => {
    if (!error) {
      setTimeout(() => {
        window.close()
      }, 5000)
    }
  }, [error])

  const closeButton = (
    <Button className="mt-2" type="primary" onClick={() => window.close()}>
      Close Window
    </Button>
  )

  let content: React.ReactNode
  if (error) {
    content = (
      <>
        <h2>Could not connect {providerName}</h2>
        <div>{error}</div>
        {closeButton}
      </>
    )
  } else {
    content = (
      <>
        <h2>Connected {providerName} Successfully!</h2>
        <div>This window will close in a few seconds.</div>
        <div>Or click the button below to close it now.</div>
        {closeButton}
      </>
    )
  }

  return (
    <PageBackgroundContainer>
      <Layout className="h-full">
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <QuiltLogo className="m-5" width="145px" />
        </Header>
        <Content
          style={{
            margin: "16px",
            border: "1px solid #edf1f5",
            borderRadius: "12px",
            padding: "0px 24px",
            minHeight: 300,
            background: colorBgContainer,
          }}
          className="flex flex-col items-center justify-center"
        >
          {content}
        </Content>
      </Layout>
    </PageBackgroundContainer>
  )
}

export default AuthResponsePage

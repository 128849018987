import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model"
import type { CellClassParams } from "@ag-grid-community/core"
import { AgGridReact } from "@ag-grid-community/react"
import "@ag-grid-community/styles/ag-grid.css"
import "@ag-grid-community/styles/ag-theme-balham.css"
import { Radio } from "antd"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { read as xlsxRead } from "xlsx"

import LoadingSpinner from "../../components/LoadingSpinner"
import { focusCell } from "../../components/QuestionnaireReview/focusCell"
import { getErrorMessage } from "../../errors"
import { getNonemptyCols } from "../../sheets/formatGrid"
import { getWorksheetJsonData } from "../../sheets/sheetsJsHelpers"
import { getColumnNumber } from "../../sheets/utils"
import type { WorksheetRange } from "../../types/sheets"
import type { AnsweredQuestionPieceLocation } from "../../types/userDocument"
import { sortStringsByLength } from "../../utils"

interface RowData {
  [key: string]: string | number
}

interface ColumnDef {
  headerName: string
  field: string
}

interface SheetData {
  name: string
  data: RowData[]
}

const rangeContainsCell = (
  range: WorksheetRange,
  rowIndex: number,
  columnId: string,
  selectedSheet: string,
) => {
  const {
    firstColIndex,
    lastColIndex,
    firstRowIndex,
    lastRowIndex,
    sheetName,
  } = range
  const cellColumnIndex = getColumnNumber(columnId)
  if (sheetName !== selectedSheet) return false
  return (
    cellColumnIndex >= firstColIndex &&
    cellColumnIndex <= lastColIndex &&
    rowIndex >= firstRowIndex &&
    rowIndex <= lastRowIndex
  )
}

const SheetsViewer: React.FC<{
  url: string
  referenceLocation?: AnsweredQuestionPieceLocation
}> = ({ url, referenceLocation }) => {
  const [sheetsData, setSheetsData] = useState<SheetData[]>([])
  const [error, setError] = useState<React.ReactNode>()
  const [loading, setLoading] = useState<boolean>(true)
  const gridRef = useRef<AgGridReact<RowData>>(null)
  const [selectedSheet, setSelectedSheet] = useState<string>("")

  const grid = sheetsData.find((sheet) => sheet.name === selectedSheet)?.data

  const shouldHighlight = useCallback(
    (rowIndex: number, columnId: string) => {
      if (!referenceLocation) return false
      return (
        rangeContainsCell(
          referenceLocation.primary_answer_location.range,
          rowIndex,
          columnId,
          selectedSheet,
        ) ||
        rangeContainsCell(
          referenceLocation.primary_question_location.range,
          rowIndex,
          columnId,
          selectedSheet,
        )
      )
    },
    [referenceLocation, selectedSheet],
  )

  const columnKeys = useMemo(
    () => {
      if (!grid) return []
      // Sort so that columns are in the right order.
      return getNonemptyCols(grid).sort(sortStringsByLength)
    },
    // Do not recompute columnKeys when grid changes, since it is only used to compute columnDefs
    // However, it does depend on sheet.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedSheet],
  )

  const columnDefs: ColumnDef[] = useMemo(() => {
    const selectedSheetData = sheetsData.find(
      (sheet) => sheet.name === selectedSheet,
    )
    if (!selectedSheetData || selectedSheetData.data.length === 0) return []
    return columnKeys.map((key) => ({
      headerName: key,
      field: key,
      wrapText: true,
      autoHeight: true,
      editable: false,
      sortable: false,
      cellDataType: "text",
      cellClassRules: {
        "bg-yellow-25 hover:bg-yellow-50 hover:border-yellow-600 !border-yellow-500 cursor-pointer":
          (props: CellClassParams) =>
            // need to add 1 to rowIndex because ag-grid is 0-indexed
            shouldHighlight(props.rowIndex + 1, props.column.getColId()),
      },
    }))
  }, [sheetsData, selectedSheet, shouldHighlight, columnKeys])

  useEffect(() => {
    const fetchAndProcessSheet = async () => {
      try {
        const response = await fetch(url)
        const arrayBuffer = await response.arrayBuffer()
        const workbook = xlsxRead(arrayBuffer, {
          cellFormula: false,
          cellHTML: true,
        })
        const allSheetsData: SheetData[] = workbook.SheetNames.filter(
          (s) =>
            !workbook.Workbook?.Sheets?.find((ws) => ws.name === s)?.Hidden,
        ).map((sheetName) => ({
          name: sheetName,
          data: getWorksheetJsonData<RowData>(workbook, sheetName, {
            dropInnerEmptyColumns: false,
          }),
        }))
        setSheetsData(allSheetsData)

        if (
          referenceLocation &&
          referenceLocation.primary_question_location.range.sheetName &&
          workbook.SheetNames.includes(
            referenceLocation.primary_question_location.range.sheetName,
          )
        ) {
          setSelectedSheet(
            referenceLocation.primary_question_location.range.sheetName,
          )
        } else {
          setSelectedSheet(workbook.SheetNames[0])
        }
      } catch (error) {
        setError(
          getErrorMessage({ error, prefix: "Couldn't load the document" }),
        )
      } finally {
        setLoading(false)
      }
    }
    void fetchAndProcessSheet()
  }, [url, referenceLocation])

  useEffect(() => {
    if (!gridRef.current?.api) return
    const range = referenceLocation?.primary_question_location.range
    if (!range) return
    const { firstColIndex, firstRowIndex } = range
    focusCell(firstRowIndex, firstColIndex, gridRef.current.api)
  }, [referenceLocation])
  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <div className="text-center text-red-600">{error}</div>
  }

  if (!sheetsData.length) {
    return <div className="text-center">No data to display.</div>
  }

  return (
    <div className="relative flex h-full w-full flex-1 flex-col p-2">
      <AgGridReact
        ref={gridRef}
        className="quilt-questionnaire-editor ag-theme-balham"
        rowData={grid}
        columnDefs={columnDefs}
        enableCellTextSelection
        modules={[ClientSideRowModelModule]}
        suppressMovableColumns
      />
      <Radio.Group
        value={selectedSheet}
        onChange={(e) => setSelectedSheet(String(e.target.value))}
        options={sheetsData.map((sheet) => sheet.name)}
        optionType="button"
        buttonStyle="solid"
        className="sheet-pages-selector"
      />
    </div>
  )
}

export default SheetsViewer

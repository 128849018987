import { Table, type TableProps } from "antd"
import { doc, getDoc } from "firebase/firestore"
import { useEffect, useState } from "react"

import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../../dbUtils"
import { db } from "../../firebaseApp"
import useErrorPopup from "../../hooks/useErrorPopup"
import { useGroupMembers } from "../../hooks/useGroupMembers"
import {
  FRONTEND_HOMEPAGE_COLLECTION,
  type HomepageMetricDocument,
  MEMBER_METRICS_SUBCOLLECTION,
} from "../../metrics/types"

interface MetricsTableRow {
  email: string
  ASK_QUESTION: number
  CREATE_ENTITY: number
  SUBMIT_QUESTIONNAIRE: number
}

const columns: TableProps<MetricsTableRow>["columns"] = [
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    sorter: (a, b) => a.email.localeCompare(b.email),
  },
  {
    title: "Asked Questions",
    dataIndex: "ASK_QUESTION",
    key: "ASK_QUESTION",
    sorter: (a, b) => a.ASK_QUESTION - b.ASK_QUESTION,
  },
  {
    title: "Documents Uploaded",
    dataIndex: "CREATE_ENTITY",
    key: "CREATE_ENTITY",
    sorter: (a, b) => a.CREATE_ENTITY - b.CREATE_ENTITY,
  },
  {
    title: "Questionnaires Submitted",
    dataIndex: "SUBMIT_QUESTIONNAIRE",
    key: "SUBMIT_QUESTIONNAIRE",
    sorter: (a, b) => a.SUBMIT_QUESTIONNAIRE - b.SUBMIT_QUESTIONNAIRE,
  },
]

interface GroupsProps {}

const GroupReportingPage: React.FC<GroupsProps> = () => {
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()
  const { handleError } = useErrorPopup()
  const [groupMembers, groupMembersLoading, groupMembersError] =
    useGroupMembers(activeGroupOid)

  const [userMetricsLoading, setUserMetricsLoading] = useState<boolean>(true)
  const [userMetrics, setUserMetrics] = useState<MetricsTableRow[]>([])

  useEffect(() => {
    if (!groupMembers) {
      return
    }
    const fetchUserMetrics = async () => {
      setUserMetricsLoading(true)
      try {
        const metricPromises = groupMembers.map((member) => {
          const docRef = doc(
            db,
            FRONTEND_HOMEPAGE_COLLECTION,
            activeGroupOid,
            MEMBER_METRICS_SUBCOLLECTION,
            member.uid,
          ).withConverter(makeConverter<HomepageMetricDocument>())
          return getDoc(docRef)
        })
        const metrics = (await Promise.all(metricPromises)).map(
          (docSnap, idx) => {
            if (docSnap.exists()) {
              const metric = docSnap.data().metrics
              return {
                email: groupMembers[idx].email,
                ASK_QUESTION: metric["ASK_QUESTION"] ?? 0,
                CREATE_ENTITY: metric["CREATE_ENTITY"] ?? 0,
                SUBMIT_QUESTIONNAIRE: metric["SUBMIT_QUESTIONNAIRE"] ?? 0,
              }
            } else {
              return {
                email: groupMembers[idx].email,
                ASK_QUESTION: 0,
                CREATE_ENTITY: 0,
                SUBMIT_QUESTIONNAIRE: 0,
              }
            }
          },
        )
        setUserMetrics(metrics)
      } catch (e) {
        handleError({
          error: e,
          prefix: "Unable to get metrics",
        })
      } finally {
        setUserMetricsLoading(false)
      }
    }
    void fetchUserMetrics()
  }, [activeGroupOid, groupMembers, handleError])

  if (groupMembersError) {
    handleError({
      error: groupMembersError,
      prefix: "Unable to get group members",
    })
  }

  return (
    <div className="flex flex-col">
      <h3>Metrics</h3>
      <Table
        virtual
        scroll={{ y: 500 }}
        pagination={false}
        bordered
        columns={columns}
        dataSource={userMetrics}
        loading={groupMembersLoading || userMetricsLoading}
        size="middle"
        rowKey={(r: MetricsTableRow) => r.email}
      />
    </div>
  )
}

export default GroupReportingPage

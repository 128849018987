import { Button, Form, Input, Select } from "antd"
import { useState } from "react"

import { adminUpdateGroupTag } from "../../groups/api"
import type { Group } from "../../groups/types"
import { useKeydown } from "../../hooks/events"
import useErrorPopup from "../../hooks/useErrorPopup"
import Tag, { TagListItem } from "../../tags/Tag"
import { type GroupTag, TAGCOLORS, type TagColor } from "../../tags/types"

interface EditTagFormProps {
  group: Group
  tag: GroupTag
  onClose: () => void
}

const EditTagForm: React.FC<EditTagFormProps> = ({ group, tag, onClose }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setSubmitting] = useState(false)

  useKeydown(27 /*escape*/, onClose)
  const { handleSuccess, handleError } = useErrorPopup()

  const name = Form.useWatch<string>("name", form)
  const color = Form.useWatch<TagColor>("color", form)

  const onSubmit = async (values: { name: string; color: TagColor }) => {
    setSubmitting(true)
    try {
      await adminUpdateGroupTag({
        oid: tag.oid,
        group_oid: group.oid,
        name: values.name,
        color: values.color,
      })
      handleSuccess("Updated tag!")
      onClose()
    } catch (error) {
      handleError({ error, prefix: "Couldn't update tag" })
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <Form
        form={form}
        onFinish={onSubmit}
        initialValues={{ name: tag.name, color: tag.color }}
        layout="vertical"
      >
        <Form.Item
          name="name"
          rules={[{ required: true, message: "Please input the tag name" }]}
        >
          <Input placeholder="Enter tag name" />
        </Form.Item>
        <Form.Item
          name="color"
          rules={[{ required: true, message: "Please select the tag color" }]}
        >
          <Select placeholder="Select tag color">
            {TAGCOLORS.map((color) => (
              <Select.Option key={color} value={color}>
                <TagListItem tag={{ color, name: color }} />
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isSubmitting}
            disabled={isSubmitting || !name || !color}
          >
            Update Tag
          </Button>
        </Form.Item>
      </Form>
      {color && name && (
        <>
          <div className="mt-4 text-sm text-slate-500">Preview</div>
          <Tag tag={{ color, name }} />
        </>
      )}
    </>
  )
}

export default EditTagForm

import { type QuerySnapshot, collection } from "firebase/firestore"
import { useCollectionData } from "react-firebase-hooks/firestore"

import { makeConverter } from "../dbUtils"
import { db } from "../firebaseApp"
import type { GroupMembership } from "../groups/types"
import {
  GROUPS_COLLECTION,
  GROUP_MEMBERSHIP_SUBCOLLECTION,
} from "../types/common"

export const useGroupMembers = (
  group_oid: string,
): [
  GroupMembership[] | undefined,
  boolean,
  Error | undefined,
  QuerySnapshot<GroupMembership> | undefined,
] => {
  return useCollectionData(
    collection(
      db,
      GROUPS_COLLECTION,
      group_oid,
      GROUP_MEMBERSHIP_SUBCOLLECTION,
    ).withConverter(makeConverter<GroupMembership>()),
  )
}

import { Button, Form, Input } from "antd"
import type { User as AuthUser } from "firebase/auth"
import { useCallback, useState } from "react"

import liveImgSrc from "../assets/img/live.jpg"
import Header from "../components/Header"
import useErrorPopup from "../hooks/useErrorPopup"
import LiveAssistedCallsList from "../live/LiveAssistedCallsList"
import { createLiveAssistedCall } from "../live/api"
import type { LiveAssistedCall } from "../live/types"

interface FormData {
  joinUrl: string
  contextForPrompt: string
  messageRecipientDisplayName: string
  boostWords: string
}

const StartLiveAssistantForm: React.FC = () => {
  const [form] = Form.useForm<FormData>()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [liveAssistedCall, setLiveAssistedCall] = useState<LiveAssistedCall>()
  const { handleSuccess, handleError, messageApi } = useErrorPopup()

  const onFinish = useCallback(
    async (formData: FormData) => {
      try {
        if (submitting) {
          return
        }

        setSubmitting(true)
        void messageApi.open({ type: "info", content: "Creating assistant..." })
        const liveAssistedCall = await createLiveAssistedCall({
          meeting_type: "ZOOM",
          join_url: formData.joinUrl,
          start_time: null,
          context_for_prompt: formData.contextForPrompt,
          message_recipient_display_name:
            formData.messageRecipientDisplayName || null,
          boost_words: (formData.boostWords || "")
            .split(",")
            .map((w) => w.trim())
            .filter(Boolean),
        })
        setLiveAssistedCall(liveAssistedCall)
        console.log("Created call", liveAssistedCall)
        handleSuccess("Created! The Assistant will join your call soon.")
      } catch (error) {
        handleError({ error, prefix: "Could not create assistant" })
      } finally {
        setSubmitting(false)
      }
    },
    [handleSuccess, handleError, messageApi, submitting],
  )

  const onReset = useCallback(() => {
    form.resetFields()
  }, [form])

  const resultText = liveAssistedCall ? (
    `Created call ${liveAssistedCall.oid}`
  ) : (
    <>&nbsp;</>
  )

  const initialValues: FormData = {
    joinUrl: "",
    contextForPrompt: "",
    messageRecipientDisplayName: "",
    boostWords: "",
  }

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  }

  const formUI = (
    <Form
      {...layout}
      form={form}
      onFinish={onFinish}
      initialValues={initialValues}
      className="w-full"
    >
      <Form.Item name="joinUrl" label="Zoom Join URL" required>
        <Input
          disabled={submitting}
          title="Zoom join URL with meeting ID and password"
          placeholder="https://zoom.us/j/91324270730?pwd=SlR..."
        />
      </Form.Item>
      <Form.Item name="messageRecipientDisplayName" label="Zoom Display Name">
        <Input
          disabled={submitting}
          title="Your Zoom display name (blank to send to host)"
          placeholder="John Doe"
        />
      </Form.Item>
      <Form.Item name="boostWords" label="Words to boost">
        <Input
          disabled={submitting}
          title="Words to boost (use comma to separate)"
          placeholder="Quilt, SOC-2"
        />
      </Form.Item>
      <div className="h-8">{resultText}</div>
      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          className="w-26"
          disabled={submitting}
          htmlType="submit"
        >
          {submitting ? "Submitting..." : "Submit"}
        </Button>
        <Button htmlType="button" className="ml-2" onClick={onReset}>
          Reset
        </Button>
      </Form.Item>
    </Form>
  )

  return (
    <div className="max-w-[1200px] p-4">
      <div className="m-auto mb-8 flex w-full max-w-[600px] items-center">
        <img
          className="h-auto w-[96px] rounded-3xl"
          src={liveImgSrc}
          alt="Live Assistant"
          width={1024}
          height={1024}
        />
        <div>
          <div className="ml-4 text-lg font-semibold">
            Try the new Quilt Live Assistant.
          </div>
          <div className="text-md ml-4">
            The assistant will join your call and answer questions it hears.
          </div>
        </div>
      </div>
      <div>{formUI}</div>
    </div>
  )
}

const StartLiveAssistantPage: React.FC<{ user: AuthUser }> = ({ user }) => {
  return (
    <div className="h-full pb-4">
      <Header
        title="Live Assistant"
        subtitle="Add Quilt to a Zoom call to answer questions"
      ></Header>
      <StartLiveAssistantForm />
      <LiveAssistedCallsList user={user} />
    </div>
  )
}

export default StartLiveAssistantPage
